import React from "react";
import ContentLoader from "react-content-loader";

const StripeCardShimmer = () => (
    <ContentLoader
        speed={2}
        width="100%"
        height={90}
        viewBox="0 0 400 90"
        backgroundColor="#f7f7f7"
        foregroundColor="#ecebeb"
        preserveAspectRatio="none">
        {/* First line (card number) */}
        <rect x="0" y="0" rx="6" ry="6" width="100%" height="42" />

        {/* Second line (expiry, CVC, ZIP) */}
        <rect x="0%" y="48" rx="6" ry="6" width="33%" height="42" />
        <rect x="33.5%" y="48" rx="6" ry="6" width="33%" height="42" />
        <rect x="67%" y="48" rx="6" ry="6" width="33%" height="42" />
    </ContentLoader>
);

export default StripeCardShimmer;
