import React, { Component } from "react";
import "../Styling/donationform.css";
import "../Styling/toggle.css";
import "../Styling/doublethedonation.css";
import DonorInfo from "./DonorInfo";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amountDonated: props.amountDonated,
      statementDescriptor: props.statementDescriptor,
      setPage: props.setPage,
      timeLeft: 15,
      freeTicket: props.freeTicket,
      opacity: 0, // Start with 0 opacity for fade-in effect
      displayedContent: "BASE",
      showTimer: true,
      interval: null,
    };
  }

  componentDidMount() {
    const { timeLeft } = this.state;
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("mousedown", this.handleClick);

    this.setState({
      interval: setInterval(
        () => this.setState({ timeLeft: this.state.timeLeft - 1 }),
        1000
      ),
    });

    // deep copy of donation data
    const data = JSON.parse(JSON.stringify(this.props.donationData));
    // add email to data
    data.email = this.props.email.toLowerCase();
    // // double check amount equals the amount donated. this is mainly for add to cart donations
    // data.amount = this.props.amount;
    // send total charged
    data.totalCharged = this.props.amountDonated;
    data.hostedPage = this.props.hostedPage;
    data.elementID = this.props.elementID;

    window.DDCONF = {
      API_KEY: this.props.doubleTheDonationPublicAPIKey,
      COMPANY: this.props.companyID,
    };

    console.log("window.DDCONF", window.doublethedonation);

    if (window.doublethedonation) {
      const doublethedonation = window.doublethedonation;
      // Don't break your page if our plugin doesn't load for any reason

      // grab dd-container div
      const ddContainer = document.getElementById("dd-container");
      doublethedonation.plugin.load_config();
      doublethedonation.plugin.set_donation_identifier(
        this.props.donationData.uuid
      ); // Replace with actual identifier or an empty string.
      doublethedonation.plugin.set_donation_campaign(""); // Replace with actual campaign.
      if (this.props.companyID && this.props.companyID !== "") {
        doublethedonation.plugin.set_company(this.props.companyID);
      } else {
        const domain = doublethedonation.integrations.core.strip_domain(
          this.props.email
        ); // Replace with actual email.
        doublethedonation.plugin.email_domain(domain); // Checks for a company based on the email address.
      }
      doublethedonation.plugin.load_plugin();
    }

    console.log("data", data);

    // send processed donation message to parent
    window.parent.postMessage(
      {
        action: "DONATION_PROCESSED",
        entity: "CHARITYSTACK",
        sender: "CHECKOUT_FORM",
        receiver: "HOSTED_SCRIPT",
        data: JSON.stringify(data),
      },
      "*"
    );
    setTimeout(() => {
      this.setState({ opacity: 1 }); // Change opacity to 1 to fade in
    }, 75); // Adjust time as needed
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("mousedown", this.handleClick);
  }

  componentDidUpdate() {
    const { timeLeft, setPage } = this.state;
    if (timeLeft == 0) {
      this.props.exitCheckoutForm(true, "EXIT");
    }
  }

  handleKeyDown = (event) => {
    // clear timer interval and dont show timer
    const { interval, displayedContent } = this.state;
    if (displayedContent === "DTD") {
      clearInterval(interval);
      this.setState({ showTimer: false });
    }
  };

  handleClick = (event) => {
    // clear timer interval and dont show timer
    const { interval, displayedContent } = this.state;
    if (displayedContent === "DTD") {
      clearInterval(interval);
      this.setState({ showTimer: false });
    }
  };

  render() {
    const { amountDonated, statementDescriptor, freeTicket, scheduledTotal } =
      this.props;
    const { timeLeft, opacity, displayedContent, showTimer } = this.state;

    const donationTodayMessage =
      this.props.companyName === ""
        ? "A payment to " +
          this.props.merchantName +
          " will appear on your statement and a receipt will be emailed to you."
        : "Please check your inbox for your donation receipt and details on how " +
          this.props.companyName +
          " can match your donation";
    let displayedAmount = ` $${amountDonated.toFixed(2)} `;
    let displayedMessage = <div>{donationTodayMessage}</div>;
    let displayedHeader = "Thank you for your payment";

    // same as above defaults
    if (amountDonated !== 0 && scheduledTotal === 0) {
      displayedHeader = "Thank you for your payment";
      displayedAmount = ` $${amountDonated.toFixed(2)} `;
      displayedMessage = <div>{donationTodayMessage}</div>;
    }

    if (amountDonated === 0 && scheduledTotal !== 0) {
      displayedHeader = "Thank you for your scheduled payment";
      displayedAmount = ` $${scheduledTotal.toFixed(2)} `;
      displayedMessage = (
        <div>
          A payment has been scheduled and a confirmation has been emailed to
          you.
        </div>
      );
    }

    if (amountDonated !== 0 && scheduledTotal !== 0) {
      displayedHeader = "Thank you for your payment";
      displayedAmount = ` $${amountDonated.toFixed(2)} `;
      displayedMessage = (
        <div>
          <div>{donationTodayMessage}</div>
          <br />
          <div>
            Another payment has been scheduled and a confirmation has been
            emailed to you.
          </div>
        </div>
      );
    }

    console.log("displayedContent", this.state.displayedContent);

    return (
      <>
        {/* <div class="FormBoxConfirm" id="FormBoxConfirm"> */}
        <div className={`${displayedContent === "DTD" ? "" : "hidden"}`}>
          <div id="dd-container"></div>
          {showTimer && (
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
                fontSize: "13px",
                color: "darkgray",
                fontWeight: "400",
                paddingTop: "21px",
              }}
            >
              This page will automatically close in {timeLeft} seconds.
            </div>
          )}
        </div>
        <div
          className={`${displayedContent === "BASE" ? "" : "hidden"}`}
          style={{ opacity: opacity, transition: "opacity 1s ease-in-out" }}
        >
          {freeTicket ? (
            <form>
              <div
                style={{
                  top: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  animationDelay: ".5s",
                }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  style={{ width: "40px", display: "block" }}
                >
                  <circle
                    class="path circle"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    class="path check"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: "2.0vh",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                {" "}
                Thank you for submitting{" "}
              </div>

              <br></br>

              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                  fontSize: "13px",
                  color: "darkgray",
                  fontWeight: "400",
                  paddingTop: "21px",
                }}
              >
                This page will automatically close in {timeLeft} seconds.
              </div>
            </form>
          ) : (
            <form>
              <div
                style={{
                  top: "5%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  animationDelay: ".5s",
                }}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  style={{ width: "40px", display: "block" }}
                >
                  <circle
                    class="path circle"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-miterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    class="path check"
                    fill="none"
                    stroke="#73AF55"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: "2.0vh",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                {" "}
                {displayedHeader}{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ fontSize: "85px", fontStretch: "ultra-condensed" }}
                >
                  {displayedAmount}
                </div>
              </div>

              <br></br>
              {/* <div style={{ display: "flex", textAlign: "center", justifyContent: "center", fontSize: "15px", color: "darkgray", fontWeight: "400", paddingBottom: "21px" }}> The donation will appear on your statement </div> */}

              <svg
                class="InlineSVG"
                focusable="false"
                width="100%"
                height="68"
                viewBox="0 0 384 68"
                fill="none"
              >
                <path
                  d="M0 3.923C0 1.756 2.686 0 6 0h372c3.314 0 6 1.756 6 3.923V68l-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23-8 5.23-8-5.23L96 68l-8-5.23L80 68l-8-5.23L64 68l-8-5.23L48 68l-8-5.23L32 68l-8-5.23L16 68l-8-5.23L0 68V3.923z"
                  id="Path"
                  fill-opacity=".03"
                  fill="#000"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M375.646 62.538l-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5-.354-.231-.354.231-7.646 5-7.646-5L8 62.307l-.354.231L.5 67.211V3.923C.5 1.937 2.962.327 6 .327h372c3.038 0 5.5 1.61 5.5 3.596v63.288l-7.146-4.673-.354-.231-.354.231z"
                  id="Path"
                  stroke-opacity=".08"
                  stroke="#000"
                  stroke-width=".5"
                ></path>
              </svg>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "baseline",
                  fontSize: "13px",
                  color: "darkgray",
                  fontFamily: "Monaco",
                  fontWeight: "bold",
                  marginTop: "-60px",
                  padding: "10px",
                  justifyContent: "center", // Center the content horizontally
                }}
              >
                <div className="w-full max-w-[384px] px-[2%] text-center">
                  <p className="flex justify-between">
                    <div>{statementDescriptor}</div>
                    <span>{displayedAmount}</span>
                  </p>
                </div>
              </div>
              <br />
              {/* <div id="dd-container" style={{maxHeight: "400px", overflowY: "auto"}}></div> */}
              {/* <div id='dd-company-name-input' onChange={this.onChangeHandler}></div> */}

              <div
                style={{
                  // display: "flex",
                  alignItems: "baseline",
                  textAlign: "center",
                  justifyContent: "center",
                  fontSize: "13px",
                  color: "darkgray",
                  fontWeight: "400",
                }}
              >
                {displayedMessage}
              </div>

              <br />
              <br />
              <br />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "13px",
                  color: "darkgray",
                  fontWeight: "400",
                  paddingTop: "21px",
                }}
                className="cursor-pointer"
                onClick={() => {
                  this.setState({ displayedContent: "DTD", timeLeft: 15 });
                }}
              >
                <div>Click here to complete your donation match</div>
                <ArrowRightIcon className="h-6 w-6 pl-1" />
              </div>
              {showTimer && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    justifyContent: "center",
                    fontSize: "13px",
                    color: "darkgray",
                    fontWeight: "400",
                    paddingTop: "21px",
                  }}
                >
                  This page will automatically close in {timeLeft} seconds.
                </div>
              )}
            </form>
          )}
        </div>

        {/* </div > */}
      </>
    );
  }
}

export default Confirmation;
