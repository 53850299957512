import { Component, createRef } from "react";
import "../Styling/donationform.css";
import "../Styling/shimmer.css";
import "../Styling/toggle.css";
import "../Styling/stripe.css";
import { v4 as uuidv4 } from "uuid";
import CustomShimmer from "./utils/Shimmer";
//import { countries } from "country-data";
import { CreditCardIcon, BuildingLibraryIcon } from "@heroicons/react/24/solid";
import Box from "@mui/material/Box";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import GooglePay from "./GooglePay";
import ApplePay from "./ApplePay";
import PayByBank from "./PayByBank";
import Confirmation from "./Confirmation";
import RecurringDatePicker from "./RecurringDatePicker";
import DoubleTheDonation from "./DoubleTheDonation";

import { isChromium, isSafari } from "react-device-detect";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin
import "@fontsource/baloo-bhai-2"; // Defaults to weight 400.
import "animate.css";
import Faqs from "./Faqs";
import FormControl from "@mui/material/FormControl";
import PaypalVenmo from "./PaypalVenmo";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GlobalCustomInputs from "./utils/GlobalCustomInputs";
import deepCopy from "./utils/DeepCopy";
import StripeCheckout from "./stripe/StripeCheckout";
import StripeACH from "./stripe/StripeACH";
import { width } from "@mui/system";

import { AddressAutofill } from "@mapbox/search-js-react";
import CountryList from "react-select-country-list";

import {
  Elements,
  useStripe,
  ExpressCheckoutElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeExpressCheckout from "./stripe/StripeExpressCheckout";
import StripeFinancialConnections from "./stripe/StripeFinancialConnections";

dayjs.extend(utc);

const FREQUENCY_MAP = {
  "One-Time": "ONE_TIME",
  Monthly: "MONTHLY",
  Weekly: "WEEKLY",
  Annually: "ANNUALLY",
  Daily: "DAILY",
};

const REVERSE_FREQUENCY_MAP = {
  ONE_TIME: "One-Time",
  MONTHLY: "Monthly",
  WEEKLY: "Weekly",
  ANNUALLY: "Annually",
  DAILY: "Daily",
};

const RECURRING_FREQUENCY_ORDER_MAP = {
  Annually: 4,
  Monthly: 3,
  Weekly: 2,
  Daily: 1,
};

const FREQUENCY_TO_TIMEPICKER_MAP = {
  ONE_TIME: "one-time",
  MONTHLY: "month",
  WEEKLY: "week",
  ANNUALLY: "year",
  DAILY: "day",
};

const PAY_BY_BANK_MINIMUM = 5; // show pay by bank for $5 but min fees is $1 for FINIX. For stripe always show

//const emojiList = ['🥰', '💚', '❤️', '🫶']
const emojiList = ["🫶", "🫶"];

const countriesMap = {
  "United States": {
    cityText: "City",
    regionText: "State",
    postalCodeText: "Zip",
  },
  Canada: {
    cityText: "City",
    regionText: "Province",
    postalCodeText: "Postal Code",
  },
  "United Kingdom": {
    cityText: "Town/City",
    regionText: "",
    postalCodeText: "Postal Code",
  },
  Australia: {
    cityText: "Suburb/City",
    regionText: "State",
    postalCodeText: "Postal Code",
  },
  France: {
    cityText: "City",
    regionText: "",
    postalCodeText: "Postal Code",
  },
  Lebanon: {
    cityText: "City/Locality",
    regionText: "District",
    postalCodeText: "Postal Code",
  },
  Kuwait: {
    cityText: "City",
    regionText: "",
    postalCodeText: "Postal Code",
  },
};

class DonorInfo extends Component {
  loadDonationFormConfig = async (event) => {
    // Ensure that the message comes from an expected source
    // if (event.origin !== 'https://your-react-app-domain.com') return;
    if (
      !(event.data.entity === "CHARITYSTACK" && event.data.action === "DONATE")
    )
      return;
    // console.log(event.data.donationData)

    const responseData = JSON.parse(event.data.donationData);

    const defaultPaymentProcessor =
      responseData["defaultPaymentProcessor"] ?? "FINIX";

    // set the stripe promise as early as possible
    if (
      defaultPaymentProcessor === "STRIPE" &&
      this.state.stripePromise === null
    ) {
      const stripeInstance = loadStripe(process.env.REACT_APP_STRIPE_PK, {
        stripeAccount: responseData["stripeAccountID"],
      });
      this.setState({
        stripePromise: stripeInstance,
      });
    }

    const MAX_ACH_FEE = 25.0;
    console.log("responsedata: ", responseData);
    const percentFee = responseData["percentFee"];
    const fixedFee = responseData["fixedFee"];
    const maxACHFee = responseData["achMaxFee"] ?? MAX_ACH_FEE;
    let break2 = <br />;
    let phoneEnabled = false;
    let frequenciesValue = "";
    let selectedFrequency = "";
    let amountDisplay = "";
    let monthly = "";
    let allFrequenciesValues = [];

    let oneTimeAmounts = responseData["oneTimeAmounts"];
    let monthlyAmounts = responseData["monthlyAmounts"];
    let yearlyAmounts = responseData["annuallyAmounts"];
    let weeklyAmounts = responseData["weeklyAmounts"];
    let dailyAmounts = responseData["dailyAmounts"];

    let defaultMonthlyAmount = responseData["defaultMonthlyAmount"];
    let defaultOneTimeAmount = responseData["defaultOneTimeAmount"];
    let defaultWeeklyAmount = responseData["defaultWeeklyAmount"];
    let defaultDailyAmount = responseData["defaultDailyAmount"];
    let defaultYearlyAmount = responseData["defaultAnnuallyAmount"];
    let customAmount = "";
    let newFees = 0;

    let defaultAmounts = monthlyAmounts;
    let defaultAmount = parseFloat(responseData["amount"]);

    let approvedForPaypalPaymentProcessing =
      responseData["approvedForPaypalPaymentProcessing"]; // ?? false; // TODO: Remove the && formName check
    let paypalPlatformPercentFee = responseData["paypalPlatformPercentFee"]; // ?? 0.02;
    let paypalPaymentProcessingFixedFee =
      responseData["paypalPaymentProcessingFixedFee"]; // ?? 0.3;
    let paypalPaymentProcessingPercentFee =
      responseData["paypalPaymentProcessingPercentFee"]; // ?? 0.035;
    let paypalThirdPartyProcessorFee =
      responseData["paypalThirdPartyProcessorFee"]; // ?? 0.02;

    let paypalMerchantID = responseData["paypalMerchantID"] ?? "";

    // if cart is not empty, then set defaultAmount to sum of the amount * quantity
    let cart = responseData["cartItems"] ?? [];
    if (cart.length > 0) {
      defaultAmount = cart.reduce((acc, item) => {
        return acc + parseFloat(item.amount) * item.quantity;
      }, 0);
    }

    let frequencyButtonStyle = "donor-field-select";

    //frequencies

    if (responseData["phoneNumber"] === true) {
      phoneEnabled = true;
      break2 = <></>;
    } else if (responseData["phoneNumber"] === false) {
      phoneEnabled = false;
    }

    // do logic to set fees and tip. Only needed if default amount selected

    // newFees = (defaultAmount * percentFee + fixedFee).toFixed(2)
    // //Recursive rollup because we can't cover the extra cost for 2.2% and 0.30
    // if (percentFee === 0.022 && fixedFee === 0.30) {
    //     newFees = (((defaultAmount + fixedFee) / (1 - percentFee)) - defaultAmount).toFixed(2)
    // }

    newFees = (
      (defaultAmount + fixedFee) / (1 - percentFee) -
      defaultAmount
    ).toFixed(2);

    let { selectedTip, calculatedTip } = this.calculateWithTip(defaultAmount);

    const showTips = responseData["showTips"];

    if (showTips === false) {
      calculatedTip = 0;
      selectedTip = "";
    }
    const showOrganizationNameField = responseData["showOrganizationNameField"];
    //const showOrganizationNameField = true;
    const showCoverFees = responseData["showCoverFees"];
    //let coverFees = true;

    const defaultCoverFees = responseData["defaultCoverFees"] ?? true;

    //default cover fees
    let coverFees = true;
    if (!defaultCoverFees) {
      coverFees = false;
    }
    console.log("coverFees: ", coverFees);

    // calculate fees with recursive formula if fees are covered
    newFees = (
      (defaultAmount + fixedFee) / (1 - percentFee) -
      defaultAmount
    ).toFixed(2);
    // if (showCoverFees === false) {
    //   coverFees = false;
    //   // if fees are not covered, use base formula
    //   newFees = (defaultAmount * percentFee + fixedFee).toFixed(2);
    // }

    // // if showPayByBankPaymentFields is true, then take the minimum of 25 and the fee amount
    // if (this.state.showPayByBankPaymentFields === true) {
    //   newFees = Math.max(newFees, 1); // minimum of 1
    //   newFees = Math.min(newFees, 25); // maximum of 25
    // }

    let selectedAmount = -1;
    if (defaultAmounts !== null) {
      if (defaultAmounts.includes(defaultAmount)) {
        selectedAmount = defaultAmount;
      }
    }

    if (defaultAmount !== 0) {
      customAmount = defaultAmount.toString();
    }

    let c = responseData["frequencies"];
    if (c !== null) {
      for (let i = 0; i < c.length; i++) {
        if (c[i] === "ONE_TIME") {
          allFrequenciesValues.push("One-Time");
        } else {
          allFrequenciesValues.push(c[i][0] + c[i].slice(1).toLowerCase());
        }
      }
    }

    let defaultFrequency = responseData["frequency"];

    if (defaultFrequency === "MONTHLY") {
      frequenciesValue = "Monthly";
      selectedFrequency = "Monthly";
      amountDisplay = "/mo";
      monthly = " per month";
    } else if (defaultFrequency === "DAILY") {
      frequenciesValue = "Daily";
      selectedFrequency = "Daily";
      amountDisplay = "/day";
      monthly = " per day";
    } else if (defaultFrequency === "WEEKLY") {
      frequenciesValue = "Weekly";
      selectedFrequency = "Weekly";
      amountDisplay = "/wk";
      monthly = " per week";
    } else if (defaultFrequency === "ANNUALLY") {
      frequenciesValue = "Annually";
      selectedFrequency = "Annually";
      amountDisplay = "/yr";
      monthly = " per year";
    } else {
      frequenciesValue = "One-Time";
      selectedFrequency = "";
    }
    console.log("cart", cart);
    const schedulePaymentMap = {};
    // create schedulePaymentMap for funds

    // Remove after ramadan for ICNA and OBAT. write undefined to endValue and remove the 5 lines below
    let hardCodedStartValue = undefined;
    let hardCodedEndValue = undefined;
    let hardCodedTimeValue = undefined;

    //12 month for sustaining masjid oct 4, 2024 implemented
    const COPPELL_CONDITION =
      responseData["nonprofitName"] === "Islamic Center of Coppell" &&
      responseData["formName"] === "Sustain Your Masjid";

    const ICNA_DEV_CONDITION =
      responseData["nonprofitName"] === "ICNA Relief USA Dev" &&
      defaultFrequency === "DAILY";
    const IHYA_FOUNDATION_CONDITION =
      responseData["nonprofitName"] === "Ihya Foundation" &&
      responseData["formName"] === "Dhul Hijja 2024" &&
      defaultFrequency === "DAILY";
    const ICNA_CONDITION =
      responseData["nonprofitName"] === "ICNA Relief USA" &&
      responseData["formName"] === "Dhul Hijja" &&
      defaultFrequency === "DAILY";
    const NAJAM_CONDITION =
      responseData["nonprofitName"] === "Najam Institute Foundation Inc" &&
      (responseData["formName"] === "Dhul Hijjah" ||
        responseData["formName"] === "Dhul Hijjah Levels" ||
        responseData["formName"] === "Dhul Hijjah - VC Campaign") &&
      defaultFrequency === "DAILY";
    const MUHSEN_CONDITION =
      responseData["nonprofitName"] === "Muhsen" &&
      responseData["formName"] === "Dhul Hijjah" &&
      defaultFrequency === "DAILY";

    //12 month for sustaining masjid oct 4, 2024 implemented
    if (COPPELL_CONDITION) {
      // take current time and add 10 months
      hardCodedEndValue = dayjs.utc().add(9, "month").local();
    }
    if (
      ICNA_CONDITION === true ||
      IHYA_FOUNDATION_CONDITION ||
      ICNA_DEV_CONDITION === true ||
      NAJAM_CONDITION ||
      MUHSEN_CONDITION
    ) {
      if (IHYA_FOUNDATION_CONDITION) {
        hardCodedStartValue = dayjs.utc("2024-06-07T17:00:00Z").local();
      } else if (NAJAM_CONDITION) {
        hardCodedStartValue = dayjs.utc("2024-06-09T00:00:00Z").local();
      } else {
        hardCodedStartValue = dayjs.utc("2024-06-06T17:00:00Z").local();
      }
      // if current time is after 2024-06-06T17:00:00Z, add 1 day until
      // current time is before hardCodedStartValue
      const today = dayjs.utc().local();
      console.log("today", today);
      while (today.isAfter(hardCodedStartValue)) {
        hardCodedStartValue = hardCodedStartValue.add(1, "day");
        console.log("hardCodedStartValue", hardCodedStartValue);
      }
      if (IHYA_FOUNDATION_CONDITION) {
        hardCodedEndValue = dayjs.utc("2024-06-16T17:00:00Z").local();
      } else if (NAJAM_CONDITION) {
        hardCodedEndValue = dayjs.utc("2024-06-18T00:00:00Z").local();
      } else {
        hardCodedEndValue = dayjs.utc("2024-06-16T17:00:00Z").local();
      }
      // convert 3am utc to local time hour and set it to hardCodedTimeValue

      if (NAJAM_CONDITION) {
        hardCodedTimeValue = dayjs.utc("2024-06-15T00:00:00Z").local();
      } else {
        hardCodedTimeValue = dayjs.utc("2024-06-15T17:00:00Z").local();
      }
    }
    // console.log("hardCodedTimeValue", hardCodedTimeValue);

    if (cart.length > 0) {
      monthly = "";
      // iterate through cart and create schedulePaymentMap for each item
      cart.forEach((item) => {
        schedulePaymentMap[item.cartItemKey] = {
          amount: item.amount,
          fund: item.fundName,
          frequency: item.frequency,
          fundraiser: item.fundraiser,
          quantity: item.quantity,
          startValue: hardCodedStartValue,
          endValue: hardCodedEndValue,
          timeValue: hardCodedTimeValue,
          timePicker: FREQUENCY_TO_TIMEPICKER_MAP[item.frequency],
          showComponent: false,
          aggregate: null,
        };
      });
    }
    // create schedulePaymentMap for fund
    else if (
      responseData["tickets"] === null ||
      responseData["tickets"] === undefined ||
      responseData["tickets"].length === 0
    ) {
      const key =
        defaultFrequency +
        "_" +
        responseData["fund"] +
        "_" +
        responseData["amount"] +
        "_" +
        responseData["formName"];

      schedulePaymentMap[key] = {
        amount: defaultAmount,
        fund: responseData["fund"],
        frequency: defaultFrequency,
        fundraiser: responseData["formName"],
        quantity: 1,
        startValue: hardCodedStartValue,
        endValue: hardCodedEndValue,
        timeValue: hardCodedTimeValue,
        timePicker: FREQUENCY_TO_TIMEPICKER_MAP[defaultFrequency],
        showComponent: false,
        aggregate: null,
      };
    }

    // Calculating Total
    let addedFees = 0;
    if (coverFees && defaultAmount > 0) {
      addedFees = parseFloat(newFees);
    }

    // const total = parseFloat((defaultAmount + calculatedTip + addedFees).toFixed(2))
    const total = parseFloat((defaultAmount + addedFees).toFixed(2));
    const merchantID = responseData["merchantID"] ?? "";

    let showCustomInputsPageHolder = true;
    if (
      responseData["customInputs"] === undefined ||
      responseData["customInputs"].length === 0
    ) {
      showCustomInputsPageHolder = false;
    }

    let customInputValues = [];
    if (responseData["customInputsValues"]) {
      customInputValues = [...responseData["customInputsValues"]];
    }

    // grabbing Finix Fraud Session Key, and then setting variables to DynamoDB values

    let selectedCountry = this.state.selectedCountry;
    let countryButtonStyle = this.state.countryButtonStyle;
    if (selectedCountry === null) {
      selectedCountry =
        responseData["defaultCountry"] === ""
          ? null
          : responseData["defaultCountry"];
      countryButtonStyle =
        responseData["defaultCountry"] === ""
          ? "donor-field-select"
          : "donor-field-country-selected";
    }

    // replace below with the value we read in from db
    let hostedPage =
      responseData["hostedPage"] === undefined
        ? null
        : responseData["hostedPage"];

    hostedPage =
      event.data.hostedPage === undefined ? null : event.data.hostedPage;
    if (hostedPage === "HOSTED_PAGE_FULLSCREEN") {
      this.props.setContainerClassName("HostedFormContainer");
    } else if (hostedPage === "HOSTED_PAGE_POPUP") {
      this.props.setContainerClassName("HostedFormPopupContainer");
    } else {
      this.props.setContainerClassName("AppContainer");
    }

    let salesforceCampaignID = responseData["salesforceCampaignID"] ?? "";
    if (cart.length > 0) {
      salesforceCampaignID = "";
    }

    const uuid = uuidv4();
    // add to responseData to use when submitting transaction
    responseData["uuid"] = uuid;

    const globalCustomInputs = responseData["globalCustomInputs"] ?? [];
    let page = "DONOR_INFO";
    if (globalCustomInputs.length > 0) {
      page = "GLOBAL_CUSTOM_INPUTS";
    }

    // for every element in globalCustomInputs, add a key called "answers" with value []
    for (let i = 0; i < globalCustomInputs.length; i++) {
      globalCustomInputs[i]["answers"] = [""];
    }

    /*  if (
       responseData["nonprofitName"] === "Rahma Worldwide Aid & Development" ||
       responseData["nonprofitName"] === "Dev"
     ) {
       countriesMap["Israel"] = {
         cityText: "City",
         regionText: "",
         postalCodeText: "Postal Code",
       };
     } */

    if (merchantID) {
      await window.Finix.Auth(
        process.env.REACT_APP_FINIX_ENVIRONMENT,
        merchantID,
        (finixSessionKey) => {
          this.setState({
            finixSessionKey: finixSessionKey,
          });
        }
      );
    }

    clearInterval(this.state.interval);
    this.setState(
      {
        thing: responseData["thing"],
        startValue: undefined,
        endValue: undefined,
        donationData: responseData,
        crowdfundingOption:
          responseData["crowdfundingOption"] === undefined
            ? undefined
            : responseData["crowdfundingOption"],
        customInputsValues: customInputValues,
        showCustomInputsPage: showCustomInputsPageHolder,
        funds: responseData["funds"],
        selectedCountry: selectedCountry,
        countryButtonStyle: countryButtonStyle,
        showPhone: phoneEnabled,
        optIn: defaultFrequency === "ONE_TIME" ? false : true,
        defaultFrequency: defaultFrequency,
        frequenciesValue: frequenciesValue,
        selectedFrequency: selectedFrequency,
        amountDisplay: amountDisplay,
        monthly: monthly,
        allFrequenciesValues: allFrequenciesValues,
        defaultAmounts: defaultAmounts,
        oneTimeAmounts: oneTimeAmounts,
        monthlyAmounts: monthlyAmounts,
        weeklyAmounts: weeklyAmounts,
        dailyAmounts: dailyAmounts,
        yearlyAmounts: yearlyAmounts,
        defaultOneTimeAmount: defaultOneTimeAmount,
        defaultMonthlyAmount: defaultMonthlyAmount,
        defaultWeeklyAmount: defaultWeeklyAmount,
        defaultYearlyAmount: defaultYearlyAmount,
        defaultDailyAmount: defaultDailyAmount,
        merchantName: responseData["nonprofitName"],
        statementDescriptor: responseData["statementDescriptor"],
        merchantID: merchantID,
        merchantIdentity: responseData["merchantIdentity"] ?? "",
        buttonPressColor: "#FFFFFF",
        lightColor: responseData["lightColor"],
        darkColor: responseData["darkColor"],
        oneTimeStyle: {
          top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        monthlyStyle: {
          top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        cardStyle: {
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        bankStyle: {
          top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        paypalStyle: {
          //    top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        nextButtonStyle: {
          top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
          marginTop: "6px",
        },
        submitButtonStyle: {
          top: "6px",
          backgroundColor: responseData["lightColor"],
          inset: responseData["buttonPressColor"],
        },
        loading:
          responseData["defaultPaymentProcessor"] === "FINIX" ||
          (responseData["defaultPaymentProcessor"] === "STRIPE" &&
            this.state.stripeAccountID)
            ? false
            : true,
        percentFee: percentFee,
        fixedFee: fixedFee,
        maxTransactionAmount: responseData["maxTransactionAmount"],
        amount: defaultAmount,
        originalAmount: defaultAmount,
        customAmount: customAmount,
        selectedTip: selectedTip,
        totalTip: calculatedTip,
        selectedAmount: selectedAmount,
        // finixSessionKey: finixSessionKey,
        fund: responseData["fund"],
        subFund: null,
        coverFees: coverFees,
        showCoverFees: showCoverFees,
        showOrganizationNameField: showOrganizationNameField,
        showTips: showTips,
        showCountries: responseData["showCountries"],
        showAddress: responseData["showAddress"],
        showForm: responseData["active"],
        fees: parseFloat(newFees),
        total: total,
        showPromos:
          responseData["hasPromoCode"] !== null &&
          responseData["hasPromoCode"] !== undefined &&
          responseData["hasPromoCode"] === true
            ? true
            : false,
        togglePromos: false,
        promoMessage: "Submit",
        totalDiscount: 0,
        promoCode: "",
        confirmedPromoCode: "",
        showScheduleDonation: responseData["showScheduleDonation"],
        showDoubleTheDonation: responseData["showDoubleTheDonation"],
        showAnonymousDonations: responseData["showAnonymousDonations"],
        doubleTheDonationPublicAPIKey:
          responseData["doubleTheDonationPublicAPIKey"],
        frequencyButtonStyle: frequencyButtonStyle,
        signUpValue: defaultFrequency,
        loadTime: 0,
        elementID: responseData["elementID"],
        elementType: responseData["elementType"],
        formName: responseData["formName"],
        faqs:
          responseData["faqs"] === null || responseData["faqs"].length === 0
            ? []
            : responseData["faqs"],
        hostedPage: hostedPage,
        tickets: responseData["tickets"],
        ticketMapping: responseData["ticketMapping"],
        cart: cart,
        cartID: responseData["sourceWebsite"] ?? "",
        salesforceCampaignID: salesforceCampaignID,
        uuid: uuid,
        schedulePaymentMap: schedulePaymentMap,
        showFinixCardPaymentFields:
          responseData["nonprofitName"] ===
            "Rahma Worldwide Aid & Development" ||
          responseData["nonprofitName"] === "Dev"
            ? false
            : true,
        showPayByBankPaymentFields: false,
        showPayByPaypalPaymentFields:
          responseData["nonprofitName"] ===
            "Rahma Worldwide Aid & Development" ||
          responseData["nonprofitName"] === "Dev"
            ? true
            : false,
        paymentMethod:
          responseData["nonprofitName"] ===
            "Rahma Worldwide Aid & Development" ||
          responseData["nonprofitName"] === "Dev"
            ? "PAYPAL"
            : "CARD",
        defaultAmount: defaultAmount,
        approvedForPaypalPaymentProcessing: approvedForPaypalPaymentProcessing,
        paypalMerchantID: paypalMerchantID,
        paypalAuthorizationCode: "",
        paypalAuthFailure: false,
        paypalAccountConnected: false,
        reloadPaypal: true,
        paypalPlatformPercentFee: paypalPlatformPercentFee,
        paypalThirdPartyProcessorFee: paypalThirdPartyProcessorFee,
        paypalPaymentProcessingFixedFee: paypalPaymentProcessingFixedFee,
        paypalPaymentProcessingPercentFee: paypalPaymentProcessingPercentFee,
        maxACHFee: maxACHFee,
        scheduledFees: 0,
        globalCustomInputs: globalCustomInputs,
        page: page,
        products: responseData["products"] ?? [],
        stripeStatementDescriptor:
          responseData["stripeStatementDescriptor"] ?? "",
        stripeAccountID: responseData["stripeAccountID"] ?? "",
        defaultPaymentProcessor:
          responseData["defaultPaymentProcessor"] ?? "FINIX",
        toggleDoubleTheDonation: responseData["nonprofitName"].includes(
          "ICNA Relief USA"
        )
          ? true
          : false,
      },
      async () => {
        // Remove after ramadan for ICNA and OBAT
        const { merchantName, formName, defaultFrequency, loading } =
          this.state;
        const ICNA_DEV_CONDITION =
          responseData["nonprofitName"] === "ICNA Relief USA Dev" &&
          defaultFrequency === "DAILY";
        const IHYA_FOUNDATION_CONDITION =
          merchantName === "Ihya Foundation" &&
          formName === "Dhul Hijja 2024" &&
          defaultFrequency === "DAILY";
        const ICNA_CONDITION =
          merchantName === "ICNA Relief USA" &&
          formName === "Dhul Hijja" &&
          defaultFrequency === "DAILY";
        const NAJAM_CONDITION =
          responseData["nonprofitName"] === "Najam Institute Foundation Inc" &&
          (responseData["formName"] === "Dhul Hijjah" ||
            responseData["formName"] === "Dhul Hijjah Levels" ||
            responseData["formName"] === "Dhul Hijjah - VC Campaign") &&
          defaultFrequency === "DAILY";
        const MUHSEN_CONDITION =
          responseData["nonprofitName"] === "Muhsen" &&
          responseData["formName"] === "Dhul Hijjah" &&
          defaultFrequency === "DAILY";
        // const GIVING_GAMES_CONDITION =
        //   responseData["nonprofitName"] === "The Giving Games Foundation" &&
        //   responseData["formName"] ===
        //     "THE GIVING GAMES FOUNDATION GENERAL FUND" &&
        //   defaultFrequency === "DAILY";
        if (
          ICNA_CONDITION === true ||
          IHYA_FOUNDATION_CONDITION ||
          ICNA_DEV_CONDITION === true ||
          NAJAM_CONDITION ||
          MUHSEN_CONDITION ||
          COPPELL_CONDITION
        ) {
          // grab only key in schedulePaymentMap
          const key = Object.keys(schedulePaymentMap)[0];
          // grab endValue from schedulePaymentMap
          const hardCodedEndValue = schedulePaymentMap[key].endValue;
          const hardCodedStartValue = schedulePaymentMap[key].startValue;
          this.calculateTotalSubscriptionCharge(
            key,
            hardCodedStartValue,
            hardCodedEndValue
          );
        }
        window.parent.postMessage(
          {
            action: "SHOW_CHECKOUT_FORM",
            entity: "CHARITYSTACK",
            sender: "CHECKOUT_FORM",
            receiver: "HOSTED_SCRIPT",
          },
          "*"
        );
        // set up DDCONF for Double the Donation if enabled
        if (this.state.showDoubleTheDonation) {
          window.parent.postMessage(
            {
              action: "ADD_DTD_DDCONF",
              entity: "CHARITYSTACK",
              sender: "CHECKOUT_FORM",
              receiver: "HOSTED_SCRIPT",
              data: JSON.stringify({
                doubleTheDonationPublicAPIKey:
                  this.state.doubleTheDonationPublicAPIKey,
              }),
            },
            "*"
          );
        }

        if (this.state.defaultPaymentProcessor === "STRIPE") {
          const stripeIntializeCheckoutURL = `${process.env.REACT_APP_STRIPE_AUXILIARY_URL}/stripe/initialize-checkout`;
          const response = await fetch(stripeIntializeCheckoutURL, {
            method: "POST",
            body: JSON.stringify({
              stripeAccountID: this.state.stripeAccountID,
            }),
          });
          const data = await response.json();
          console.log("DATA HERRE", data);
          const stripeCustomerID = data.customerID;
          // const stripeSessionID = data.sessionID;
          const stripeClientSecret = data.clientSecret;

          if (!loading) {
            this.setState({
              stripeCustomerID: stripeCustomerID,
              // stripeSessionID: stripeSessionID,
              stripeClientSecret: stripeClientSecret,
            });
          } else {
            // Set a timeout of 600ms before updating the state
            setTimeout(() => {
              this.setState({
                stripeCustomerID: stripeCustomerID,
                // stripeSessionID: stripeSessionID,
                stripeClientSecret: stripeClientSecret,
                loading: false,
              });
            }, 100);
          }
        }
      }
    );
  };

  // exitListener = (event) => {
  //   if (
  //     !(
  //       event.data.entity === "CHARITYSTACK" &&
  //       event.data.action === "CLICKED_OUTSIDE_CHECKOUT_FORM"
  //     )
  //   )
  //     return;
  //   this.exitCheckoutForm(false, "EXIT");
  // };

  constructor(props) {
    super(props);
    this.stripeCardRef = createRef();
    this.stripeACHRef = createRef();

    let iframeStates = null;
    let form = window.PaymentForm.card((state, binInformation) => {
      if (state === undefined) {
        // document.getElementById('brand').innerHTML = binInformation.cardBrand;
        //document.getElementById('bin').innerHTML = JSON.stringify(binInformation, null, '  ');
      } else {
        this.setState({ iframeStates: state });
      }
    });

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let nonprofitName = params.get("nonprofitName");
    let formName = params.get("formName");

    if (nonprofitName === null || formName === null) {
      nonprofitName = ".NGO Charities";
      formName = "Form 1";
    }

    this.state = {
      thing: null,
      donationData: null,
      crowdfundingOption: undefined,
      elementID: "",
      elementType: "",
      eventListener: this.loadDonationFormConfig,
      // exitListener: this.exitListener,
      startValue: undefined,
      endValue: undefined,
      showDatePickerInput: false,
      randomEmoji: emojiList[Math.floor(Math.random() * emojiList.length)],
      formName: formName,
      amount: 0,
      customAmount: "",
      customTip: "",
      firstName: "",
      lastName: "",
      accountNumber: "",
      routingNumber: "",
      accountType: "Checking",
      donationSubmitted: false,
      donationCompleted: false,
      email: "",
      orgName: "",
      phoneNumber: null,
      showPhone: false,
      uuid: uuidv4(),
      timeLeft: NaN,
      form: form,
      iframeStates: iframeStates,
      optIn: true,
      paymentMethod: "CARD",
      amountError: false,
      creditCardError: false,
      coverFees: true,
      coverFeesText: "",
      fees: 0.0,
      errorDisplayTime: 0,
      errorMessage: "",
      page: "DONOR_INFO",
      cardInputs: <div />,
      setPage: props.setPage,
      loading: true,
      loadTime: 2,
      interval: null,
      errorMessageInterval: null,
      streetAddress: "",
      streetAddress2: "",
      city: null,
      tipRange: [],
      state: "",
      zip: null,
      defaultAmounts: [10, 25, 50, 100],
      oneTimeAmounts: [],
      monthlyAmounts: [],
      yearlyAmounts: [],
      weeklyAmounts: [],
      dailyAmounts: [],
      defaultOneTimeAmount: 0,
      defaultMonthlyAmount: 0,
      defaultWeeklyAmount: 0,
      defaultYearlyAmount: 0,
      defaultDailyAmount: 0,
      selectedAmount: -1,
      merchantName: "",
      merchantID: "",
      merchantIdentity: "",
      buttonPressColor: "",
      selectedTip: "",
      totalTip: 0,
      lightColor: "",
      nextButtonStyle: { top: "6px", paddingBottom: "0px" },
      categoriesButtonStyle: "donor-field-select",
      frequencyButtonStyle: "donor-field-select",
      accountTypeButtonStyle: "donor-field-select",
      amountDisplay: "",
      monthly: "",
      signUpValue: "",
      countryButtonStyle: "donor-field-select",
      submitButtonStyle: { top: "6px" },
      monthlyStyle: "",
      cardStyle: "",
      bankStyle: "",
      paypalStyle: "",
      oneTimeStyle: "",
      percentFee: 0.032,
      fixedFee: 0.3,
      statementDescriptor: "",
      country: "",
      funds: "",
      maxTransactionAmount: 1000,
      finixSessionKey: "",
      fund: null,
      subFund: "",
      showCoverFees: false,
      showOrganizationNameField: false,
      organizationNameField: false,
      // showCoverFeesModule: false,
      //setting false here to remove functionality
      showTips: false,
      showCountries: true,
      showAddress: true,
      showForm: true,
      frequenciesValue: "",
      allFrequenciesValues: [],
      selectedFrequency: "",
      defaultFrequency: "",
      total: 0,
      showApplePay: false,
      showFinixCardPaymentFields: true,
      showPayByBankPaymentFields: false,
      showPayByPaypalPaymentFields: false,
      showScheduleDonation: false,
      showDoubleTheDonation: false,
      showAnonymousDonations: false,
      company: { companyName: "", companyID: "" },
      showCustomInputsPage: true,
      customInputsValues: [],
      toggleScheduleDonation: false,
      toggleDoubleTheDonation: false,
      togglePromos: false,
      promoMessage: "Submit",
      totalDiscount: 0,
      promoCode: "",
      confirmedPromoCode: "",
      showPromos: false,
      toggleAnonymousDonations: false,
      anonymous: false,
      faqs: [],
      hostedPage: null,
      tickets: [],
      ticketMapping: {},
      cart: [],
      cartID: "",
      salesforceCampaignID: "",
      donationFlagPopup: true,
      schedulePaymentMap: {},
      originalAmount: 0,
      isChromium: true,
      scheduledFees: 0,
      globalCustomInputs: [],
      products: [],
      stripeStatementDescriptor: "",
      stripeAccountID: "",
      defaultPaymentProcessor: "",
      countries: [],
      selectedCountry: "",
      streetAddress: "",
      streetAddress2: "",
      countrySettings: {
        cityText: "City",
        regionText: "Region",
        postalCodeText: "Postal Code",
      },
      mapBoxAccess: true,
      stripeCustomerID: null,
      accountHolderType: "individual",
      accountHolderTypeButtonStyle: "donor-field-select",
      stripePromise: null,
      stripeWalletAvailable: true,
      // stripeSessionID: null,
      stripeClientSecret: null,
      scheduledWalletFees: 0,
      // stripeExpressCheckoutOptions: {
      //   mode: "payment",
      //   amount: 100,
      //   currency: "usd",
      //   setupFutureUsage: "off_session",
      //   // onBehalfOf: "acct_1PaKyPFPKZBe2fLV",
      //   // paymentMethodTypes: ["google_pay", "apple_pay"],
      //   // Customizable with appearance API.
      //   appearance: {
      //     /*...*/
      //   },
      // },
    };
  }

  setStripeClientSecret = (stripeClientSecret, callback) => {
    this.setState({ stripeClientSecret: stripeClientSecret }, () => {
      if (callback) {
        callback();
      }
    });
  };

  setStripeWalletAvailable = (stripeWalletAvailable) => {
    this.setState({ stripeWalletAvailable: stripeWalletAvailable });
  };

  // callStripeACHPaymentFunction = async () => {
  //   const { name, accountNumber, routingNumber } = this.state;
  //   if (!stripe) {
  //     return;
  //   }

  //   // Create a PaymentMethod
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "us_bank_account",
  //     us_bank_account: {
  //       routing_number: routingNumber,
  //       account_number: accountNumber,
  //       account_holder_type: "individual",
  //     },
  //     billing_details: {
  //       name: name,
  //     },
  //   });

  //   if (error) {
  //     console.error(error);
  //   } else {
  //     console.log("Payment Method:", paymentMethod);
  //     // You can now use the payment method in your server-side code
  //   }
  // };

  callStripeACHPaymentFunction = () => {
    this.stripeACHRef.current.handleSubmit();
  };

  callStripeCardPaymentFunction = () => {
    this.stripeCardRef.current.handleSubmit();
  };

  setGlobalCustomInputs = (globalCustomInputs) => {
    this.setState({ globalCustomInputs: globalCustomInputs });
  };

  calculateTotalSubscriptionCharge = (key, startDate, endDate) => {
    const { schedulePaymentMap } = this.state;
    if (!endDate) {
      // set aggregate to null if end date is not set and update state
      const updatedSchedulePaymentMap = { ...schedulePaymentMap };
      console.log("key", key);
      console.log("updatedSchedulePaymentMap", updatedSchedulePaymentMap);
      updatedSchedulePaymentMap[key].aggregate = null;
      this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
      return;
    }

    const amount =
      schedulePaymentMap[key].amount * schedulePaymentMap[key].quantity;
    const frequency = schedulePaymentMap[key].frequency;

    console.log("frequency in calculateTotalSubscriptionCharge", frequency);

    startDate = startDate || dayjs();
    startDate = startDate.startOf("day");
    endDate = dayjs(endDate).startOf("day");

    let totalDays = endDate.diff(startDate, "day") + 1;
    let aggregate = null;

    console.log("FREQUENCYYY", frequency);
    console.log("amounttttt", amount);

    if (frequency === "DAILY") {
      aggregate = totalDays * amount;
    } else if (frequency === "WEEKLY") {
      aggregate = Math.ceil(totalDays / 7) * amount;
    } else if (frequency === "MONTHLY") {
      aggregate =
        Math.round(endDate.diff(startDate, "month", true) + 1) * amount;
    } else if (frequency === "ANNUALLY") {
      aggregate =
        Math.round(endDate.diff(startDate, "year", true) + 1) * amount;
    } else {
      aggregate = null;
    }

    console.log("aggregate", aggregate);
    console.log(typeof aggregate, typeof amount, typeof totalDays);

    const updatedSchedulePaymentMap = { ...schedulePaymentMap };
    updatedSchedulePaymentMap[key].aggregate = aggregate;
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
  };

  exitCheckoutForm = (donationSuccessful, exitType) => {
    const { hostedPage, cart } = this.state;
    // post message to parent window
    //trying to clear everything
    console.log("Clearing");
    this.setState({
      customAmount: "",
      firstName: "",
      lastName: "",
      organizationNameField: false,
      showOrganizationNameField: false,
      accountNumber: "",
      routingNumber: "",
      accountType: "Checking",
      email: "",
      orgName: "",
      phoneNumber: null,
      showPhone: false,
      form: this.state.form, // Assuming form setup should not be reset
      optIn: true,
      paymentMethod: "CARD",
      coverFees: true,
      errorDisplayTime: 0,
      page: "DONOR_INFO",
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      funds: "",
      selectedCountry: "",
      paypalAccountConnected: false,
      paypalAuthorizationCode: "",
      paypalAuthFailure: false,
      reloadPaypal: true,
    });
    //console.log("Going into donor info set state on exit");
    this.setState(
      {
        page: "DONOR_INFO",
        uuid: uuidv4(),
        reloadPaypal: false,
      },
      () => {
        window.parent.postMessage(
          {
            action: "EXIT_CHECKOUT_FORM",
            entity: "CHARITYSTACK",
            sender: "CHECKOUT_FORM",
            receiver: "HOSTED_SCRIPT",
            data: {
              donationSuccessful: donationSuccessful,
              elementID: this.state.elementID,
              elementType: this.state.elementType,
              exitType:
                hostedPage === "HOSTED_PAGE_FULLSCREEN" ? "EXIT" : exitType,
            },
          },
          "*"
        );
        if (cart.length > 0) {
          window.parent.postMessage(
            {
              action: "ADD_TO_CART_RESIZE",
              entity: "CHARITYSTACK",
              sender: "CHECKOUT_FORM",
              notification: true,
              message: "Your items will remain in your cart.",
            },
            "*"
          );
        }
      }
    );
    if (this.stripeCardRef.current) {
      this.stripeCardRef.current.resetForm();
    }
  };

  setErrorMessage = (errorMessage) => {
    const { lightColor } = this.state;
    this.setState({
      errorMessage: errorMessage,
      errorDisplayTime: 0,
      errorMessageInterval: setInterval(
        () =>
          this.setState({
            errorDisplayTime: this.state.errorDisplayTime + 1,
          }),
        1000
      ),
      nextButtonStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
        paddingTop: "0px",
      },
      donationSubmitted: false,
    });
  };

  schedulePaymentShowComponent = () => {
    // return true or false if all showComponent is false
    // also set an error message if a false item is found
    const { schedulePaymentMap, lightColor } = this.state;
    for (let key in schedulePaymentMap) {
      if (schedulePaymentMap[key].showComponent === true) {
        this.setState({
          errorMessage: `please apply changes to schedule payment(s)`,
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000
          ),
          nextButtonStyle: {
            top: "6px",
            backgroundColor: lightColor,
            inset: lightColor,
            paddingTop: "0px",
          },
        });
        return true;
      }
    }
    return false;
  };

  setToggleScheduleDonation = (toggleScheduleDonation) => {
    this.setState({ toggleScheduleDonation: toggleScheduleDonation });
  };

  setCompany = (entry) => {
    this.setState({ company: entry });
  };

  setStartValue = (key, date) => {
    const updatedSchedulePaymentMap = { ...this.state.schedulePaymentMap };
    if (updatedSchedulePaymentMap[key]) {
      updatedSchedulePaymentMap[key].startValue = date;
    }
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
  };

  setEndValue = (key, date) => {
    const updatedSchedulePaymentMap = { ...this.state.schedulePaymentMap };
    if (updatedSchedulePaymentMap[key]) {
      updatedSchedulePaymentMap[key].endValue = date;
    }
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
  };

  setTimeValue = (key, time) => {
    const updatedSchedulePaymentMap = { ...this.state.schedulePaymentMap };
    if (updatedSchedulePaymentMap[key]) {
      updatedSchedulePaymentMap[key].timeValue = time;
    }
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
  };

  setShowScheduleDonationComponent = (key, showComponent) => {
    console.log(key);
    const updatedSchedulePaymentMap = { ...this.state.schedulePaymentMap };
    if (updatedSchedulePaymentMap[key]) {
      console.log("setting showComponent");
      updatedSchedulePaymentMap[key].showComponent = showComponent;
    }
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
  };

  setShowApplePay = (showApplePay) => {
    this.setState({ showApplePay: showApplePay });
  };

  handleSelectedFundChange = (e) => {
    let value = e.target.value;

    this.setState({
      fund: value,
      categoriesButtonStyle: "donor-field-selected",
    });
  };

  handleFreeTicketPurchase = async (event) => {
    const {
      errorMessageInterval,
      finixSessionKey,
      showPhone,
      lightColor,
      amount,
      fees,
      email,
      orgName,
      uuid,
      form,
      optIn,
      coverFees,
      setPage,
      firstName,
      organizationNameField,
      lastName,
      phoneNumber,
      showAddress,
      streetAddress,
      streetAddress2,
      city,
      totalTip,
      state,
      zip,
      selectedCountry,
      merchantName,
      merchantID,
      statementDescriptor,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      ticketMapping,
      tickets,
      confirmedPromoCode,
      showOrganizationNameField,
    } = this.state;
    clearInterval(errorMessageInterval);
    this.setState({
      donationSubmitted: true,
      errorMessage: "",
    });
    //showOrganizationNameField = false;
    //organizationNameField = false;
    console.log(
      "showOrganizationNameField in free ticket:",
      showOrganizationNameField
    );
    console.log("organizationNameField in free ticket:", organizationNameField);

    /*  if (
      showOrganizationNameField === false &&
      organizationNameField === true &&
      orgName === ""
    ) {
      this.setState({
        errorMessage: "please enter a organization name",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          paddingTop: "0px",
        },
      });
      return false;
    } */
    if (
      (streetAddress === null ||
        streetAddress === "" ||
        city === null ||
        city === "" ||
        zip === null ||
        zip === "" ||
        selectedCountry === "" ||
        selectedCountry === null) &&
      showAddress === true
    ) {
      this.setState({
        errorMessage: "please fill out all address fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } /* else if (showAddress === true && city.match(/\D+$/) === null) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].cityText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          paddingTop: "0px",
        },
      });
      return false;
    } else if (
      showAddress === true &&
      state.match(/\D+$/) === null &&
      countriesMap[selectedCountry].regionText !== ""
    ) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].regionText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (showAddress === true && zip.match(/[0-9]*/ /*) === null) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].postalCodeText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } */ else if (
      (streetAddress === null ||
        streetAddress === "" ||
        city === null ||
        city === "" ||
        zip === null ||
        zip === "" ||
        state === "" ||
        state === null) &&
      showAddress === true &&
      selectedCountry === "United States"
    ) {
      this.setState({
        errorMessage: "please fill out all address fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (firstName === "" || lastName === "") {
      this.setState({
        errorMessage: "please enter your full name",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (
      firstName.match(/\D+$/) === null ||
      lastName.match(/\D+$/) === null
    ) {
      this.setState({
        errorMessage: "letters only for name fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
      });
      return false;
    } else if (
      showPhone === true &&
      (phoneNumber === "" || phoneNumber === null || phoneNumber.length < 10)
    ) {
      this.setState({
        errorMessage: "please enter a valid phone number",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
      });
      return false;
    } else if (email.indexOf("@") === -1 || email.indexOf(".") === -1) {
      this.setState({
        errorMessage: "please enter a valid email",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
      });
      return false;
    } else {
      //   console.log("all good");
      // event.preventDefault()

      // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
      let joinedCustomInputs = deepCopy(customInputsValues);
      joinedCustomInputs = joinedCustomInputs.concat(
        this.state.globalCustomInputs
      );

      const requestOptions = {
        method: "POST",
        headers: { accept: "*/*", "content-type": "application/json" },
        body: JSON.stringify({
          amount: 0.0,
          email: email,
          orgName: orgName,
          uuid: uuid,
          donationFrequency: "ONE_TIME",
          coverFees: false,
          merchantID: merchantID,
          merchantName: merchantName,
          name: firstName + " " + lastName,
          phone: phoneNumber,
          address: streetAddress,
          address2: streetAddress2,
          city: city,
          tip: 0,
          region: state,
          zip: zip,
          country: selectedCountry,
          finixSessionKey: finixSessionKey,
          fund: fund,
          subFund: subFund,
          formName: formName,
          dateEndCharge: endValue,
          customInputs: joinedCustomInputs,
          elementID: elementID,
          crowdfundingOption: crowdfundingOption,
          anonymous: false,
          tickets: tickets,
          ticketMapping: ticketMapping,
          color: lightColor,
          promoCode: confirmedPromoCode,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      };

      console.log("request options:", requestOptions);
      // submitting transaction to finix-server-2 lambda
      let response = await fetch(
        process.env.REACT_APP_FREE_TRANSACTION_URL,
        requestOptions
      );
      let responseBody = await response.json();
      if (!response.ok) {
        this.setState({
          errorMessage: responseBody,
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000
          ),
          donationSubmitted: false,
          submitButtonStyle: {
            top: "6px",
            backgroundColor: lightColor,
            inset: lightColor,
          },
        });
      } else {
        let dtd_company_name = "";
        // console.log('hello confirmation')
        this.setState({
          page: "CONFIRMATION",
          donationSubmitted: false,
        });
        // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
      }
    }

    // } catch (e) {
    //   alert("Something went wrong when submitting your ticket\n" + e);
    //   this.setState({
    //     donationSubmitted: false,
    //     submitButtonStyle: {
    //       top: "6px",
    //       backgroundColor: lightColor,
    //       inset: lightColor,
    //     },
    //   });
    // }
  };

  setErrorPromo = (promoMessage) => {
    this.setState({ promoMessage: promoMessage });

    // Set a timeout to hide the error message after 2 seconds
    setTimeout(() => {
      this.setState({ promoMessage: "Submit" });
    }, 3000);
  };

  handlePromoCheck = async (event) => {
    const {
      elementID,
      tickets,
      promoCode,
      percentFee,
      fixedFee,
      showPayByBankPaymentFields,
      showPayByPaypalPaymentFields,
      coverFees,
      paypalPaymentProcessingFixedFee,
      paypalPaymentProcessingPercentFee,
      paypalThirdPartyProcessorFee,
      paypalPlatformPercentFee,
      maxACHFee,
      defaultPaymentProcessor,
    } = this.state;

    // event.preventDefault()

    if (this.state.totalDiscount > 0) {
      this.setErrorPromo("InValid");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { accept: "*/*", "content-type": "application/json" },
      body: JSON.stringify({
        elementID: elementID,
        tickets: tickets,
        promoCode: promoCode,
      }),
    };

    // submitting transaction to finix-server-2 lambda
    let response = await fetch(
      process.env.REACT_APP_PROMO_CHECK_URL,
      requestOptions
    );
    // check if response code is 400
    if (response.status === 400) {
      this.setErrorPromo("Not Valid");
    } else {
      let responseBody = await response.json();
      if (!response.ok) {
        this.setErrorPromo("Invalid");
      } else {
        if (responseBody["totalDiscountedAmount"] === 0) {
          this.setErrorPromo("Invalid");
        } else {
          this.setState({
            totalDiscount: responseBody["totalDiscountedAmount"],
          });
          this.setErrorPromo("Applied");

          let newAmounts = [];
          let defaultAmount = 0;
          let selectedAmount = -1;
          let customAmount = "";
          let newFees = 0;
          let roundedSubtotal = parseFloat(responseBody["subtotal"].toFixed(2));

          newFees = (
            (roundedSubtotal + fixedFee) / (1 - percentFee) -
            roundedSubtotal
          ).toFixed(2);

          // Calculating Fees with base formula if fees are not covered
          if (!coverFees) {
            newFees = (roundedSubtotal * percentFee + fixedFee).toFixed(2);
          }

          if (showPayByBankPaymentFields) {
            newFees = Math.max(
              newFees,
              defaultPaymentProcessor === "STRIPE" ? 2 : 1
            ); // minimum of 1
            newFees = Math.min(newFees, maxACHFee); // maximum of 25
          }

          if (showPayByPaypalPaymentFields === true) {
            console.log("paypal fixedFee fee", paypalPaymentProcessingFixedFee);
            console.log(
              "paypal percentFee fee",
              paypalPaymentProcessingPercentFee
            );
            console.log("paypal third party fee", paypalThirdPartyProcessorFee);
            console.log("paypal platform fee", paypalPlatformPercentFee);
            console.log("roundedSubtotal", roundedSubtotal);
            // 100 -> 104.2
            // 100 +.30 n
            let newPaypalPlatformPercentFee =
              paypalPaymentProcessingPercentFee +
              paypalPlatformPercentFee +
              paypalThirdPartyProcessorFee;
            let newPaypalFee = parseFloat(
              (
                (roundedSubtotal + paypalPaymentProcessingFixedFee) /
                  (1 - newPaypalPlatformPercentFee) -
                roundedSubtotal
              ).toFixed(2)
            );
            newFees = newPaypalFee; // minimum of 1
            //   newFees = Math.min(newFees, 25); // maximum of 25
          }

          // Calculating Total
          let addedFees = 0;
          if (coverFees && roundedSubtotal > 0) {
            addedFees = parseFloat(newFees);
          }

          // const total = parseFloat((defaultAmount + calculatedTip + addedFees).toFixed(2))
          const total = parseFloat((roundedSubtotal + addedFees).toFixed(2));

          this.setState({
            confirmedPromoCode: promoCode,
            amount: roundedSubtotal,
            fees: parseFloat(newFees),
            total: total,
          });
        }
      }
    }

    // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))

    // } catch (e) {
    //   alert("Something went wrong when submitting your ticket\n" + e);
    //   this.setState({
    //     donationSubmitted: false,
    //     submitButtonStyle: {
    //       top: "6px",
    //       backgroundColor: lightColor,
    //       inset: lightColor,
    //     },
    //   });
    // }
  };

  handleSelectedAccountTypeChange = (e) => {
    let value = e.target.value;
    this.setState({
      accountType: value,
      accountTypeButtonStyle: "donor-field-selected",
    });
  };

  handleSelectedAccountHolderTypeChange = (e) => {
    let value = e.target.value;
    this.setState({
      accountHolderType: value,
      accountHolderTypeButtonStyle: "donor-field-selected",
    });
  };

  handleSelectedCountryChange = (e) => {
    // let value = e.target.value;
    const { countries, country } = this.state;
    const selectedCountry = e.target.value;
    const countrySettings = this.getCountrySettings(selectedCountry);
    console.log("value : ", e, countrySettings);

    this.setState({
      selectedCountry: selectedCountry,
      countrySettings: countrySettings,
      countryButtonStyle: "donor-field-country-selected",
    });
  };

  getCountrySettings = (country) => {
    console.log(`Looking up country: "${country}"`); // Log the country value
    console.log(`Available countries: ${Object.keys(countriesMap).join(", ")}`); // Log available keys

    if (!country) {
      console.log("Country is undefined or null");
      return {
        cityText: "City",
        regionText: "Region",
        postalCodeText: "Postal Code",
      };
    }

    const trimmedCountry = country.trim();
    const countrySettings = countriesMap[trimmedCountry];

    if (!countrySettings) {
      console.warn(`Country settings not found for "${trimmedCountry}"`);
      return {
        cityText: "City",
        regionText: "Region",
        postalCodeText: "Postal Code",
      };
    }

    return countrySettings;
  };

  registerDonationForDoubleTheDonation = () => {
    const {
      fund,
      uuid,
      fees,
      coverFees,
      amount,
      firstName,
      lastName,
      organizationNameField,
      orgName,
      email,
      zip,
      city,
      state,
      streetAddress,
      streetAddress2,
      country,
      phone,
      optIn,
      doubleTheDonationPublicAPIKey,
      company,
    } = this.state;
    if (window.doublethedonation) {
      // Don't break your page if our plugin doesn't load for any reason
      const doublethedonation = window.doublethedonation;

      // creating values to pass into confirmation page
      let addedFees = 0;
      if (coverFees && amount !== 0) {
        addedFees = fees;
      }
      let total = amount + addedFees;

      // grab input with name doublethedonation_company_id and doublethedonation_company_name
      // const dtd_company_id = document.getElementsByName('doublethedonation_company_id')[0].value;
      // const dtd_company_name = document.getElementsByName('doublethedonation_company_name')[0].value;

      // console.log('dtd_company_id', dtd_company_id)
      // console.log('dtd_company_name', dtd_company_name)
      // create a string of the current timestamp in the format 2023-02-22T10:15:30-05:00
      const timestamp = new Date().toISOString();

      const data = {
        "360matchpro_public_key": doubleTheDonationPublicAPIKey, // Replace this key with the client's 360MatchPro public key.
        partner_identifier: "CHARI-WQ7YtFhJzCGY4Kdu", //  Replace this with your partner identifier. This will be securely provided to you by the Double the Donation Partnerships Team and is NOT the same as your partner API key.
        campaign: fund,
        donation_identifier: uuid,
        donation_amount: total,
        donor_first_name: firstName,
        donor_last_name: lastName,
        donor_email: email,
        donor_address: {
          zip: zip ? zip : "",
          city: city ? city : "",
          state: state ? state : "",
          address1: streetAddress ? streetAddress : "",
          address2: streetAddress2 ? streetAddress2 : "",
          country: country ? country : "",
        },
        donor_phone: phone ? phone : "",
        donation_datetime: timestamp,
        recurring: optIn.toString(),
      };
      if (company.companyName !== "" && company.companyID !== "") {
        data["doublethedonation_company_id"] = company.companyID;
        data["doublethedonation_entered_text"] = company.companyName;
        // data["doublethedonation_company_id"] = dtd_company_id;
        // data["doublethedonation_entered_text"] = dtd_company_name;
      }

      doublethedonation.integrations.core.register_donation(data);

      return company.companyName;
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    const {
      errorMessageInterval,
      errorDisplayTime,
      loadTime,
      interval,
      amount,
      page,
      cartID,
      schedulePaymentMap,
      fixedFee,
      percentFee,
      optIn,
      showPayByBankPaymentFields,
      showPayByPaypalPaymentFields,
      originalAmount,
      coverFees,
      paypalPaymentProcessingFixedFee,
      paypalPaymentProcessingPercentFee,
      paypalThirdPartyProcessorFee,
      paypalPlatformPercentFee,
      totalDiscount,
      maxACHFee,
      stripeAccountID,
      stripeClientSecret,
      defaultPaymentProcessor,
    } = this.state;
    // // // console.log('error display time', errorDisplayTime)
    // // // console.log(errorMessageInterval)

    // if (stripeAccountID && stripeClientSecret) {
    //   const stripe = await this.state.stripePromise;
    //   const { error, financialConnectionsSession } =
    //     await stripe.collectFinancialConnectionsAccounts({
    //       clientSecret: this.state.stripeClientSecret,
    //     });

    //   console.log(
    //     "financialConnectionsSession",
    //     error,
    //     financialConnectionsSession
    //   );
    // }

    // if (prevState.stripeAccountID !== stripeAccountID) {
    //   const stripeInstance = loadStripe(process.env.REACT_APP_STRIPE_PK, {
    //     stripeAccount: stripeAccountID,
    //   });
    //   this.setState({ stripePromise: stripeInstance });
    // }

    if (loadTime >= 1) {
      clearInterval(interval);
      this.setState({ loadTime: 0, loading: false }, () => {
        console.log("finished loading", this.state.loading);
      });
    }

    if (errorDisplayTime >= 3) {
      clearInterval(errorMessageInterval);
      this.setState({
        errorDisplayTime: 0,
        errorMessage: "",
      });
    }

    if (page === "CONFIRMATION") {
      if (cartID !== "") {
        // Clear Add to Cart
        window.parent.postMessage(
          {
            action: "CLEAR_CART",
            entity: "CHARITYSTACK",
            sender: "CHECKOUT_FORM",
            receiver: "HOSTED_SCRIPT",
            id: cartID,
          },
          "*"
        );
      }
    }
    if (
      this.state.amount !== prevState.amount &&
      prevState.amount > 0 &&
      this.state.amount === 0
    ) {
      console.log(amount);
    }
    if (
      this.state.schedulePaymentMap !== prevState.schedulePaymentMap &&
      page !== "CONFIRMATION"
    ) {
      const scheduledTotal = this.calculateScheduledDonationTotal();
      const newAmount = originalAmount - scheduledTotal - totalDiscount;
      // recalculate fees using recursive formula
      let newFees = (
        (newAmount + fixedFee) / (1 - percentFee) -
        newAmount
      ).toFixed(2);
      // Calculating Fees with base formula if fees are not covered
      if (!coverFees) {
        newFees = (newAmount * percentFee + fixedFee).toFixed(2);
      }

      // if showPayByBankPaymentFields is true, then take the minimum of 25 and the fee amount
      if (showPayByBankPaymentFields === true) {
        newFees = Math.max(
          newFees,
          defaultPaymentProcessor === "STRIPE" ? 2 : 1
        ); // minimum of 1
        newFees = Math.min(newFees, maxACHFee); // maximum of 25
      }
      // if showPayByPaypalPaymentFields is true, then add another 1% for the fee
      if (showPayByPaypalPaymentFields === true) {
        console.log("paypal fixedFee fee", paypalPaymentProcessingFixedFee);
        console.log("paypal percentFee fee", paypalPaymentProcessingPercentFee);
        console.log("paypal third party fee", paypalThirdPartyProcessorFee);
        console.log("paypal platform fee", paypalPlatformPercentFee);
        console.log("newAmount", newAmount);
        // 100 -> 104.2
        // 100 +.30 n
        let newPaypalPlatformPercentFee =
          paypalPaymentProcessingPercentFee +
          paypalPlatformPercentFee +
          paypalThirdPartyProcessorFee;
        let newPaypalFee = parseFloat(
          (
            (newAmount + paypalPaymentProcessingFixedFee) /
              (1 - newPaypalPlatformPercentFee) -
            newAmount
          ).toFixed(2)
        );
        newFees = newPaypalFee; // minimum of 1
        //   newFees = Math.min(newFees, 25); // maximum of 25
      }

      // Calculating Total
      let addedFees = 0;
      if (coverFees && newAmount > 0) {
        addedFees = parseFloat(newFees);
      }

      // const total = parseFloat((amount + totalTip + addedFees).toFixed(2))
      const total = parseFloat((newAmount + addedFees).toFixed(2));
      console.log("newAmount", newAmount);
      console.log("total", total);
      console.log("schedulePaymentMap", schedulePaymentMap);
      this.setState({
        amount: newAmount,
        total: total,
        fees: parseFloat(newFees),
      });
    }
    // check if scheduledFees need to be updated
    if (
      (schedulePaymentMap !== prevState.schedulePaymentMap &&
        page !== "CONFIRMATION") ||
      coverFees !== prevState.coverFees ||
      showPayByBankPaymentFields !== prevState.showPayByBankPaymentFields
    ) {
      this.calculateScheduledDonationFees();
    }
  }

  calculateScheduledDonationFees = () => {
    // first create a grouping of the scheduled donations by frequency, start date, and end date
    // for start date and end date comparisons, use only month, day, and year
    // store the amount total for each group as the value
    // then calculate the fees for each group
    // then sum the fees for all groups
    const {
      schedulePaymentMap,
      fixedFee,
      percentFee,
      coverFees,
      showPayByBankPaymentFields,
      maxACHFee,
      defaultPaymentProcessor,
    } = this.state;
    let fees = 0;
    let groupedPayments = {};
    for (let key in schedulePaymentMap) {
      let payment = schedulePaymentMap[key];
      let startDate = payment.startValue ?? undefined;
      if (startDate === undefined) {
        continue;
      }
      let endDate = payment.endValue ?? undefined;
      if (startDate) {
        startDate = dayjs(startDate).format("MM-DD-YYYY");
      }
      if (endDate) {
        endDate = dayjs(endDate).format("MM-DD-YYYY");
      }
      let groupKey = `${payment.frequency}-${startDate}-${endDate}`;
      if (groupedPayments[groupKey]) {
        groupedPayments[groupKey] += payment.amount * payment.quantity;
      } else {
        groupedPayments[groupKey] = payment.amount * payment.quantity;
      }
    }

    console.log("groupedPayments", groupedPayments);
    let walletFees = 0;
    for (let key in groupedPayments) {
      let grouped_amount = groupedPayments[key];
      let newFees = (
        (grouped_amount + fixedFee) / (1 - percentFee) -
        grouped_amount
      ).toFixed(2);
      // Calculating Fees with base formula if fees are not covered
      if (!coverFees) {
        newFees = (grouped_amount * percentFee + fixedFee).toFixed(2);
      }
      walletFees += parseFloat(newFees);
      // if showPayByBankPaymentFields is true, then take the minimum of 25 and the fee amount
      if (showPayByBankPaymentFields === true) {
        newFees = Math.max(
          newFees,
          defaultPaymentProcessor === "STRIPE" ? 2 : 1
        ); // minimum of 1
        newFees = Math.min(newFees, maxACHFee); // maximum of 25
      }
      console.log(key);
      console.log("newFees", newFees);
      fees += parseFloat(newFees);
    }
    console.log("scheduledFees", fees);
    this.setState({ scheduledFees: fees, scheduledWalletFees: walletFees });
  };

  componentWillUnmount() {
    clearInterval(this.state.interval);

    // remove window event listener
    window.removeEventListener("message", this.state.eventListener);
    // window.removeEventListener("message", this.state.exitListener);
  }

  async componentDidMount() {
    window.addEventListener("message", this.state.eventListener, false);
    // window.addEventListener("message", this.state.exitListener, false);

    // chromium browser check
    const isChromium = !!window.chrome || /CriOS/.test(navigator.userAgent);

    // this.setState({ interval: setInterval(() => this.setState({ loadTime: this.state.loadTime + 1 }), 1000) })

    let cardInputs = (
      <div>
        <div class="fake-field">
          <div
            id="field-wrapper-number"
            style={{ width: "100%" }}
            className="field-wrapper"
          ></div>
        </div>
        <div class="fake-field">
          <div
            id="field-wrapper-expiration_date"
            style={{ width: "50%" }}
            className="field-wrapper"
          ></div>
          <div
            id="field-wrapper-security_code"
            style={{ width: "50%" }}
            className="field-wrapper"
          ></div>
          <span
            id="field-wrapper-address_postal_code"
            className="field-wrapper"
          ></span>
        </div>
      </div>
    );

    const countryList = CountryList().getData(); // Getting the country list

    console.log("Transformed country array:", countryList);
    // this.props.setContainerClassName("HostedFormContainer");

    // We only create a customer here on mount to allow for the modal to render faster
    // const stripeCustomerURL = `${process.env.REACT_APP_STRIPE_AUXILIARY_URL}/stripe/customer`;
    // const response = await fetch(stripeCustomerURL, {
    //   method: "POST",
    //   body: JSON.stringify({ stripeAccountID: "" }),
    // });
    // const data = await response.json();
    // const stripeCustomerID = data.customer_id;

    this.setState(
      {
        countries: countryList,
        cardInputs: cardInputs,
        isChromium: isChromium,
        // stripeCustomerID: stripeCustomerID,
        // hostedPage: true,
      },
      () => {
        this.insertFinixIframes(this.state.form);
      }
    );
  }

  setCustomInputsValues = (customInputsValues) => {
    this.setState({
      customInputsValues: customInputsValues,
    });
  };

  // Inserting Finix Card Info fields for second page
  insertFinixIframes = (form) => {
    this.defineField(form, "field-wrapper-number", "number", "Card Number");
    this.defineField(
      form,
      "field-wrapper-expiration_date",
      "expiration_date",
      "MM/YY"
    );
    this.defineField(
      form,
      "field-wrapper-security_code",
      "security_code",
      "CVC"
    );
    this.defineField(
      form,
      "field-wrapper-address_postal_code",
      "address.postal_code",
      "Zip/Postal"
    );
  };

  defineField = (form, elementId, type, placeholder) => {
    const f = form.field(type, {
      placeholder,

      // Required values for Finix Card Fields
      // Not mutatable due to lack of support from Finix
      styles: {
        placeholder: {
          fontFamily: "sans-serif",
          background: "transparent",
          fontSize: "25px",
        },
        default: {
          color: "black",
        },
        success: {
          color: "#5cb85c",
        },
        error: {
          color: "#d9534f",
        },
      },
    });
    document.getElementById(elementId).appendChild(f);
  };

  // Finix Error Checking
  checkIframeErrors = () => {
    const { iframeStates } = this.state;
    // // console.log(iframeStates)
    return (
      iframeStates.number.errorMessages.length === 0 &&
      iframeStates.expiration_date.errorMessages.length === 0 &&
      iframeStates.security_code.errorMessages.length === 0 &&
      iframeStates["address.postal_code"].errorMessages.length === 0
    );
  };

  //next (page 1 to page 2)
  handleNextOnMouseDown = () => {
    const { darkColor } = this.state;
    this.setState({
      nextButtonStyle: {
        top: "6px",
        backgroundColor: darkColor,
        inset: darkColor,
        marginTop: "6px",
      },
    });
  };

  handleNextOnMouseLeave = () => {
    const { lightColor } = this.state;
    this.setState({
      nextButtonStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
        marginTop: "6px",
      },
    });
  };

  //monthly
  handleFrequencyOnMouseDownMonthly = () => {
    const { darkColor } = this.state;
    this.setState({
      monthlyStyle: {
        top: "6px",
        backgroundColor: darkColor,
        inset: darkColor,
      },
    });
  };

  handleFrequencyOnMouseLeaveMonthly = () => {
    const { lightColor } = this.state;
    this.setState({
      monthlyStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
      },
    });
  };

  //onetime
  handleFrequencyOnMouseDownOneTime = () => {
    const { darkColor } = this.state;
    this.setState({
      oneTimeStyle: {
        top: "6px",
        backgroundColor: darkColor,
        inset: darkColor,
      },
    });
  };
  handleFrequencyOnMouseLeaveOneTime = () => {
    const { lightColor } = this.state;
    this.setState({
      oneTimeStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
      },
    });
  };

  //card
  handlePaymentStyleOnMouseDownBank = () => {
    const { darkColor } = this.state;
    this.setState({
      bankStyle: { top: "6px", backgroundColor: darkColor, inset: darkColor },
    });
  };

  handlePaymentStyleOnMouseLeaveBank = () => {
    const { lightColor } = this.state;
    this.setState({
      bankStyle: { top: "6px", backgroundColor: lightColor, inset: lightColor },
    });
  };

  //paypal
  handlePaymentStyleOnMouseDownPaypal = () => {
    const { darkColor } = this.state;
    this.setState({
      paypalStyle: { top: "6px", backgroundColor: darkColor, inset: darkColor },
    });
  };

  handlePaymentStyleOnMouseLeavePaypal = () => {
    const { lightColor } = this.state;
    this.setState({
      paypalStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
      },
    });
  };

  //bank
  handlePaymentStyleOnMouseDownCard = () => {
    const { darkColor } = this.state;
    this.setState({
      cardStyle: { backgroundColor: darkColor, inset: darkColor },
    });
  };
  handlePaymentStyleOnMouseLeaveCard = () => {
    const { lightColor } = this.state;
    this.setState({
      cardStyle: { backgroundColor: lightColor, inset: lightColor },
    });
  };

  //submit
  handleSubmitOnMouseDown = () => {
    const { darkColor } = this.state;
    this.setState({
      submitButtonStyle: {
        top: "6px",
        backgroundColor: darkColor,
        inset: darkColor,
      },
    });
  };

  handleSubmitOnMouseLeave = () => {
    const { lightColor } = this.state;
    this.setState({
      submitButtonStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
      },
    });
  };

  handleDonate = (event) => {
    const {
      lightColor,
      customTip,
      selectedTip,
      errorMessageInterval,
      paymentMethod,
      accountNumber,
      accountType,
      routingNumber,
      paypalAuthorizationCode,
      paypalAuthFailure,
      fees,
      defaultPaymentProcessor,
      accountHolderType,
    } = this.state;
    // event.preventDefault()
    clearInterval(errorMessageInterval);
    console.log("paymentMethod in handleDonate, ", paymentMethod);
    if (paymentMethod === "BANK") {
      if (
        accountNumber === "" ||
        routingNumber === "" ||
        accountType === "" ||
        (defaultPaymentProcessor === "STRIPE" && accountHolderType === "")
      ) {
        // // console.log("in 575", accountType)
        this.setState({
          errorMessage: "your banking information is incomplete",
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000
          ),
          submitButtonStyle: {
            top: "6px",
            backgroundColor: lightColor,
            inset: lightColor,
          },
        });
      } else if (routingNumber.length !== 9) {
        this.setState({
          errorMessage: "routing numbers must be 9 digits", // information is incomplete",
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000
          ),
          submitButtonStyle: {
            top: "6px",
            backgroundColor: lightColor,
            inset: lightColor,
          },
        });
      } else if (defaultPaymentProcessor === "STRIPE") {
        this.setState(
          {
            donationSubmitted: true,
            errorMessage: "",
          },
          this.callStripeACHPaymentFunction
        );
      }
      // Finix Bank Payment
      else {
        this.setState(
          {
            donationSubmitted: true,
            errorMessage: "",
          },
          this.processTransactionPayByBank
        );
      }
    } else if (customTip === "" && selectedTip === "Other") {
      this.setState({
        errorMessage: "please enter a valid tip amount",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
      });
    } else if (paymentMethod === "PAYPAL") {
      console.log("paypalAuthorizationCode", paypalAuthorizationCode);
      if (paypalAuthorizationCode === "") {
        this.setState({
          errorMessage: "your paypal account isn't connected",
          errorDisplayTime: 0,
          errorMessageInterval: setInterval(
            () =>
              this.setState({
                errorDisplayTime: this.state.errorDisplayTime + 1,
              }),
            1000
          ),
          submitButtonStyle: {
            top: "6px",
            backgroundColor: lightColor,
            inset: lightColor,
          },
        });
      }
      if (paypalAuthFailure === true) {
        console.log("in handle donate");
        this.setState(
          {
            donationSubmitted: false,
            errorMessage: "PayPal Connection Error",
          },
          () => this.paypalAuthFailureFunction("PayPal Connection Error")
        );
      }
      if (paypalAuthorizationCode !== "") {
        console.log(
          "paypal auth code before trying to charge: ",
          paypalAuthorizationCode
        );
        this.setState(
          {
            donationSubmitted: true,
            errorMessage: "",
          },
          () => this.processTransactionPayPal(paypalAuthorizationCode)
        );
      }
    } else if (
      paymentMethod === "CARD" &&
      defaultPaymentProcessor === "FINIX" &&
      !this.checkIframeErrors()
    ) {
      this.setState({
        errorMessage: "your card's information is incomplete",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
      });
    } else if (
      paymentMethod === "CARD" &&
      defaultPaymentProcessor === "STRIPE"
    ) {
      this.setState({
        donationSubmitted: true,
        errorMessage: "",
      });
      this.callStripeCardPaymentFunction();
    } else {
      this.setState(
        {
          donationSubmitted: true,
          errorMessage: "",
        },
        this.processTransaction
      );
    }
  };

  processTransactionPayPal = async (paypalAuthCode) => {
    const component = this;
    const timestamp = new Date().toISOString();

    const {
      amount,
      optIn,
      email,
      orgName,
      firstName,
      lastName,
      organizationNameField,
      showOrganizationNameField,
      uuid,
      total,
      fees,
      coverFees,
      setPage,
      merchantName,
      merchantID,
      phoneNumber,
      streetAddress,
      totalTip,
      streetAddress2,
      city,
      state,
      zip,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      accountType,
      lightColor,
      accountNumber,
      routingNumber,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      tickets,
      ticketMapping,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      paypalMerchantID,
      products,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;
    if (optIn) {
      optInMessage = signUpValue;
    }
    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );

    // send public_token to server
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        paypalAuthorizationCode: paypalAuthCode,
        //from previous paybybank fields
        publicToken: null,
        accountID: null,
        accountType: accountType,
        email: email,
        orgName: orgName,
        name: fullName,
        uuid: uuid,
        tip: 0,
        amount: amount,
        total: total,
        paypalMerchantID: paypalMerchantID,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: phoneNumber,
        address: streetAddress,
        address2: streetAddress2,
        city: city,
        region: state,
        zip: zip,
        country: selectedCountry,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        flag: "paypal",
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        lightColor: lightColor,
        funds: cart,
        cardID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timestamp: timestamp,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        //plaid-get-bank-info lambda use flag to determine what to do next
        //if flag says plaid, continue, else use routing and account number
      }),
    };
    let link1 =
      process.env.REACT_APP_PAYPAL_ORDER_API +
      "/create-order/" +
      paypalAuthCode +
      "/capture";
    console.log("hello from 737", link1);
    let response = await fetch(link1, requestOptions);
    let responseBody = await response.json();
    console.log("responseBody from Paypal", responseBody);
    if (!response.ok) {
      component.setState({
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            component.setState({
              errorDisplayTime: component.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
      });
    } else {
      let dtd_company_name = "";
      if (showDoubleTheDonation) {
        dtd_company_name = this.registerDonationForDoubleTheDonation();
      }

      // creating values to pass into confirmation page
      let addedFees = 0;
      if (coverFees) {
        addedFees = fees;
      }
      // let total = amount + totalTip + addedFees
      component.setState({ page: "CONFIRMATION", donationSubmitted: false });
      // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
    }
  };

  paypalAuthFailureFunction = async (responseBody) => {
    console.log("in paypal auth failure");
    const component = this;
    const timestamp = new Date().toISOString();
    const { optIn, firstName, lastName, lightColor, signUpValue } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;
    if (optIn) {
      optInMessage = signUpValue;
    }
    component.setState({
      errorMessage: responseBody,
      errorDisplayTime: 0,
      errorMessageInterval: setInterval(
        () =>
          component.setState({
            errorDisplayTime: component.state.errorDisplayTime + 1,
          }),
        3000
      ),
      donationSubmitted: false,
      submitButtonStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
      },
    });
  };

  processStripePayByBank = async (paymentMethod) => {
    const {
      finixSessionKey,
      optIn,
      orgName,
      email,
      firstName,
      lastName,
      showOrganizationNameField,
      organizationNameField,
      uuid,
      amount,
      fees,
      coverFees,
      setPage,
      merchantName,
      merchantID,
      phoneNumber,
      streetAddress,
      totalTip,
      streetAddress2,
      city,
      state,
      zip,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      accountType,
      lightColor,
      accountNumber,
      routingNumber,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      tickets,
      ticketMapping,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      products,
      stripeCustomerID,
      stripeAccountID,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;
    if (optIn) {
      optInMessage = signUpValue;
    }
    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );
    // send public_token to server
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        paymentProcessor: "STRIPE",
        stripeAccountID: stripeAccountID,
        stripeCustomerID: stripeCustomerID,
        paymentMethod: paymentMethod,
        publicToken: null,
        accountID: null,
        accountType: accountType,
        email: email,
        orgName: orgName,
        name: fullName,
        uuid: uuid,
        tip: 0,
        merchantID: merchantID,
        amount: amount,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: phoneNumber,
        address: streetAddress,
        address2: streetAddress2,
        city: city,
        region: state,
        zip: zip,
        country: selectedCountry,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        flag: "paybybank",
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        lightColor: lightColor,
        funds: cart,
        cardID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        //plaid-get-bank-info lambda use flag to determine what to do next
        //if flag says plaid, continue, else use routing and account number
      }),
    };
    // // console.log("hello from 737", requestOptions)
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/bank`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      this.setState({
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        loading: false,
        uuid: uuidv4(),
      });
    } else {
      let dtd_company_name = "";
      if (showDoubleTheDonation) {
        dtd_company_name = this.registerDonationForDoubleTheDonation();
      }

      // creating values to pass into confirmation page
      let addedFees = 0;
      if (coverFees) {
        addedFees = fees;
      }
      // let total = amount + totalTip + addedFees
      this.setState({
        page: "CONFIRMATION",
        donationSubmitted: false,
        loading: false,
      });
      // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
    }
  };

  processTransactionPayByBank = async () => {
    //first two available for both
    //2-4 is for
    // makePlaidPayment = async (public_token, accountID, accountType) => {
    const component = this;

    const {
      finixSessionKey,
      optIn,
      orgName,
      email,
      firstName,
      lastName,
      showOrganizationNameField,
      organizationNameField,
      uuid,
      amount,
      fees,
      coverFees,
      setPage,
      merchantName,
      merchantID,
      phoneNumber,
      streetAddress,
      totalTip,
      streetAddress2,
      city,
      state,
      zip,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      accountType,
      lightColor,
      accountNumber,
      routingNumber,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      tickets,
      ticketMapping,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      products,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;
    if (optIn) {
      optInMessage = signUpValue;
    }
    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );
    // send public_token to server
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        publicToken: null,
        accountID: null,
        accountType: accountType,
        email: email,
        orgName: orgName,
        name: fullName,
        uuid: uuid,
        tip: 0,
        merchantID: merchantID,
        amount: amount,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: phoneNumber,
        address: streetAddress,
        address2: streetAddress2,
        city: city,
        region: state,
        zip: zip,
        country: selectedCountry,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        flag: "paybybank",
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        lightColor: lightColor,
        funds: cart,
        cardID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        //plaid-get-bank-info lambda use flag to determine what to do next
        //if flag says plaid, continue, else use routing and account number
      }),
    };
    // // console.log("hello from 737", requestOptions)
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/bank`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      component.setState({
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            component.setState({
              errorDisplayTime: component.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        uuid: uuidv4(),
      });
    } else {
      let dtd_company_name = "";
      if (showDoubleTheDonation) {
        dtd_company_name = this.registerDonationForDoubleTheDonation();
      }

      // creating values to pass into confirmation page
      let addedFees = 0;
      if (coverFees) {
        addedFees = fees;
      }
      // let total = amount + totalTip + addedFees
      component.setState({ page: "CONFIRMATION", donationSubmitted: false });
      // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
    }
  };

  updateSchedulePaymentMapWithTimestamp = () => {
    // iterate through schedulePaymentMap and update the timestamp for each payment if timeValue exists
    const { schedulePaymentMap } = this.state;
    let updatedSchedulePaymentMap = {};
    for (let key in schedulePaymentMap) {
      let scheduledPayment = schedulePaymentMap[key];
      if (scheduledPayment.timeValue) {
        const timeValueHour = scheduledPayment.timeValue.hour();
        const timeValueMinute = scheduledPayment.timeValue.minute();
        if (scheduledPayment.startValue) {
          scheduledPayment.startValue = scheduledPayment.startValue
            .hour(timeValueHour)
            .minute(timeValueMinute)
            .second(0)
            .millisecond(0);
        } else {
          scheduledPayment.startValue = dayjs()
            .hour(timeValueHour)
            .minute(timeValueMinute)
            .second(0)
            .millisecond(0);
        }
        if (scheduledPayment.endValue) {
          scheduledPayment.endValue = scheduledPayment.endValue
            .hour(timeValueHour)
            .minute(timeValueMinute)
            .second(0)
            .millisecond(0);
        } else {
          // do nothing. It's INFINITY
        }
      }
      updatedSchedulePaymentMap[key] = scheduledPayment;
    }
    this.setState({ schedulePaymentMap: updatedSchedulePaymentMap });
    return updatedSchedulePaymentMap;
  };

  processStripeCard = async (paymentMethod) => {
    let {
      errorMessageInterval,
      finixSessionKey,
      lightColor,
      amount,
      fees,
      email,
      orgName,
      uuid,
      form,
      optIn,
      coverFees,
      setPage,
      firstName,
      lastName,
      showOrganizationNameField,
      organizationNameField,
      phoneNumber,
      streetAddress,
      streetAddress2,
      city,
      totalTip,
      state,
      zip,
      selectedCountry,
      merchantName,
      merchantID,
      statementDescriptor,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      ticketMapping,
      tickets,
      cart,
      cardID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      globalCustomInputs,
      products,
      stripeCustomerID,
      stripeAccountID,
      thing,
    } = this.state;

    clearInterval(errorMessageInterval);

    let optInMessage = "ONE_TIME";
    if (optIn) {
      optInMessage = signUpValue;
    }
    console.log("processing stripe card");
    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(globalCustomInputs);
    const requestOptions = {
      method: "POST",
      headers: { accept: "*/*", "content-type": "application/json" },
      body: JSON.stringify({
        paymentProcessor: "STRIPE",
        stripeAccountID: stripeAccountID,
        stripeCustomerID: stripeCustomerID,
        paymentMethod: paymentMethod,
        amount: amount,
        email: email,
        orgName: orgName,
        uuid: uuid,
        donationFrequency: optInMessage,
        coverFees: coverFees,
        merchantID: merchantID,
        merchantName: merchantName,
        name: firstName + " " + lastName,
        phone: phoneNumber,
        address: streetAddress,
        address2: streetAddress2,
        city: city,
        tip: 0,
        region: state,
        zip: zip,
        country: selectedCountry,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        formName: formName,
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        color: lightColor,
        funds: cart,
        cardID: cardID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        thing: thing,
      }),
    };

    // submitting transaction to finix-server-2 lambda
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/card`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      this.setState({
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        uuid: uuidv4(),
      });
    } else {
      let dtd_company_name = "";
      if (showDoubleTheDonation) {
        dtd_company_name = this.registerDonationForDoubleTheDonation();
      }
      // creating values to pass into confirmation page
      let addedFees = 0;
      if (coverFees) {
        addedFees = fees;
      }
      // let total = amount + totalTip + addedFees
      // console.log('hello confirmation')
      this.setState({
        page: "CONFIRMATION",
        donationSubmitted: false,
      });
      // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
    }
  };

  processTransaction = () => {
    const component = this;
    let {
      errorMessageInterval,
      finixSessionKey,
      lightColor,
      amount,
      fees,
      email,
      orgName,
      uuid,
      form,
      optIn,
      coverFees,
      setPage,
      firstName,
      lastName,
      showOrganizationNameField,
      organizationNameField,
      phoneNumber,
      streetAddress,
      streetAddress2,
      city,
      totalTip,
      state,
      zip,
      selectedCountry,
      merchantName,
      merchantID,
      statementDescriptor,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      ticketMapping,
      tickets,
      cart,
      cardID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      globalCustomInputs,
      products,
      thing,
    } = this.state;

    clearInterval(errorMessageInterval);
    try {
      form.submit(
        process.env.REACT_APP_FINIX_ENVIRONMENT,
        process.env.REACT_APP_FINIX_APPLICATION_ID,
        async function (err, res) {
          if (err) {
            alert("There was an error", err);
            component.setState({
              donationSubmitted: false,
              submitButtonStyle: {
                top: "6px",
                backgroundColor: lightColor,
                inset: lightColor,
              },
            });
            return;
          }

          const tokenID = res.data.id;

          let optInMessage = "ONE_TIME";
          if (optIn) {
            optInMessage = signUpValue;
          }
          // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
          let joinedCustomInputs = deepCopy(customInputsValues);
          joinedCustomInputs = joinedCustomInputs.concat(globalCustomInputs);
          const requestOptions = {
            method: "POST",
            headers: { accept: "*/*", "content-type": "application/json" },
            body: JSON.stringify({
              paymentProcessor: "FINIX",
              token: tokenID,
              amount: amount,
              email: email,
              orgName: orgName,
              uuid: uuid,
              donationFrequency: optInMessage,
              coverFees: coverFees,
              merchantID: merchantID,
              merchantName: merchantName,
              name: firstName + " " + lastName,
              phone: phoneNumber,
              address: streetAddress,
              address2: streetAddress2,
              city: city,
              tip: 0,
              region: state,
              zip: zip,
              country: selectedCountry,
              finixSessionKey: finixSessionKey,
              fund: fund,
              subFund: subFund,
              formName: formName,
              dateEndCharge: endValue,
              customInputs: joinedCustomInputs,
              elementID: elementID,
              crowdfundingOption: crowdfundingOption,
              anonymous: anonymous,
              tickets: tickets,
              ticketMapping: ticketMapping,
              color: lightColor,
              funds: cart,
              cardID: cardID,
              promoCode: confirmedPromoCode,
              salesforceCampaignID: salesforceCampaignID,
              schedulePayment:
                component.updateSchedulePaymentMapWithTimestamp(),
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              products: products,
              thing: thing,
            }),
          };

          // submitting transaction to finix-server-2 lambda
          let response = await fetch(
            `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/card`,
            requestOptions
          );
          let responseBody = await response.json();
          if (!response.ok) {
            component.setState({
              errorMessage: responseBody,
              errorDisplayTime: 0,
              errorMessageInterval: setInterval(
                () =>
                  component.setState({
                    errorDisplayTime: component.state.errorDisplayTime + 1,
                  }),
                1000
              ),
              donationSubmitted: false,
              submitButtonStyle: {
                top: "6px",
                backgroundColor: lightColor,
                inset: lightColor,
              },
              uuid: uuidv4(),
            });
          } else {
            let dtd_company_name = "";
            if (showDoubleTheDonation) {
              dtd_company_name =
                component.registerDonationForDoubleTheDonation();
            }
            // creating values to pass into confirmation page
            let addedFees = 0;
            if (coverFees) {
              addedFees = fees;
            }
            // let total = amount + totalTip + addedFees
            // console.log('hello confirmation')
            component.setState({
              page: "CONFIRMATION",
              donationSubmitted: false,
            });
            // component.setState({ donationCompleted: true }, setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />))
          }
        }
      );
    } catch (e) {
      alert("Something went wrong when submitting your payment\n" + e);
      component.setState({
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
      });
    }
  };

  handleZipChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z0-9]+/g, "");
    this.setState({
      zip: value,
    });
  };

  //autopick tip logic
  calculateWithTip = (inputAmount) => {
    const { showTips } = this.state;
    let calculatedTip = 0;
    let selectedTip = "";

    if (showTips === true) {
      if (inputAmount < 6.0) {
        selectedTip = "$1.00";
        calculatedTip = 1;
      } else if (inputAmount >= 6.0 && inputAmount <= 20.0) {
        selectedTip = "$3.00";
        calculatedTip = 3; //inputAmount * 0.12
      } else if (inputAmount < 50.0 && inputAmount >= 20.0) {
        selectedTip = "$5.00";
        calculatedTip = 5;
      } else {
        selectedTip = "10%";
        calculatedTip = inputAmount * 0.1;
      }
    }

    return { selectedTip, calculatedTip };
  };

  handleEmailChange = (event) => {
    this.setState({
      // remove space from email
      email: event.target.value.replace(/\s/g, ""),
    });
  };

  handleOrgNameChange = (event) => {
    this.setState({
      orgName: event.target.value,
    });
  };

  handlePromoChange = (event) => {
    this.setState({
      promoCode: event.target.value,
    });
  };

  handlefirstNameChange = (event) => {
    this.setState({
      firstName: event.target.value,
    });
  };

  handlelastNameChange = (event) => {
    this.setState({
      lastName: event.target.value,
    });
  };

  handleShowingOrganizationNameField = (event) => {
    event.preventDefault();
    console.log("handleShowingOrganizationNameField event: ", event);
    this.setState((prevState) => ({
      organizationNameField: !prevState.organizationNameField,
      showOrganizationNameField: !prevState.showOrganizationNameField,
      orgName: "",
    }));
  };

  handleAccountNumberChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^0-9.].*/g, "");

    this.setState({
      accountNumber: value,
    });
  };

  handleRoutingNumberChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^\d{5}].*/g, "");

    this.setState({
      routingNumber: value, //.target.value
    });
  };

  handlePhoneNumberChange = (event) => {
    let value = event.target.value;
    let phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    this.setState({
      phoneNumber: value,
    });
  };

  handleStreetAddressChange = (event) => {
    this.setState({
      streetAddress: event.target.value,
    });
  };

  handleStreetAddress2Change = (event) => {
    this.setState({
      streetAddress2: event.target.value,
    });
  };

  handleCityChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[0-9]/g, "");
    this.setState({
      city: value,
    });
  };

  handleStateChange = (event) => {
    let value = event.target.value;
    value = value.replace(/[^A-Za-z.].*/g, "");
    this.setState({
      state: value,
    });
  };

  handlePaymentTypeChange = (event) => {
    const {
      showFinixCardPaymentFields,
      showPayByBankPaymentFields,
      showPayByPaypalPaymentFields,
      paymentMethod,
      amount,
      fees,
      fixedFee,
      percentFee,
      totalTip,
      coverFees,
      paypalPlatformPercentFee,
      paypalPaymentProcessingFixedFee,
      paypalPaymentProcessingPercentFee,
      paypalThirdPartyProcessorFee,
      reloadPaypal,
      maxACHFee,
      defaultPaymentProcessor,
    } = this.state;
    let newValueForCard;
    let newValueForBank;
    let newValueForPaypal;
    let paymentMethodValue;
    //oneTime = card
    //monthly = bank
    let newFee = parseFloat(
      ((amount + fixedFee) / (1 - percentFee) - amount).toFixed(2)
    );
    console.log("new fee", newFee);
    console.log("fixedFee fee", fixedFee);
    console.log("percentFee fee", percentFee);
    console.log("maxACHFee", maxACHFee);
    console.log("paypal fixedFee fee", paypalPaymentProcessingFixedFee);
    console.log("paypal percentFee fee", paypalPaymentProcessingPercentFee);
    // 100 -> 104.2
    // 100 +.30 n
    let newPaypalPlatformPercentFee =
      paypalPlatformPercentFee +
      paypalThirdPartyProcessorFee +
      paypalPaymentProcessingPercentFee;
    console.log("newPaypalPlatformPercentFee", newPaypalPlatformPercentFee);
    let newPaypalFee = parseFloat(
      (
        (amount + paypalPaymentProcessingFixedFee) /
          (1 - newPaypalPlatformPercentFee) -
        amount
      ).toFixed(2)
    );
    let newBankFee = parseFloat(
      ((amount + fixedFee) / (1 - percentFee) - amount).toFixed(2)
    );
    // 0.049 should be 0.059
    // if (!coverFees) {
    //   newFee = parseFloat((amount * percentFee + fixedFee).toFixed(2));
    // }
    //capping the max fee for a pay by bank at 25
    let potentialPaypalReload = false;
    console.log("in the event on line 905", event);
    if (event.target.id === "card") {
      newValueForCard = true;
      newValueForBank = false;
      newValueForPaypal = false;
      paymentMethodValue = "CARD";
      potentialPaypalReload = true;
    }
    if (event.target.id === "bank") {
      newValueForBank = true;
      newValueForCard = false;
      newValueForPaypal = false;
      paymentMethodValue = "BANK";
      //recalculate fees for bank if switching from paypal
      newFee = parseFloat(
        ((amount + fixedFee) / (1 - percentFee) - amount).toFixed(2)
      );
      newFee = Math.max(
        newBankFee,
        defaultPaymentProcessor === "STRIPE" ? 2 : 1
      ); // minimum of 1
      newFee = Math.min(newFee, maxACHFee); // maximum
      potentialPaypalReload = true;
    }
    if (event.target.id === "paypal-container-charitystack") {
      newValueForBank = false;
      newValueForCard = false;
      newValueForPaypal = true;
      paymentMethodValue = "PAYPAL";
      //update paypal fee logic
      console.log("fees for paypal", fees, newPaypalFee);
      newFee = newPaypalFee;
    }

    let addedFees = 0;
    if (coverFees && amount > 0) {
      addedFees = newFee;
    }

    // const total = parseFloat((amount + totalTip + newFee).toFixed(2))
    const total = parseFloat((amount + addedFees).toFixed(2));

    this.setState({
      showFinixCardPaymentFields: newValueForCard,
      showPayByBankPaymentFields: newValueForBank,
      showPayByPaypalPaymentFields: newValueForPaypal,
      paymentMethod: paymentMethodValue,
      reloadPaypal: potentialPaypalReload,

      fees: newFee,
      total: total,
    });
  };

  handleAnonymousChange = (event) => {
    this.setState({
      anonymous: event.target.checked,
    });
  };

  calculateWalletTotal = () => {
    // this function should calculate fees the way we calculate them for card
    // it should return the total
    const { amount, coverFees, fixedFee, percentFee, scheduledWalletFees } =
      this.state;

    // handle scheduled donations case
    if (amount == 0) {
      let scheduledAmount = this.calculateScheduledDonationTotal();
      if (coverFees) {
        return parseFloat((scheduledAmount + scheduledWalletFees).toFixed(2));
      }
      return parseFloat(scheduledAmount.toFixed(2));
    }
    // calculate fees
    let walletFees = parseFloat(
      ((amount + fixedFee) / (1 - percentFee) - amount).toFixed(2)
    );
    // calculate total
    let addedFees = 0;
    if (coverFees) {
      addedFees = walletFees;
    }
    return parseFloat((amount + addedFees).toFixed(2));
  };

  handleFeeOptinChange = (event) => {
    const {
      amount,
      totalTip,
      fees,
      percentFee,
      fixedFee,
      showPayByBankPaymentFields,
      showPayByPaypalPaymentFields,
      paypalPlatformPercentFee,
      paypalThirdPartyProcessorFee,
      paypalPaymentProcessingFixedFee,
      paypalPaymentProcessingPercentFee,
      maxACHFee,
      defaultPaymentProcessor,
    } = this.state;
    // // console.log('thing', event)
    // // console.log('checked', event.target.checked)
    // // console.log('value', event.target.value)
    // // console.log('type', event.target.type)
    const target = event.target;
    const optIn = target.type === "checkbox" ? target.checked : target.value;
    // // console.log('optin', optIn)
    // // // console.log("the value of target.type is ")
    // // // console.log(optIn)
    let showBlurb = false;
    if (event.target.checked) {
      showBlurb = false;
    } else {
      showBlurb = true;
    }

    // recalculate fees using recursive formula
    let newFees = ((amount + fixedFee) / (1 - percentFee) - amount).toFixed(2);
    // Calculating Fees with base formula if fees are not covered
    if (!optIn) {
      newFees = (amount * percentFee + fixedFee).toFixed(2);
    }

    // if showPayByBankPaymentFields is true, then take the minimum of 25 and the fee amount
    if (showPayByBankPaymentFields === true) {
      newFees = Math.max(newFees, defaultPaymentProcessor === "STRIPE" ? 2 : 1); // minimum of 1
      newFees = Math.min(newFees, maxACHFee); // maximum of 25
    }

    if (showPayByPaypalPaymentFields === true) {
      console.log("paypal fixedFee fee", paypalPaymentProcessingFixedFee);
      console.log("paypal percentFee fee", paypalPaymentProcessingPercentFee);
      console.log("paypal third party fee", paypalThirdPartyProcessorFee);
      console.log("paypal platform fee", paypalPlatformPercentFee);
      console.log("amount", amount);
      // 100 -> 104.2
      // 100 +.30 n
      let newPaypalPlatformPercentFee =
        paypalPaymentProcessingPercentFee +
        paypalPlatformPercentFee +
        paypalThirdPartyProcessorFee;
      let newPaypalFee = parseFloat(
        (
          (amount + paypalPaymentProcessingFixedFee) /
            (1 - newPaypalPlatformPercentFee) -
          amount
        ).toFixed(2)
      );
      newFees = newPaypalFee; // minimum of 1
      //   newFees = Math.min(newFees, 25); // maximum of 25
    }

    // Calculating Total
    let addedFees = 0;
    if (optIn && amount > 0) {
      addedFees = parseFloat(newFees);
    }

    // const total = parseFloat((amount + totalTip + addedFees).toFixed(2))
    const total = parseFloat((amount + addedFees).toFixed(2));

    console.log("total", total);

    this.setState({
      coverFees: optIn,
      coverFeesText: showBlurb,
      total: total,
      fees: parseFloat(newFees),
    });
  };

  triggerButtonAnimation = (buttonId) => {
    const button = document.getElementById(buttonId);
    button.classList.add("scale-110");
    setTimeout(() => {
      button.classList.remove("scale-110");
    }, 100);
  };

  goToPayment = (type) => {
    const {
      amount,
      email,
      firstName,
      lastName,
      iframeStates,
      buttonPressColor,
      lightColor,
      selectedCountry,
      fund,
      phoneNumber,
      showPhone,
      streetAddress,
      streetAddress2,
      city,
      state,
      selectedTip,
      customTip,
      zip,
      maxTransactionAmount,
      errorMessageInterval,
      showAddress,
      endValue,
      showDatePickerInput,
      optIn,
      selectedFrequency,
      allFrequenciesValues,
      showCustomInputsPage,
      paypalAuthorizationCode,
      showOrganizationNameField,
      organizationNameField,
      orgName,
      defaultPaymentProcessor,
    } = this.state;
    //console.log("testing", showOrganizationNameField, organizationNameField, orgName)

    this.setState({
      nextButtonStyle: {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
        marginTop: "6px",
      },
    });
    console.log(
      "in go to payment. paypal auth value: ",
      paypalAuthorizationCode
    );

    //console.log("testing", showOrganizationNameField, organizationNameField, orgName)

    clearInterval(errorMessageInterval);
    if (
      isNaN(amount) ||
      (amount === 0 && this.calculateScheduledDonationTotal() === 0)
    ) {
      this.setState({
        errorMessage: "please enter an amount greater than $0",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (amount < 1 && this.calculateScheduledDonationTotal() === 0) {
      this.setState({
        errorMessage: "please enter an amount greater than $1",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (
      showOrganizationNameField === false &&
      organizationNameField === true &&
      orgName === ""
    ) {
      this.setState({
        errorMessage: "please enter a organization name",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          paddingTop: "0px",
        },
      });
      return false;
    } else if (selectedCountry === null) {
      this.setState({
        errorMessage: "please select a country",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (
      (streetAddress === null ||
        streetAddress === "" ||
        city === null ||
        city === "" ||
        zip === null ||
        zip === "" ||
        selectedCountry === "" ||
        selectedCountry === null) &&
      showAddress === true
    ) {
      this.setState({
        errorMessage: "please fill out all address fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (
      (streetAddress === null ||
        streetAddress === "" ||
        city === null ||
        city === "" ||
        zip === null ||
        zip === "" ||
        state === "" ||
        state === null) &&
      showAddress === true &&
      selectedCountry === "United States"
    ) {
      this.setState({
        errorMessage: "please fill out all address fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (fund === "Please Select a Fund" || fund === null) {
      this.setState({
        errorMessage: "please select a fund",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (amount > maxTransactionAmount) {
      this.setState({
        errorMessage: "max amount is $" + maxTransactionAmount,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } /* else if (showAddress === true && city.match(/\D+$/) === null) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].cityText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          paddingTop: "0px",
        },
      });
      return false;
    } else if (
      showAddress === true &&
      state.match(/\D+$/) === null &&
      countriesMap[selectedCountry].regionText !== ""
    ) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].regionText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (showAddress === true && zip.match(/[0-9]*/ /*) === null) {
      this.setState({
        errorMessage: `please enter a valid ${countriesMap[selectedCountry].postalCodeText}`,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } */ else if (firstName === "" || lastName === "") {
      this.setState({
        errorMessage: "please enter your full name",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        nextButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
          marginTop: "6px",
        },
      });
      return false;
    } else if (
      firstName.match(/\D+$/) === null ||
      lastName.match(/\D+$/) === null
    ) {
      this.setState({
        errorMessage: "letters only for name fields",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
      });
      return false;
    } else if (
      showPhone === true &&
      (phoneNumber === "" || phoneNumber === null || phoneNumber.length < 10)
    ) {
      this.setState({
        errorMessage: "please enter a valid phone number",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
      });
      return false;
    } else if (email.indexOf("@") === -1 || email.indexOf(".") === -1) {
      this.setState({
        errorMessage: "please enter a valid email",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
      });
      return false;
    } else if (
      showDatePickerInput === true &&
      optIn &&
      (endValue === null || endValue === undefined)
    ) {
      this.setState({
        errorMessage: "please enter an end date",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
      });
      return false;
    } else if (selectedFrequency === "" && optIn) {
      this.setState({
        errorMessage: "please select a donation frequency",
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
      });
      return false;
    } else if (this.schedulePaymentShowComponent() === true) {
      console.log("please apply changes to schedule payment");
      return false;
    } else if (amount > 0.99 || this.calculateScheduledDonationTotal() > 0.99) {
      this.setState(
        {
          errorMessage: "",
          // page: 'CONFIRMATION',
          // nextButtonStyle: { top: "6px", 'backgroundColor': lightColor, 'inset': lightColor, marginTop: '0px' }
        },
        () => {
          if (
            (type !== "PLAID" && defaultPaymentProcessor === "FINIX") ||
            (type !== "FINANCIAL_CONNECTIONS" &&
              defaultPaymentProcessor === "STRIPE")
          ) {
            console.log("Entering handleDonate");
            this.handleDonate();
          }
        }
      );
      return true;
    }
  };

  goToPaymentFromCustomInputs = () => {
    this.setState({
      page: "PAYMENT_INFO",
      errorMessage: "",
    });
  };

  // goBack = () => {
  //   const { errorMessageInterval, page, showCustomInputsPage } = this.state;
  //   let prevPage = "";
  //   if (page === "CUSTOM_INPUTS_PAGE") {
  //     prevPage = "DONOR_INFO";
  //   }
  //   if (page === "PAYMENT_INFO" && showCustomInputsPage === true) {
  //     prevPage = "CUSTOM_INPUTS_PAGE";
  //   }
  //   if (page === "PAYMENT_INFO" && showCustomInputsPage === false) {
  //     prevPage = "DONOR_INFO";
  //   }

  //   clearInterval(errorMessageInterval);
  //   this.setState({
  //     page: prevPage,
  //     errorMessage: "",
  //   });
  // };

  setLoad = (isLoading) => {
    this.setState({
      loading: isLoading,
    });
  };

  makePlaidPayment = async (
    flag,
    accountType,
    public_token,
    accountID,
    routingNumber,
    accountNumber
  ) => {
    //first two available for both
    //2-4 is for
    // makePlaidPayment = async (public_token, accountID, accountType) => {
    const {
      finixSessionKey,
      optIn,
      email,
      orgName,
      firstName,
      lastName,
      uuid,
      lightColor,
      amount,
      fees,
      coverFees,
      setPage,
      merchantName,
      merchantID,
      phoneNumber,
      streetAddress,
      totalTip,
      streetAddress2,
      city,
      state,
      zip,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      ticketMapping,
      tickets,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      products,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;

    if (optIn) {
      optInMessage = signUpValue;
    }
    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );
    // send public_token to server
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        publicToken: public_token,
        accountID: accountID,
        accountType: accountType,
        email: email,
        orgName: orgName,
        name: fullName,
        uuid: uuid,
        tip: 0,
        merchantID: merchantID,
        amount: amount,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: phoneNumber,
        address: streetAddress,
        address2: streetAddress2,
        city: city,
        region: state,
        zip: zip,
        country: selectedCountry,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        flag: "plaid",
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        color: lightColor,
        funds: cart,
        cartID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        //plaid-get-bank-info lambda use flag to determine what to do next
        //if flag says plaid, continue, else use routing and account number
      }),
    };
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/bank`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      this.setState({
        loading: false,
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        uuid: uuidv4(),
      });
      return; // break out of function
    }

    let dtd_company_name = "";
    if (showDoubleTheDonation) {
      dtd_company_name = this.registerDonationForDoubleTheDonation();
    }

    let addedFees = 0;
    if (coverFees) {
      addedFees = fees;
    }
    // const total = amount + totalTip + addedFees
    this.setState({
      page: "CONFIRMATION",
      donationSubmitted: false,
      loading: false,
    });
    // setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />)
  };

  makeGooglePayPayment = async (paymentRequest) => {
    //    // // console.log('load payment data', paymentRequest);

    const {
      finixSessionKey,
      optIn,
      email,
      orgName,
      lightColor,
      firstName,
      lastName,
      uuid,
      amount,
      fees,
      coverFees,
      setPage,
      merchantName,
      merchantID,
      totalTip,
      merchantIdentity,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      tickets,
      ticketMapping,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      products,
      countries,
      defaultPaymentProcessor,
      stripeCustomerID,
      stripeAccountID,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;

    if (optIn) {
      optInMessage = signUpValue;
    }

    let gpayEmail = "";
    let billingContact = {};
    let gpayPhone = "";
    let gPayAddress = "";
    let gPayAddress2 = "";
    let gPayCity = "";
    let gPayRegion = "";
    let gPayZip = "";
    let countryName = "";
    let names = [];

    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );

    if (defaultPaymentProcessor === "FINIX") {
      gpayEmail = paymentRequest["email"];
      billingContact = paymentRequest.paymentMethodData.info.billingAddress; // Changed from shippingContact to billingContact

      // check if phone number has any country code at front. if so, remove it
      gpayPhone = billingContact["phoneNumber"];
      if (billingContact["phoneNumber"].charAt(0) === "+") {
        // split by space and grab last element
        const splitPhone = billingContact["phoneNumber"].split(" ");
        gpayPhone = splitPhone[splitPhone.length - 1];
      }

      names = billingContact["name"].split(" "); // Changed from shippingContact to billingContact

      const countryData = CountryList().getData(); // Getting the country list

      // Retrieve the country name using the country code
      countryName =
        countryData.find(
          (country) => country.value === billingContact["countryCode"]
        )?.label || "Unknown Country";

      // Log for debugging
      console.log("billingContact Value:", billingContact);
      console.log(
        "billingContact[countryCode]:",
        billingContact["countryCode"]
      );
      console.log("Mapped Country Name:", countryName);

      gPayAddress = billingContact["address1"];
      gPayAddress2 = billingContact["address2"];
      gPayCity = billingContact["locality"];
      gPayRegion = billingContact["administrativeArea"];
      gPayZip = billingContact["postalCode"];
    } else if (defaultPaymentProcessor === "STRIPE") {
      billingContact = paymentRequest.billing_details;
      gpayEmail = billingContact.email;
      gpayPhone = billingContact.phone;
      if (gpayPhone.charAt(0) === "+") {
        // split by space and grab last element
        const splitPhone = gpayPhone.split(" ");
        gpayPhone = splitPhone[splitPhone.length - 1];
      }

      names = billingContact.name.split(" ");

      const countryData = CountryList().getData(); // Getting the country list

      // Retrieve the country name using the country code
      countryName =
        countryData.find(
          (country) => country.value === billingContact["address"]["country"]
        )?.label || "Unknown Country";

      // Log for debugging
      console.log("billingContact Value:", billingContact);
      console.log(
        "billingContact[countryCode]:",
        billingContact["address"]["country"]
      );
      console.log("Mapped Country Name:", countryName);

      gPayAddress = billingContact["address"]["line1"];
      gPayAddress2 = billingContact["address"]["line2"];
      gPayCity = billingContact["address"]["city"];
      gPayRegion = billingContact["address"]["state"];
      gPayZip = billingContact["address"]["postal_code"];
    }

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        paymentProcessor: defaultPaymentProcessor,
        paymentMethod: paymentRequest,
        stripeCustomerID: stripeCustomerID,
        stripeAccountID: stripeAccountID,
        networkToken:
          defaultPaymentProcessor === "FINIX"
            ? paymentRequest.paymentMethodData.tokenizationData.token
            : null,
        email: gpayEmail,
        name: names[0] + " " + names[names.length - 1],
        uuid: uuid,
        orgName: orgName,
        merchantID: merchantID,
        merchantIdentity: merchantIdentity,
        amount: amount,
        tip: 0,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: gpayPhone,
        address: gPayAddress, // Changed from shippingContact to billingContact
        address2: gPayAddress2, // Changed from shippingContact to billingContact
        city: gPayCity, // Changed from shippingContact to billingContact
        region: gPayRegion, // Changed from shippingContact to billingContact
        zip: gPayZip, // Changed from shippingContact to billingContact
        country: countryName,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        lightColor: lightColor,
        funds: cart,
        cartID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
      }),
    };
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/google-pay/submit`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      this.setState({
        loading: false,
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        uuid: uuidv4(),
      });
      return; // break out of function
    }
    let dtd_company_name = "";
    if (showDoubleTheDonation) {
      // set states for name, address, email, etc
      await this.updateStateAsync({
        firstName: names[0],
        lastName: names[names.length - 1],
        email: gpayEmail,
        phone: gpayPhone,
        address: gPayAddress, // Changed from shippingContact to billingContact
        address2: gPayAddress2, // Changed from shippingContact to billingContact
        city: gPayCity, // Changed from shippingContact to billingContact
        state: gPayRegion, // Changed from shippingContact to billingContact
        zip: gPayZip, // Changed from shippingContact to billingContact
        country: countryName, // Changed from shippingContact to billingContact
      });
      dtd_company_name = this.registerDonationForDoubleTheDonation();
    }

    let addedFees = 0;
    if (coverFees) {
      addedFees = fees;
    }
    // const total = amount + totalTip + addedFees

    this.setState({ email: gpayEmail }, () => {
      // This function will be executed after the email state is updated
      this.setState({
        page: "CONFIRMATION",
        donationSubmitted: false,
        loading: false,
      });
    });
    // setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />)
  };

  updateStateAsync = (stateUpdate) => {
    return new Promise((resolve) => {
      this.setState(stateUpdate, () => {
        resolve();
      });
    });
  };

  makeApplePayPayment = async (networkToken) => {
    const {
      finixSessionKey,
      optIn,
      email,
      firstName,
      lastName,
      uuid,
      amount,
      lightColor,
      fees,
      coverFees,
      setPage,
      merchantName,
      totalTip,
      merchantID,
      merchantIdentity,
      phoneNumber,
      streetAddress,
      streetAddress2,
      city,
      state,
      zip,
      statementDescriptor,
      selectedCountry,
      fund,
      subFund,
      formName,
      endValue,
      showDoubleTheDonation,
      signUpValue,
      customInputsValues,
      elementID,
      crowdfundingOption,
      anonymous,
      ticketMapping,
      tickets,
      cart,
      cartID,
      confirmedPromoCode,
      salesforceCampaignID,
      schedulePaymentMap,
      products,
      orgName,
      defaultPaymentProcessor,
      stripeCustomerID,
      stripeAccountID,
    } = this.state;
    let optInMessage = "ONE_TIME";
    const fullName = firstName + " " + lastName;

    if (optIn) {
      optInMessage = signUpValue;
    }

    let applePayEmail = "";
    let billingContact = {};
    let applePayPhone = "";
    let applePayAddress = "";
    let applePayAddress2 = "";
    let applePayCity = "";
    let applePayRegion = "";
    let applePayZip = "";
    let applePayName = "";
    let countryName = "";
    let names = [];

    // console.log("applePay networkToken", JSON.parse(networkToken));

    if (defaultPaymentProcessor === "FINIX") {
      // grab shipping contact from apple pay
      const billingContact = JSON.parse(networkToken).billingContact;
      const shippingContact = JSON.parse(networkToken).shippingContact;

      applePayEmail = shippingContact["emailAddress"];
      applePayName =
        billingContact["givenName"] + " " + billingContact["familyName"];
      applePayPhone = shippingContact["phoneNumber"];
      applePayAddress = billingContact["addressLines"][0];
      applePayAddress2 = billingContact["addressLines"][1]
        ? billingContact["addressLines"][1]
        : "";
      applePayCity = billingContact["locality"];
      applePayRegion = billingContact["administrativeArea"];
      applePayZip = billingContact["postalCode"];
      countryName = billingContact["country"];
    }

    if (defaultPaymentProcessor === "STRIPE") {
      const billingContact = networkToken.billing_details;

      applePayEmail = billingContact.email;
      applePayName = billingContact.name;
      applePayPhone = billingContact.phone;

      applePayAddress = billingContact.address.line1;
      applePayAddress2 = billingContact.address.line2;
      applePayCity = billingContact.address.city;
      applePayRegion = billingContact.address.state;
      applePayZip = billingContact.address.postal_code;
      countryName = billingContact.address.country;

      const countryData = CountryList().getData(); // Getting the country list

      // Retrieve the country name using the country code
      countryName =
        countryData.find((country) => country.value === countryName)?.label ||
        "Unknown Country";
    }

    // create joinedCustomInputs which combines globalCustomInputs and customInputsValues
    let joinedCustomInputs = deepCopy(customInputsValues);
    joinedCustomInputs = joinedCustomInputs.concat(
      this.state.globalCustomInputs
    );
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        paymentProcessor: defaultPaymentProcessor,
        paymentMethod: networkToken, // for Stripe this is payment method
        stripeCustomerID: stripeCustomerID,
        stripeAccountID: stripeAccountID,
        networkToken: defaultPaymentProcessor === "FINIX" ? networkToken : null,
        networkToken: networkToken,
        email: applePayEmail,
        name: applePayName,
        uuid: uuid,
        merchantID: merchantID,
        merchantIdentity: merchantIdentity,
        amount: amount,
        tip: 0,
        donationFrequency: optInMessage,
        merchantName: merchantName,
        phone: applePayPhone,
        address: applePayAddress,
        address2: applePayAddress2,
        city: applePayCity,
        region: applePayRegion,
        zip: applePayZip,
        country: countryName,
        finixSessionKey: finixSessionKey,
        fund: fund,
        subFund: subFund,
        coverFees: coverFees,
        formName: formName,
        dateEndCharge: endValue,
        customInputs: joinedCustomInputs,
        elementID: elementID,
        crowdfundingOption: crowdfundingOption,
        anonymous: anonymous,
        tickets: tickets,
        ticketMapping: ticketMapping,
        lightColor: lightColor,
        funds: cart,
        cartID: cartID,
        promoCode: confirmedPromoCode,
        salesforceCampaignID: salesforceCampaignID,
        schedulePayment: this.updateSchedulePaymentMapWithTimestamp(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        products: products,
        orgName: orgName,
      }),
    };
    let response = await fetch(
      `${process.env.REACT_APP_TRANSACTIONS_URL}/checkout/apple-pay/submit`,
      requestOptions
    );
    let responseBody = await response.json();
    if (!response.ok) {
      this.setState({
        loading: false,
        errorMessage: responseBody,
        errorDisplayTime: 0,
        errorMessageInterval: setInterval(
          () =>
            this.setState({
              errorDisplayTime: this.state.errorDisplayTime + 1,
            }),
          1000
        ),
        donationSubmitted: false,
        submitButtonStyle: {
          top: "6px",
          backgroundColor: lightColor,
          inset: lightColor,
        },
        uuid: uuidv4(),
      });
      return; // break out of function
    }

    let dtd_company_name = "";
    if (showDoubleTheDonation) {
      await this.updateStateAsync({
        firstName:
          applePayName.split(" ").length >= 1 ? applePayName.split(" ")[0] : "",
        lastName:
          applePayName.split(" ").length > 1 ? applePayName.split(" ")[1] : "",
        email: applePayEmail,
        phone: applePayPhone,
        address: applePayAddress,
        address2: applePayAddress2,
        city: applePayCity,
        state: applePayRegion,
        zip: applePayZip,
        country: countryName,
      });
      dtd_company_name = this.registerDonationForDoubleTheDonation();
    }

    let addedFees = 0;
    if (coverFees) {
      addedFees = fees;
    }
    // const total = amount + totalTip + addedFees
    this.setState({ email: applePayEmail }, () => {
      // This function will be executed after the email state is updated
      this.setState({
        page: "CONFIRMATION",
        donationSubmitted: false,
        loading: false,
      });
    });
    // setPage(<Confirmation setPage={setPage} amountDonated={total} statementDescriptor={statementDescriptor} companyName={dtd_company_name} />)
  };

  setShowDatePickerInput = (value) => {
    this.setState({ showDatePickerInput: value, endValue: undefined });
  };

  //Paypal Inputs
  setPaypalInputs = (
    emailFromPaypal,
    firstNameFromPaypal,
    lastNameFromPaypal
  ) => {
    this.setState({
      email: emailFromPaypal,
      firstName: firstNameFromPaypal,
      lastName: lastNameFromPaypal,
    });
  };
  setPaypalAuthorizationCode = (value) => {
    console.log("Setting Paypal Auth Code: ", value);
    this.setState({
      paypalAuthorizationCode: value,
      paypalAccountConnected: true,
    });
  };
  grabOneTimeOrFirstRecurringFrequency = (arr) => {
    if (arr.includes("One-Time")) {
      return "One-Time";
    } else {
      return arr.find((el) => el !== "One-Time");
    }
  };

  setMyPage = (page) => {
    this.setState({ page: page });
  };

  handleCartItem = (key, productID, action) => {
    const {
      cart,
      cartID,
      fixedFee,
      percentFee,
      errorMessageInterval,
      coverFees,
      schedulePaymentMap,
      originalAmount,
      products,
    } = this.state;

    console.log("HELLO", key, action, cart);

    let newCart = [...cart];
    let itemIndex = newCart.findIndex((item) => item.cartItemKey === key);

    if (action === "REMOVE") {
      newCart = newCart.filter((item) => item.cartItemKey !== key);
    } else if (action === "ADD" && itemIndex !== -1) {
      newCart[itemIndex].quantity += 1;
    } else if (action === "SUBTRACT" && itemIndex !== -1) {
      newCart[itemIndex].quantity = Math.max(
        0,
        newCart[itemIndex].quantity - 1
      );
    }

    let newAmount = newCart.reduce(
      (acc, item) => acc + parseFloat(item.amount) * item.quantity,
      0
    );

    const newScheduledPaymentMap = { ...schedulePaymentMap };
    if (action === "REMOVE") {
      delete newScheduledPaymentMap[key];
    }

    let newProducts = products;
    if (products.length > 0) {
      newProducts = products.filter((product) =>
        newCart.some(
          (item) =>
            item.productID === product.productID &&
            item.productGroupName === product.productGroupName &&
            item.productGroupDescription === product.productGroupDescription &&
            item.productName === product.productName &&
            item.productPrice === product.productPrice
        )
      );
    }

    // find matching product in newProducts
    // it may be removed here already if the quantity is 0
    const productIndex = newProducts.findIndex(
      (product) => product.productID === productID
    );
    if (productIndex !== -1) {
      if (action === "ADD") {
        newProducts[productIndex].quantity += 1;
      }
      if (action === "SUBTRACT") {
        newProducts[productIndex].quantity = Math.max(
          0,
          newProducts[productIndex].quantity - 1
        );
      }
    }

    this.setState(
      {
        cart: newCart,
        amount: newAmount,
        originalAmount: newAmount,
        products: newProducts,
      },
      () => {
        this.setState({ schedulePaymentMap: newScheduledPaymentMap });
        if (newCart.length === 0) {
          // exit checkout form if empty cart
          this.exitCheckoutForm(false, "EXIT");
        }
      }
    );
    // send message to parent to update cart in local storage
    if (action === "REMOVE") {
      window.top.postMessage(
        {
          action: "REMOVE_CART_ITEM",
          entity: "CHARITYSTACK",
          sender: "CHECKOUT_FORM",
          receiver: "HOSTED_SCRIPT",
          id: cartID,
          cartItemKey: key,
        },
        "*"
      );
    } else if (action === "ADD" || action === "SUBTRACT") {
      console.log("posting UPDATE_CART_QUANTITY");
      window.top.postMessage(
        {
          action: "UPDATE_CART_QUANTITY",
          entity: "CHARITYSTACK",
          sender: "CHECKOUT_FORM",
          receiver: "HOSTED_SCRIPT",
          id: cartID,
          data: JSON.stringify({
            cartItemKey: key,
            productID: productID,
            quantity: newCart[itemIndex].quantity,
          }),
        },
        "*"
      );
    }
  };

  recurringDonationInCart = () => {
    const { cart } = this.state;
    let recurring = false;
    cart.forEach((item) => {
      if (item.frequency !== "ONE_TIME") {
        recurring = true;
      }
    });
    return recurring;
  };

  calculateScheduledDonationTotal = (passedInSchedulePaymentMap) => {
    // iterate through schedulePaymentMap and calculate total
    // only include items that have a startValue
    const { schedulePaymentMap } = this.state;
    let total = 0;

    let referencedSchedulePaymentMap = schedulePaymentMap;

    // reference passed in schedulePaymentMap if it exists
    if (passedInSchedulePaymentMap) {
      referencedSchedulePaymentMap = passedInSchedulePaymentMap;
    }

    for (const [key, value] of Object.entries(referencedSchedulePaymentMap)) {
      if (value.startValue || value.timeValue) {
        total += value.amount * value.quantity;
      }
    }
    return total;
  };

  handleMapboxError = (error) => {
    console.error("Mapbox Autofill Error:", error);
    this.setState({ mapBoxAccess: false });
  };

  render() {
    const {
      submitButtonStyle,
      darkColor,
      nextButtonStyle,
      categoriesButtonStyle,
      accountTypeButtonStyle,
      buttonPressColor,
      lightColor,
      selectedAmount,
      defaultAmounts,
      zip,
      city,
      state,
      streetAddress,
      streetAddress2,
      frequencyButtonStyle,
      setPage,
      firstName,
      lastName,
      uuid,
      interval,
      loadTime,
      loading,
      cardInputs,
      page,
      funds,
      coverFees,
      fees,
      amount,
      errorMessage,
      creditCardError,
      donationSubmitted,
      donationCompleted,
      amountDisplay,
      monthly,
      setEndValue,
      showDatePickerInput,
      setShowDatePickerInput,
      customAmount,
      timeLeft,
      email,
      orgName,
      phoneNumber,
      showPhone,
      optIn,
      merchantIdentity,
      customTip,
      selectedTip,
      totalTip,
      country,
      frequenciesValue,
      showFinixCardPaymentFields,
      showPayByBankPaymentFields,
      showPayByPaypalPaymentFields,
      selectedCountry,
      countryButtonStyle,
      fund,
      subFund,
      coverFeesText,
      showCoverFees,
      showOrganizationNameField,
      organizationNameField,
      showTips,
      showCountries,
      showAddress,
      merchantName,
      showForm,
      total,
      showApplePay,
      paymentMethod,
      allFrequenciesValues,
      selectedFrequency,
      setStartValue,
      signUpValue,
      customInputsValues,
      accountNumber,
      accountType,
      routingNumber,
      randomEmoji,
      showScheduleDonation,
      showPromos,
      togglePromos,
      promoMessage,
      totalDiscount,
      promoCode,
      showDoubleTheDonation,
      dtd_company_name,
      statementDescriptor,
      hostedPage,
      tickets,
      ticketMapping,
      defaultFrequency,
      donationFlagPopup,
      cart,
      schedulePaymentMap,
      formName,
      originalAmount,
      isChromium,
      approvedForPaypalPaymentProcessing,
      paypalMerchantID,
      paypalAuthorizationCode,
      paypalAuthFailure,
      paypalAccountConnected,
      reloadPaypal,
      scheduledFees,
      products,
      stripeStatementDescriptor,
      stripeAccountID,
      defaultPaymentProcessor,
      countrySettings,
      countries,
      mapBoxAccess,
      stripeWalletAvailable,
      stripeClientSecret,
      stripeCustomerID,
    } = this.state;

    let freeTicket = true;
    if (tickets === null || tickets === undefined || tickets.length === 0) {
      freeTicket = false;
    }
    if (ticketMapping !== null && ticketMapping !== undefined) {
      for (let i = 0; i < ticketMapping.length; i++) {
        if (ticketMapping[i] !== null) {
          if (
            ticketMapping[i].numSold > 0 &&
            ticketMapping[i].soldTotalPrice > 0
          ) {
            freeTicket = false;
          }
        }
      }
    }
    const ticketsLength =
      tickets === null || tickets === undefined ? 0 : tickets.length;
    if (amount === 0 && ticketsLength > 0) {
      freeTicket = true;
    }

    let submit;
    let nextButton;
    let phone1 = null;
    let feeDisplay = "($" + fees.toString() + ")";
    let payByBankVisibility = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };
    let customTipVisability = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };
    let addressVisability = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };
    // let submitButtonStyle = {top: "88px"}
    let lineBreaks = (
      <>
        <br />
        <br />
      </>
    );
    let lineBreakTop = <br />;
    let tempTip = 0;
    let cityText = "";
    let regionText = "";
    let postalCodeText = "";
    let countryVisibility = { display: "none" };
    let orgNameVisibility = { display: "none" };
    let donationInformation = "Donation Information";
    let showFinixCardPaymentFieldsVisability = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };
    let showPaypalPaymentFieldsVisability = {
      display: "none",
      justifyContent: "center",
    };

    let totalDiscountedAmt = 0;
    let showPayByBankPaymentFieldsVisability = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };
    let showPayByBankPaymentFieldsVisabilityPlaid = {
      width: "100%",
      display: "none",
      justifyContent: "center",
    };

    let reoccuringFrequenciesValueVisability = { display: "none" };
    let recurringCategories = allFrequenciesValues;
    let recurringFrequencies = allFrequenciesValues.filter(
      (el) => el !== "One-Time"
    );
    recurringFrequencies.sort((a, b) => {
      const weightA = RECURRING_FREQUENCY_ORDER_MAP[a];
      const weightB = RECURRING_FREQUENCY_ORDER_MAP[b];
      if (weightA === weightB) {
        return a.localeCompare(b);
      } else {
        return weightB - weightA;
      }
    });

    // Calculating Total
    let addedFees = 0;
    if (coverFees) {
      addedFees = fees;
    }

    // // // console.log('totalTip', totalTip)
    // // // console.log ('total', total)
    // // // console.log('type of total', typeof(total))
    // // // console.log('selectedTip', selectedTip)
    // -------
    if (frequenciesValue === "Monthly" && recurringCategories.length < 2) {
      donationInformation = "Monthly Donation";
    }
    if (frequenciesValue === "One-Time" && recurringCategories.length < 2) {
      donationInformation = "One-Time Donation";
    }
    if (frequenciesValue === "Daily" && recurringCategories.length < 2) {
      donationInformation = "Daily Donation";
    }
    if (frequenciesValue === "Weekly" && recurringCategories.length < 2) {
      donationInformation = "Weekly Donation";
    }
    if (frequenciesValue === "Annually" && recurringCategories.length < 2) {
      donationInformation = "Annual Donation";
    }

    if (
      allFrequenciesValues.length >= 3 &&
      !allFrequenciesValues.includes("One-Time")
    ) {
      donationInformation = "Recurring Donation";
    }

    if (
      recurringCategories.length >= 3 &&
      recurringCategories.includes("One-Time")
    ) {
      reoccuringFrequenciesValueVisability = {};
    }

    if (showCountries) {
      countryVisibility = { display: "flex" };
    }

    if (organizationNameField === true) {
      //   console.log("orgname value is true");
      orgNameVisibility = { display: "flex" };
    }

    if (organizationNameField === false) {
      //  console.log("orgname value is false");
      orgNameVisibility = { display: "none" };
    }

    if (selectedCountry !== null && showAddress) {
      addressVisability = {};
    }

    console.log("selectedCountry", selectedCountry);

    // set helper text values for inputs
    if (selectedCountry !== "") {
      // console.log("checkinnnnn", countriesMap)
      //cityText = countriesMap[selectedCountry].cityText ?? "";
      //regionText = countriesMap[selectedCountry].regionText ?? "";
      //postalCodeText = countriesMap[selectedCountry].postalCodeText ?? "";
      //console.log("postalCodeText", postalCodeText, "regionText", regionText, "cityText", cityText)
    }

    let categoriesVisibility = { display: "none" };
    if (funds !== undefined) {
      if (funds.length > 1) {
        categoriesVisibility = {};
      }
    }

    let amountButtonFontSize = "18px";
    let amountWidth = "24%";
    if (
      document.getElementById("appContainer") !== null &&
      document.getElementById("appContainer").offsetWidth < 420
    ) {
      //   // // console.log("WIDTH IS", document.getElementById('appContainer').offsetWidth)
      amountButtonFontSize = "16px";
      amountWidth = "24%";
    }

    if (showPhone === true) {
      phone1 = (
        <div className="donor-field">
          <label className={phoneNumber && "filled"} htmlFor={phoneNumber}>
            {" "}
            Phone Number
          </label>
          <input
            type="tel"
            className="Info"
            value={phoneNumber}
            onChange={this.handlePhoneNumberChange}
            autoComplete="tel"
          ></input>
        </div>
      );
    }

    let cardDivStyle = (
      <span
        className="flex items-center justify-center"
        style={{ pointerEvents: "none" }}
      >
        {/* <svg className="inline-block" width='24' height='24' xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
                <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd" />
            </svg> */}
        <CreditCardIcon className="inline-block h-5 w-5" />
        <span
          className="inline-block pl-1"
          style={{ fontSize: "18px", fontWeight: "400" }}
        >
          Card
        </span>
      </span>
    );

    let BankDivStyle = (
      <span
        className="flex items-center justify-center"
        style={{ pointerEvents: "none" }}
      >
        {/* <svg className="inline-block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clip-rule="evenodd" />
                <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
            </svg> */}
        <BuildingLibraryIcon className="mb-[2.5px] inline-block h-5 w-5" />
        <span
          className="inline-block pl-1"
          style={{ fontSize: "18px", fontWeight: "400" }}
        >
          Bank
        </span>
      </span>
    );

    let PaypalDivStyle = (
      <span
        className="flex items-center justify-center"
        style={{ pointerEvents: "none" }}
      >
        {/* <svg className="inline-block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clip-rule="evenodd" />
                <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
            </svg> */}
        <span
          className="inline-block pl-1"
          style={{ fontSize: "18px", fontWeight: "400" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="18.5579296875"
            viewBox="0 0 338.667 89.785"
            fill="currentColor"
          >
            <path
              fill="currentColor"
              d="M-828.604 39.734c-.697 0-1.289.506-1.398 1.195l-8.068 51.165a1.31 1.31 0 001.294 1.513h9.568c.696 0 1.289-.507 1.398-1.195l2.37-15.025a1.415 1.415 0 011.398-1.195h8.699c10.164 0 18.792-7.416 20.368-17.465 1.589-10.134-6.328-18.971-17.549-18.993zm9.301 11.422h6.96c5.73 0 7.596 3.381 7.006 7.12-.59 3.747-3.488 6.507-9.031 6.507h-7.084zm45.788 3.478c-2.416.009-5.196.504-8.317 1.804-7.159 2.984-10.597 9.151-12.057 13.647 0 0-4.647 13.717 5.852 21.253 0 0 9.737 7.255 20.698-.447l-.189 1.203a1.31 1.31 0 001.292 1.513h9.083c.697 0 1.289-.507 1.398-1.195l5.525-35.038a1.31 1.31 0 00-1.292-1.515h-9.083c-.697 0-1.29.507-1.398 1.195l-.297 1.886s-3.967-4.333-11.216-4.306zm.297 11.067c1.043 0 1.997.144 2.853.419 3.919 1.258 6.141 5.023 5.498 9.104-.793 5.025-4.914 8.725-10.199 8.725-1.042 0-1.996-.143-2.853-.418-3.918-1.258-6.154-5.023-5.511-9.104.793-5.025 4.927-8.727 10.212-8.727z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-697.804 39.734c-.697 0-1.289.506-1.398 1.195l-8.068 51.165a1.31 1.31 0 001.294 1.513h9.568c.696 0 1.289-.507 1.398-1.195l2.37-15.025a1.415 1.415 0 011.398-1.195h8.699c10.164 0 18.791-7.416 20.366-17.465 1.59-10.134-6.326-18.971-17.547-18.993zm9.301 11.422h6.96c5.73 0 7.596 3.381 7.006 7.12-.59 3.747-3.487 6.507-9.031 6.507h-7.084zm45.787 3.478c-2.416.009-5.196.504-8.317 1.804-7.159 2.984-10.597 9.151-12.057 13.647 0 0-4.645 13.717 5.854 21.253 0 0 9.735 7.255 20.697-.447l-.189 1.203a1.31 1.31 0 001.294 1.513h9.082c.697 0 1.289-.507 1.398-1.195l5.527-35.038a1.31 1.31 0 00-1.294-1.515h-9.083c-.697 0-1.29.507-1.398 1.195l-.297 1.886s-3.967-4.333-11.216-4.306zm.297 11.067c1.043 0 1.997.144 2.853.419 3.919 1.258 6.141 5.023 5.498 9.104-.793 5.025-4.914 8.725-10.199 8.725-1.042 0-1.996-.143-2.853-.418-3.918-1.258-6.154-5.023-5.511-9.104.793-5.025 4.927-8.727 10.212-8.727z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-745.92 55.859c-.72 0-1.232.703-1.012 1.388l9.958 30.901-9.004 14.562c-.437.707.071 1.62.902 1.62h10.642a1.77 1.77 0 001.513-.854l27.811-46.007a1.063 1.063 0 00-.909-1.611h-10.641a1.77 1.77 0 00-1.522.869l-10.947 18.482-5.557-18.345a1.417 1.417 0 00-1.355-1.006z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-609.107 39.734c-.696 0-1.289.507-1.398 1.195l-8.07 51.163a1.31 1.31 0 001.294 1.515h9.568c.696 0 1.289-.507 1.398-1.195l8.068-51.165a1.31 1.31 0 00-1.292-1.513z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-908.37 39.734a2.59 2.59 0 00-2.556 2.185l-4.247 26.936a2.587 2.587 0 012.556-2.185h12.445c12.525 0 23.153-9.137 25.095-21.519a20.76 20.76 0 00.245-2.793c-3.183-1.669-6.922-2.624-11.019-2.624z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-874.832 42.359a20.76 20.76 0 01-.245 2.793c-1.942 12.382-12.571 21.519-25.095 21.519h-12.445a2.587 2.587 0 00-2.556 2.185l-3.905 24.752-2.446 15.528a2.1 2.1 0 002.075 2.43h13.508a2.59 2.59 0 002.556-2.185l3.558-22.567a2.59 2.59 0 012.558-2.185h7.953c12.525 0 23.153-9.137 25.095-21.519 1.379-8.788-3.047-16.784-10.611-20.75z"
              transform="translate(936.898 -21.779)"
            ></path>
            <path
              fill="currentColor"
              d="M-923.716 21.779a2.587 2.587 0 00-2.556 2.183l-10.6 67.216a2.103 2.103 0 002.077 2.43h15.719l3.903-24.752 4.247-26.936a2.59 2.59 0 012.556-2.185h22.519c4.098 0 7.836.956 11.019 2.624.218-11.273-9.084-20.58-21.873-20.58z"
              transform="translate(936.898 -21.779)"
            ></path>
          </svg>
        </span>
      </span>
    );
    let loadingFinished = false;
    let donorInfoVisibility = { display: "none", justifyContent: "center" };
    let donorInfoContentVisibility = {
      // display: "none",
      // justifyContent: "center",
      visibility: "hidden",
      position: "absolute",
    };
    let frequenciesValueVisability = { display: "none" };
    let twoRecurringFrequenciesVisability = { display: "none" };

    let donorInfoHeaderVisibility = { display: "none" };
    let shimmerVisibility = {};
    let secretFormBox = "FormBoxShimmer";
    let paymentInfoVisibility = {};
    let paymentInfoContentVisibility = {};
    let paymentInfoShimmerVisibility = { display: "none" };
    let paymentInfoHeaderVisibility = {};
    let placeholderStyle = {
      width: "1.34%",
      display: "flex",
      justifyContent: "center",
    };
    let commaSeperatedAmount = amount
      .toFixed(2)
      .replace(/[.]00$/, "")
      .toLocaleString();
    let labelAmountHolder = "";
    //color field controls the color of the frequency. change here and below to dakrcolor to use opossing color palete
    let monthlyStyle = {
      backgroundColor: buttonPressColor,
      inset: buttonPressColor,
      color: lightColor,
      border: "1px solid rgba(0, 0, 0, 0.05)",
    };
    let oneTimeStyle = {
      backgroundColor: lightColor,
      inset: lightColor,
      color: "white",
    };

    let bankStyle = {
      top: "6px",
      backgroundColor: buttonPressColor,
      inset: buttonPressColor,
      color: lightColor,
      border: "1px solid rgba(0, 0, 0, 0.05)",
    };
    let paypalStyle = {
      //  top: "6px",
      backgroundColor: buttonPressColor,
      inset: buttonPressColor,
      color: lightColor,
      border: "1px solid rgba(0, 0, 0, 0.05)",
    };
    let cardStyle = {
      backgroundColor: lightColor,
      inset: lightColor,
      color: "white",
    };
    if (optIn === false) {
      labelAmountHolder = "One-Time";
    }
    if (optIn === true) {
      labelAmountHolder = "Recurring";
    }

    if (allFrequenciesValues.length === 2) {
      if (allFrequenciesValues.includes("One-Time")) {
        frequenciesValueVisability = {};
      } else {
        twoRecurringFrequenciesVisability = {};
      }
    }
    // console.log('look at me', loadTime, loading, page)
    if (page === "DONOR_INFO") {
      donorInfoVisibility = {};
      paymentInfoVisibility = { display: "none" };
      paymentInfoHeaderVisibility = { display: "none" };
    }

    if (!loading) {
      loadingFinished = true;
      shimmerVisibility = { display: "none" };
      secretFormBox = "FormBox";
      donorInfoContentVisibility = {};
      donorInfoHeaderVisibility = {};
      clearInterval(interval);
    }

    if (page !== "DONOR_INFO" && loading) {
      paymentInfoContentVisibility = { display: "none" };
      paymentInfoHeaderVisibility = { display: "none" };
      paymentInfoShimmerVisibility = {};
    }
    // if (page === "CUSTOM_INPUTS_PAGE" && !loading) {
    //   donorInfoVisibility = { display: "none" };
    //   donorInfoHeaderVisibility = { display: "none" };
    //   donorInfoContentVisibility = { display: "none" };
    //   paymentInfoVisibility = { display: "none" };
    //   paymentInfoContentVisibility = { display: "none" };
    //   paymentInfoHeaderVisibility = { display: "none" };
    //   secretFormBox = "";
    // }
    if (page !== "DONOR_INFO" && !loading) {
      donorInfoHeaderVisibility = { display: "none" };
    }

    let scheduleDonationButtonParentStyle = { display: "none" };
    let scheduleDonationButtonStyle = { display: "none" };
    let dateRangePickerStyle = { display: "none" };
    if (optIn && this.state.showDatePickerInput) {
      scheduleDonationButtonStyle = { display: "none" };
      scheduleDonationButtonParentStyle = { display: "none" };
      dateRangePickerStyle = { paddingBottom: "8px" };
    }
    if (optIn && !this.state.showDatePickerInput) {
      scheduleDonationButtonParentStyle = { paddingBottom: "8px" };
      scheduleDonationButtonStyle = {
        height: "42px",
        top: "0px",
        backgroundColor: "white",
        inset: "white",
        color: lightColor,
        "border-width": "1px",
        "border-color": lightColor,
        "border-style": "solid",
        marginTop: "0px",
      };
      dateRangePickerStyle = { display: "none" };
    }

    let tipRange = ["No Tip", "$1.00", "$2.00", "$3.00"];
    if (6.0 <= amount && amount <= 20.0) {
      tipRange = ["No Tip", "$2.00", "$3.00", "$5.00"]; //, "No Tip"]
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.indexOf("$1") === -1 ? tipRange.push("$1") : // // console.log();
      // tipRange.indexOf("$2") === -1 ? tipRange.push("$2") : // // console.log();;
      // tipRange.indexOf("$3") === -1 ? tipRange.push("$3") : // // console.log();;
      // tipRange.indexOf("Other") === -1 ? tipRange.push("Other") : // // console.log();
    }
    if (20.0 <= amount && amount < 50.0) {
      tipRange = ["No Tip", "$3.00", "$5.00", "$7.00"]; //, "No Tip"]
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.indexOf("10%") === -1 ? tipRange.push("10%") : // // console.log();
      // tipRange.indexOf("12%") === -1 ? tipRange.push("12%") : // // console.log();;
      // tipRange.indexOf("15%") === -1 ? tipRange.push("15%") : // // console.log();;
      // tipRange.indexOf("Other") === -1 ? tipRange.push("Other") : // // console.log();
    }
    if (amount >= 50.0) {
      tipRange = ["No Tip", "5%", "10%", "15%"]; //, "No Tip"]
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.pop();
      // tipRange.indexOf("10%") === -1 ? tipRange.push("10%") : // // console.log();
      // tipRange.indexOf("12%") === -1 ? tipRange.push("12%") : // // console.log();;
      // tipRange.indexOf("15%") === -1 ? tipRange.push("15%") : // // console.log();;
      // tipRange.indexOf("Other") === -1 ? tipRange.push("Other") : // // console.log();
    }

    if (fees === 0) {
      feeDisplay = "";
    }
    let tipList = Object.keys(tipRange).map((k) => {
      return (
        <div key={k} value={tipRange[k]}>
          {tipRange[k]}{" "}
        </div>
      );
    });
    let recurringCategoriesList = Object.keys(recurringFrequencies).map((k) => {
      if (recurringFrequencies[k] !== "One-Time") {
        return (
          <option key={k} value={recurringFrequencies[k]}>
            {recurringFrequencies[k][0].toString().toUpperCase() +
              recurringFrequencies[k].toString().slice(1).toLowerCase()}
          </option>
        );
      }
    });

    // If no tip selected yet, pick a default. we make a new variable cuz selectedTip is const
    let shownTipValue = selectedTip;
    if (shownTipValue === "") {
      // if less than $5, default selected is $1, so index 0. else use index 1 ($2 or 12%)
      if (amount <= 5) {
        shownTipValue = tipRange[0];
      } else {
        shownTipValue = tipRange[1];
      }
    }

    let categoryList = Object.keys(funds).map((k) => {
      return (
        <option key={k} value={funds[k]}>
          {funds[k]}{" "}
        </option>
      );
    });
    if (
      showFinixCardPaymentFields === true &&
      showPayByBankPaymentFields === false &&
      showPayByPaypalPaymentFields === false
    ) {
      showFinixCardPaymentFieldsVisability = {
        width: "100%",
        display: "flex",
        flexDirection: "column", // Arrange children in a column
        alignItems: "center", // Center inputs horizontally
        marginTop: "10px",
      };
    }
    if (
      showFinixCardPaymentFields === false &&
      showPayByBankPaymentFields === false &&
      showPayByPaypalPaymentFields === true
    ) {
      showPaypalPaymentFieldsVisability = {
        justifyContent: "center",
        width: "100%",
        display: "flex",
        marginTop: "10px",
      };
    }
    if (
      showPayByBankPaymentFields === true &&
      showFinixCardPaymentFields === false &&
      showPayByPaypalPaymentFields === false &&
      amount + this.calculateScheduledDonationTotal() < PAY_BY_BANK_MINIMUM &&
      defaultPaymentProcessor === "FINIX"
    ) {
      showPayByBankPaymentFieldsVisability = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      };
    }
    if (
      showPayByBankPaymentFields === true &&
      showFinixCardPaymentFields === false &&
      showPayByPaypalPaymentFields === false &&
      (amount + this.calculateScheduledDonationTotal() >= PAY_BY_BANK_MINIMUM ||
        defaultPaymentProcessor === "STRIPE")
    ) {
      showPayByBankPaymentFieldsVisability = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      };
      showPayByBankPaymentFieldsVisabilityPlaid = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      };
    }
    if (selectedTip === "Other") {
      customTipVisability = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      };
    }
    let amountDisplay1 = null;
    let monthly1;

    if (optIn) {
      amountDisplay1 = amountDisplay;
      monthly1 = monthly;
      monthlyStyle = {
        backgroundColor: lightColor,
        inset: lightColor,
        color: "white",
      };
      oneTimeStyle = {
        backgroundColor: buttonPressColor,
        inset: buttonPressColor,
        color: lightColor,
        border: "1px solid rgba(0, 0, 0, 0.05)",
      };
    }
    if (paymentMethod === "BANK") {
      bankStyle = {
        top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
        color: "white",
      };
      cardStyle = {
        backgroundColor: buttonPressColor,
        inset: buttonPressColor,
        color: lightColor,
        border: "1px solid rgba(0, 0, 0, 0.05)",
      };
      paypalStyle = {
        //top: "6px",
        backgroundColor: buttonPressColor,
        inset: buttonPressColor,
        color: lightColor,
        border: "1px solid rgba(0, 0, 0, 0.05)",
      };
    }
    if (paymentMethod === "PAYPAL") {
      paypalStyle = {
        //top: "6px",
        backgroundColor: lightColor,
        inset: lightColor,
        color: "white",
      };
      bankStyle = {
        backgroundColor: buttonPressColor,
        inset: buttonPressColor,
        color: lightColor,
        border: "1px solid rgba(0, 0, 0, 0.05)",
      };
      cardStyle = {
        backgroundColor: buttonPressColor,
        inset: buttonPressColor,
        color: lightColor,
        border: "1px solid rgba(0, 0, 0, 0.05)",
      };
    }

    let paypalAuthorizedPrefix = "";
    if (paypalAuthorizationCode !== "") {
      paypalAuthorizedPrefix = "Account verified. ";
    }

    // error check next button
    if (page === "DONOR_INFO") {
      //console.log("in Donor Info page");
      if (tickets === null || tickets === undefined || tickets.length === 0) {
        // console.log("tickets are null, undefined or length of 0");
        nextButton = (
          <button
            type="button"
            onMouseDown={this.handleNextOnMouseDown}
            onMouseLeave={this.handleNextOnMouseLeave}
            className="SubmitButton"
            style={nextButtonStyle}
            name="next"
            id="next"
            onClick={this.goToPayment}
          >
            {amount !== 0 &&
              this.calculateScheduledDonationTotal() === 0 &&
              `${paypalAuthorizedPrefix}Pay $${total.toFixed(2)} ${monthly}`}
            {amount === 0 &&
              this.calculateScheduledDonationTotal() !== 0 &&
              `${paypalAuthorizedPrefix}Schedule $${
                coverFees
                  ? (
                      this.calculateScheduledDonationTotal() + scheduledFees
                    ).toFixed(2)
                  : this.calculateScheduledDonationTotal().toFixed(2)
              }`}
            {amount !== 0 &&
              this.calculateScheduledDonationTotal() !== 0 &&
              `${paypalAuthorizedPrefix}Pay $${total.toFixed(2)} & Schedule $${
                coverFees
                  ? (
                      this.calculateScheduledDonationTotal() + scheduledFees
                    ).toFixed(2)
                  : this.calculateScheduledDonationTotal().toFixed(2)
              }`}
            {/* {" "}
            Donate ${total.toFixed(2)} {monthly} */}
          </button>
        );
      } else {
        if (freeTicket) {
          console.log("in free ticket");
          if (donationSubmitted) {
            nextButton = (
              <button
                type="button"
                onMouseDown={this.handleNextOnMouseDown}
                onMouseLeave={this.handleNextOnMouseLeave}
                className="SubmitButton"
                style={nextButtonStyle}
                name="next"
                id="next"
                disabled
              >
                {" "}
                Submitting
              </button>
            );
          } else {
            nextButton = (
              <button
                type="button"
                onMouseDown={this.handleNextOnMouseDown}
                onMouseLeave={this.handleNextOnMouseLeave}
                className="SubmitButton"
                style={nextButtonStyle}
                name="next"
                id="next"
                onClick={this.handleFreeTicketPurchase}
              >
                Submit
              </button>
            );
          }
        } else {
          console.log("in else for free ticket");

          nextButton = (
            <div style={{ paddingBottom: 10 }}>
              <button
                type="button"
                onMouseDown={this.handleNextOnMouseDown}
                onMouseLeave={this.handleNextOnMouseLeave}
                className="SubmitButton"
                style={nextButtonStyle}
                name="next"
                id="next"
                onClick={this.goToPayment}
              >
                {" "}
                {paypalAuthorizedPrefix}Pay ${total.toFixed(2)} {monthly}
              </button>
            </div>
          );
        }
      }

      if (donationSubmitted && errorMessage === "") {
        console.log("in donationSubmitted errorMessage");

        nextButton = (
          <button
            type="button"
            onMouseDown={this.handleNextOnMouseDown}
            onMouseLeave={this.handleNextOnMouseLeave}
            className="SubmitButton"
            style={nextButtonStyle}
            name="next"
            id="next"
            disabled
          >
            {" "}
            Submitting
          </button>
        );
      } else if (errorMessage !== "") {
        console.log("in errorMessage !==");

        nextButton = (
          <button
            type="button"
            className="SubmitButton"
            onMouseDown={this.handleNextOnMouseDown}
            onMouseLeave={this.handleNextOnMouseLeave}
            style={nextButtonStyle}
            name="next"
            id="next"
            onClick={this.goToPayment}
          >
            {" "}
            {errorMessage}{" "}
          </button>
        );
      }
    }
    // // error check submit button
    // else {
    //   submit = (
    //     <button
    //       key={total}
    //       type="button"
    //       className="SubmitButton"
    //       onMouseDown={this.handleSubmitOnMouseDown}
    //       onMouseLeave={this.handleSubmitOnMouseLeave}
    //       style={submitButtonStyle}
    //       name="button"
    //       id="button"
    //       onClick={this.handleDonate}
    //     >
    //       Donate ${total.toFixed(2).replace(/[.,]00$/, "")}
    //       {monthly1}{" "}
    //     </button>
    //   );

    //   if (donationSubmitted && errorMessage === "") {
    //     submit = (
    //       <button
    //         key={total}
    //         type="button"
    //         className="SubmitButton"
    //         onMouseDown={this.handleSubmitOnMouseDown}
    //         onMouseLeave={this.handleSubmitOnMouseLeave}
    //         style={submitButtonStyle}
    //         name="button"
    //         id="button"
    //         disabled
    //       >
    //         Submitting
    //       </button>
    //     );
    //   } else if (errorMessage !== "") {
    //     submit = (
    //       <button
    //         key={total}
    //         type="button"
    //         className="SubmitButton"
    //         onMouseDown={this.handleSubmitOnMouseDown}
    //         onMouseLeave={this.handleSubmitOnMouseLeave}
    //         style={submitButtonStyle}
    //         name="button"
    //         id="button"
    //         onClick={this.handleDonate}
    //       >
    //         {errorMessage}
    //       </button>
    //     );
    //   }
    // }

    //form 2 stuff
    if (
      amount + this.calculateScheduledDonationTotal() < PAY_BY_BANK_MINIMUM &&
      defaultPaymentProcessor === "FINIX"
    ) {
      //    // // console.log('visible')
      BankDivStyle = { display: "none", justifyContent: "center" };
      //submitButtonStyle = {top: "38px"}
      lineBreaks = (
        <>
          <br />
        </>
      );
      lineBreakTop = null;
      showPayByBankPaymentFieldsVisability = {
        width: "100%",
        display: "none",
        justifyContent: "center",
      };
      showPayByBankPaymentFieldsVisabilityPlaid = {
        width: "100%",
        display: "none",
        justifyContent: "center",
      };
    }
    if (
      amount + this.calculateScheduledDonationTotal() >= PAY_BY_BANK_MINIMUM ||
      defaultPaymentProcessor === "STRIPE"
    ) {
      //    // // console.log('visible')
      payByBankVisibility = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      };
      //submitButtonStyle = {top: "38px"}
      lineBreaks = (
        <>
          <br />
        </>
      );
      lineBreakTop = null;

      BankDivStyle = (
        <span
          className="flex items-center justify-center"
          style={{ pointerEvents: "none" }}
        >
          {/* <svg className="inline-block w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                    <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                    <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clip-rule="evenodd" />
                    <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
                </svg> */}
          <BuildingLibraryIcon className="mb-[2.5px] inline-block h-5 w-5" />
          <span
            className="inline-block pl-1"
            style={{ fontSize: "18px", fontWeight: "400" }}
          >
            Bank
          </span>
        </span>
      );
    } else {
      payByBankVisibility = {
        width: "100%",
        display: "none",
        justifyContent: "center",
      };
      //submitButtonStyle = {top: "38px"}
      lineBreaks = (
        <>
          <br />
          <br />
        </>
      );
      lineBreakTop = <br />;
    }
    let googlePayOrApplePayButton = (
      <GooglePay
        isChromium={isChromium}
        setLoad={this.setLoad}
        total={this.calculateWalletTotal().toString()}
        merchantIdentity={merchantIdentity}
        makeGooglePayPayment={this.makeGooglePayPayment}
        schedulePaymentShowComponent={this.schedulePaymentShowComponent}
        handlePaymentTypeChange={this.handlePaymentTypeChange}
      />
    );
    let gpayOrApplypayParentStyle = {};
    if (isSafari) {
      // apple pay doesnt need parent style. remove black background
      googlePayOrApplePayButton = (
        <ApplePay
          setShowApplePay={this.setShowApplePay}
          uuid={uuid}
          setLoad={this.setLoad}
          sponsee={merchantName}
          total={this.calculateWalletTotal().toString()}
          makeApplePayPayment={this.makeApplePayPayment}
          schedulePaymentShowComponent={this.schedulePaymentShowComponent}
          handlePaymentTypeChange={this.handlePaymentTypeChange}
        />
      );
      gpayOrApplypayParentStyle = {};
    }

    let cardAndBankingButtonElements = <div> </div>;

    if (
      approvedForPaypalPaymentProcessing === false &&
      (amount + this.calculateScheduledDonationTotal() >= PAY_BY_BANK_MINIMUM ||
        defaultPaymentProcessor === "STRIPE")
    ) {
      cardAndBankingButtonElements = (
        <div>
          <div class="FrequencyContainer">
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={cardStyle}
              id="card"
              name="card"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownCard}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveCard}
              disabled={paymentMethod === "CARD"}
            >
              {cardDivStyle}
            </button>
            <span style={placeholderStyle}> </span>
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={bankStyle}
              id="bank"
              name="bank"
              value="bank"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownBank}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveBank}
              disabled={paymentMethod === "BANK"}
            >
              {BankDivStyle}
            </button>
          </div>
        </div>
      );
    }
    if (
      approvedForPaypalPaymentProcessing === true &&
      ((frequenciesValue === "One-Time" && cart.length === 0) ||
        (this.recurringDonationInCart() === false && cart.length > 0)) &&
      amount + this.calculateScheduledDonationTotal() < PAY_BY_BANK_MINIMUM &&
      defaultPaymentProcessor === "FINIX"
    ) {
      cardAndBankingButtonElements = (
        <div>
          <div class="FrequencyContainer">
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={cardStyle}
              id="card"
              name="card"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownCard}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveCard}
              disabled={paymentMethod === "CARD"}
            >
              {cardDivStyle}
            </button>
            <span style={placeholderStyle}> </span>
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={paypalStyle}
              id="paypal-container-charitystack"
              name="paypal-container-charitystack"
              value="paypal-container-charitystack"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownPaypal}
              onMouseLeave={this.handlePaymentStyleOnMouseLeavePaypal}
              disabled={paymentMethod === "PAYPAL"}
            >
              {PaypalDivStyle}
            </button>
          </div>
        </div>
      );
    }
    if (
      approvedForPaypalPaymentProcessing === true &&
      ((frequenciesValue === "One-Time" && cart.length === 0) ||
        (this.recurringDonationInCart() === false && cart.length > 0)) &&
      (amount + this.calculateScheduledDonationTotal() >= PAY_BY_BANK_MINIMUM ||
        defaultPaymentProcessor === "STRIPE")
    ) {
      cardAndBankingButtonElements = (
        <div>
          <div class="FrequencyContainer">
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={cardStyle}
              id="card"
              name="card"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownCard}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveCard}
              disabled={paymentMethod === "CARD"}
            >
              {cardDivStyle}
            </button>
            <span style={placeholderStyle}> </span>
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={bankStyle}
              id="bank"
              name="bank"
              value="bank"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownBank}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveBank}
              disabled={paymentMethod === "BANK"}
            >
              {BankDivStyle}
            </button>

            <span style={placeholderStyle}> </span>
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={paypalStyle}
              id="paypal-container-charitystack"
              name="paypal-container-charitystack"
              value="paypal-container-charitystack"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownPaypal}
              onMouseLeave={this.handlePaymentStyleOnMouseLeavePaypal}
              disabled={paymentMethod === "PAYPAL"}
            >
              {PaypalDivStyle}
            </button>
          </div>
        </div>
      );
    }

    //disabling paypal for recurring

    if (
      approvedForPaypalPaymentProcessing === true &&
      ((frequenciesValue !== "One-Time" && cart.length === 0) ||
        (this.recurringDonationInCart() === true && cart.length > 0)) &&
      (amount + this.calculateScheduledDonationTotal() >= PAY_BY_BANK_MINIMUM ||
        defaultPaymentProcessor === "STRIPE")
    ) {
      cardAndBankingButtonElements = (
        <div>
          <div class="FrequencyContainer">
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={cardStyle}
              id="card"
              name="card"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownCard}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveCard}
              disabled={paymentMethod === "CARD"}
            >
              {cardDivStyle}
            </button>
            <span style={placeholderStyle}> </span>
            <button
              type="button"
              className="FrequencyButton OneTimeButton"
              style={bankStyle}
              id="bank"
              name="bank"
              value="bank"
              onClick={this.handlePaymentTypeChange}
              onMouseDown={this.handlePaymentStyleOnMouseDownBank}
              onMouseLeave={this.handlePaymentStyleOnMouseLeaveBank}
              disabled={paymentMethod === "BANK"}
            >
              {BankDivStyle}
            </button>
          </div>
        </div>
      );
    }

    let selectedDonationPicker;
    if (selectedFrequency === "Monthly") {
      selectedDonationPicker = "month";
    }
    if (selectedFrequency === "Weekly") {
      selectedDonationPicker = "week";
    }
    if (selectedFrequency === "Daily") {
      selectedDonationPicker = "day";
    }
    if (selectedFrequency === "Annually") {
      selectedDonationPicker = "year";
    }
    let scheduledEndDate = "";
    // if (endValue !== undefined && optIn === true) {
    //   let dateHolder = new Date(endValue);
    //   scheduledEndDate =
    //     "scheduled to end on " + dateHolder.toLocaleDateString();
    // }

    // Below are configurations for fund specific donations only
    let fundFreqDisplay = "";
    let fundKey = String(
      defaultFrequency + "_" + fund + "_" + originalAmount + "_" + formName
    );

    let fundScheduleText = "";
    let fundScheduleDatesDisplay = "";

    let fundSchedulePaymentObject = null;

    let fundStartValue = undefined;
    let fundEndValue = undefined;
    let fundTimeValue = undefined;
    let fundTimeDisplay = "";

    if (
      ticketsLength === 0 &&
      (!cart || cart.length === 0) &&
      fundKey in schedulePaymentMap
    ) {
      fundFreqDisplay =
        defaultFrequency === "ONE_TIME"
          ? ""
          : "/" + FREQUENCY_TO_TIMEPICKER_MAP[defaultFrequency];

      fundScheduleText = "";
      fundScheduleDatesDisplay = "";

      fundSchedulePaymentObject = schedulePaymentMap[fundKey];

      fundStartValue = fundSchedulePaymentObject.startValue ?? undefined;
      fundEndValue = fundSchedulePaymentObject.endValue ?? undefined;
      fundTimeValue = fundSchedulePaymentObject.timeValue ?? undefined;
      // format timeValue to be in 12 hour format and include AM or PM
      if (fundTimeValue !== undefined) {
        // fundTimeValue is a dayjs object. grab the hour and minutes in local time
        let hours = fundTimeValue.hour();
        let minutes = fundTimeValue.minute();
        let ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        fundTimeDisplay = ` ${hours}:${minutes}${ampm}`;
      }

      if (fundStartValue !== undefined && fundEndValue !== undefined) {
        fundScheduleText = "Scheduled";
        fundScheduleDatesDisplay = `${fundSchedulePaymentObject.startValue.format(
          "M/D/YYYY"
        )} - ${fundSchedulePaymentObject.endValue.format(
          "M/D/YYYY"
        )}${fundTimeDisplay}`;
      } else if (fundStartValue !== undefined && fundEndValue === undefined) {
        fundScheduleText = "Scheduled Start";
        fundScheduleDatesDisplay = `${fundSchedulePaymentObject.startValue.format(
          "M/D/YYYY"
        )}${fundTimeDisplay}`;
      } else if (fundStartValue === undefined && fundEndValue !== undefined) {
        fundScheduleText = "Scheduled End";
        fundScheduleDatesDisplay = `${fundSchedulePaymentObject.endValue.format(
          "M/D/YYYY"
        )}${fundTimeDisplay}`;
      }
    } else if (fundTimeValue !== undefined) {
      fundScheduleText = "Scheduled Start";
      fundScheduleDatesDisplay = `Today${fundTimeDisplay}`;
    }

    // End of fund specific configurations

    if (!showForm && loadingFinished) {
      return (
        <div>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <div
            style={donorInfoHeaderVisibility}
            class="HeaderBox"
            id="HeaderBox"
          >
            <div class="DonationInformationHeader">Form Disabled</div>
          </div>
          <div class="FormBox" id="FormBox">
            <form style={{ height: "500px" }}>
              <div className="shimmerContainer">
                <label className="SuccessLabel" style={{ textAlign: "center" }}>
                  This form is currently disabled
                </label>
                <br />
                <label className="SuccessLabel" style={{ textAlign: "center" }}>
                  Please use a different form, or enable
                </label>
                <br />
                <label className="SuccessLabel" style={{ textAlign: "center" }}>
                  it through the CharityStack dashboard
                </label>
              </div>
            </form>
          </div>
        </div>
      );
    } else if (donationCompleted) {
      return (
        <div>
          <label className="SuccessLabel">
            Your donation of ${total} has been submitted successfully!
          </label>
          <br />
          <label className="SuccessLabel">
            An email will be sent out soon confirming the status of your
            donation.
          </label>
          <br />
          <label className="SuccessLabel">
            Thank you for your generous contribution!
          </label>
          <br />
          <label className="SuccessLabel">
            This Page will redirect back in {timeLeft} seconds
          </label>
        </div>
      );
    } else {
      return (
        <>
          <div style={shimmerVisibility} className="">
            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
            />
            {/* <div className="hidden sm:block fixed top-0 right-0 p-4">
                            <XMarkIcon className="text-white h-10 w-10 justify-end text-gray-500 cursor-pointer" onClick={this.exitCheckoutForm} />
                        </div> */}
            {/* <div className="flex justify-center items-start sm:h-auto h-screen" style={{ display: 'flex' }}> */}
            <div
              style={{ scrollbarWidth: "none" }}
              className={`flex h-full flex-col overflow-y-auto sm:flex-row ${
                hostedPage === "HOSTED_PAGE_FULLSCREEN" ? "sm:h-screen" : ""
              } ${
                hostedPage === "HOSTED_PAGE_POPUP"
                  ? "h-screen rounded-md border border-gray-400 sm:h-[750px]"
                  : ""
              }
                  ${
                    hostedPage === null
                      ? "h-screen rounded-md border border-gray-400 sm:h-[750px]"
                      : ""
                  }`}
            >
              <div
                className={`bg-gray-100 sm:overflow-y-auto ${
                  hostedPage !== null
                    ? "h-full p-5 sm:flex sm:w-7/12 sm:flex-col sm:items-center sm:justify-center"
                    : "h-full p-5 sm:w-1/2"
                }`}
              >
                {/* <div className="flex justify-end">
                                    <XMarkIcon className="block sm:hidden h-6 w-6 text-gray-500 cursor-pointer" onClick={this.exitCheckoutForm} />
                                </div> */}
                <CustomShimmer />
              </div>

              <div
                className={`flex flex-1 bg-white ${
                  hostedPage !== null
                    ? "p-5 sm:w-5/12 sm:items-center sm:justify-center sm:text-center"
                    : "p-5"
                }`}
              >
                <CustomShimmer />
              </div>
            </div>
          </div>
          <div style={donorInfoContentVisibility}>
            <meta
              name="viewport"
              content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
            />
            {hostedPage !== "HOSTED_PAGE_FULLSCREEN" && (
              <div className="fixed right-0 top-0 hidden p-4 sm:block">
                {!donationSubmitted && (
                  <XMarkIcon
                    className="h-10 w-10 cursor-pointer justify-end text-gray-500 text-white"
                    onClick={() =>
                      this.state.page === "CONFIRMATION"
                        ? this.exitCheckoutForm(true, "EXIT")
                        : this.exitCheckoutForm(false, "EXIT")
                    }
                  />
                )}
              </div>
            )}

            {/* <div className="flex justify-center items-start sm:h-auto h-screen" style={{ display: 'flex' }}> */}
            <div
              style={{ scrollbarWidth: "none" }}
              className={`flex h-full flex-col overflow-y-auto sm:flex-row ${
                hostedPage === "HOSTED_PAGE_FULLSCREEN" ? "sm:h-screen" : ""
              } ${
                hostedPage === "HOSTED_PAGE_POPUP"
                  ? `h-screen rounded-md border border-gray-400 ${
                      this.state.page === "CONFIRMATION" && freeTicket
                        ? "sm:h-[500px]"
                        : "sm:h-[750px]"
                    }`
                  : ""
              }
                  ${
                    hostedPage === null
                      ? `h-screen rounded-md border border-gray-400 ${
                          this.state.page === "CONFIRMATION" && freeTicket
                            ? "sm:h-[500px]"
                            : "sm:h-[750px]"
                        }`
                      : ""
                  }
              `}
            >
              <div
                className={`bg-gray-100 sm:overflow-y-auto ${
                  hostedPage === "HOSTED_PAGE_FULLSCREEN"
                    ? "h-full p-5 sm:flex sm:w-7/12 sm:flex-col sm:items-center sm:justify-center sm:text-center"
                    : "h-full p-5 sm:w-1/2"
                }`}
                style={{ textAlign: "left", scrollbarWidth: "none" }}
              >
                <div className="my-auto w-full max-w-[1024px] sm:flex sm:flex-col">
                  <form className="w-full">
                    <div>
                      {hostedPage === null && (
                        <div
                          className={`flex justify-between 
                        ${
                          this.state.globalCustomInputs.length > 0
                            ? "sm:justify-between"
                            : "sm:justify-center"
                        }`}
                        >
                          {this.state.globalCustomInputs.length > 0 && (
                            <button
                              type="button"
                              onClick={() => {
                                if (this.state.page === "CONFIRMATION") {
                                  this.exitCheckoutForm(true, "BACK");
                                } else if (
                                  this.state.page === "DONOR_INFO" &&
                                  this.state.globalCustomInputs.length > 0
                                ) {
                                  this.setState({
                                    page: "GLOBAL_CUSTOM_INPUTS",
                                  });
                                } else {
                                  this.exitCheckoutForm(false, "EXIT");
                                }
                              }}
                              className="block flex cursor-pointer text-gray-500"
                            >
                              <ArrowLeftIcon className="h-5 w-5" />
                              <div className="text-sm">Back</div>
                            </button>
                          )}
                          <img
                            onError={(e) => {
                              e.target.src =
                                "https://charitystack-logos.s3.us-east-2.amazonaws.com/CharityStack+Logo+Full.png";
                            }}
                            src={`${process.env.REACT_APP_S3_LOGO_URL}/${merchantName}_Logo.png`}
                            className="LogoForOrg pl-1 sm:pl-0"
                            style={{
                              maxWidth: "125px",
                              maxHeight: "80px",
                            }}
                          ></img>

                          {!donationSubmitted && (
                            <XMarkIcon
                              className="block h-6 w-6 cursor-pointer text-gray-500 sm:hidden"
                              onClick={() =>
                                this.state.page === "CONFIRMATION"
                                  ? this.exitCheckoutForm(true, "EXIT")
                                  : this.exitCheckoutForm(false, "EXIT")
                              }
                            />
                          )}
                        </div>
                      )}
                      {hostedPage !== null && (
                        <div className="">
                          <div className="flex justify-between sm:hidden">
                            {!donationSubmitted && (
                              <button
                                type="button"
                                onClick={() => {
                                  if (this.state.page === "CONFIRMATION") {
                                    this.exitCheckoutForm(true, "BACK");
                                  } else if (
                                    this.state.page === "DONOR_INFO" &&
                                    this.state.globalCustomInputs.length > 0
                                  ) {
                                    this.setState({
                                      page: "GLOBAL_CUSTOM_INPUTS",
                                    });
                                  } else {
                                    this.exitCheckoutForm(false, "BACK");
                                  }
                                }}
                                className="block flex cursor-pointer text-gray-500"
                              >
                                <ArrowLeftIcon className="h-5 w-5" />
                                <div className="text-sm">Back</div>
                              </button>
                            )}
                            {/* {!donationSubmitted && donationFlagPopup && (
                              <XMarkIcon
                                className="block h-6 w-6 cursor-pointer text-gray-500 sm:hidden"
                                onClick={() =>
                                  this.state.page === "CONFIRMATION"
                                    ? this.exitCheckoutForm(true)
                                    : this.exitCheckoutForm(false)
                                }
                              />
                            )} */}

                            <img
                              onError={(e) => {
                                e.target.src =
                                  "https://charitystack-logos.s3.us-east-2.amazonaws.com/CharityStack+Logo+Full.png";
                              }}
                              src={`${process.env.REACT_APP_S3_LOGO_URL}/${merchantName}_Logo.png`}
                              className="LogoForOrg"
                              style={{
                                maxWidth: "125px",
                                maxHeight: "80px",
                              }}
                            ></img>
                          </div>

                          <div
                            className="hidden sm:absolute sm:left-0 sm:top-0 sm:grid sm:self-start
                            sm:p-5"
                          >
                            {!donationSubmitted &&
                              hostedPage === "HOSTED_PAGE_FULLSCREEN" && (
                                <div
                                  type="button"
                                  onClick={() => {
                                    if (this.state.page === "CONFIRMATION") {
                                      this.exitCheckoutForm(true, "BACK");
                                    } else if (
                                      this.state.page === "DONOR_INFO" &&
                                      this.state.globalCustomInputs.length > 0
                                    ) {
                                      this.setState({
                                        page: "GLOBAL_CUSTOM_INPUTS",
                                      });
                                    } else {
                                      this.exitCheckoutForm(false, "BACK");
                                    }
                                  }}
                                  className="flex cursor-pointer justify-self-start text-gray-500"
                                >
                                  <ArrowLeftIcon className="h-5 w-5" />
                                  <div className="text-sm">Back</div>
                                </div>
                              )}

                            {/* <img
                              onError={(e) => {
                                e.target.src =
                                  "https://charitystack-logos.s3.us-east-2.amazonaws.com/CharityStack+Logo+Full.png";
                              }}
                              src={`${process.env.REACT_APP_S3_LOGO_URL}/${merchantName}_Logo.png`}
                              className="LogoForOrg justify-self-center p-2 sm:p-0"
                              style={{
                                maxWidth: "125px",
                                maxHeight: "80px",
                              }}
                            ></img> */}
                          </div>
                        </div>
                      )}

                      <div>
                        {/* center logo placement for hostedPage. Only for desktop */}
                        {hostedPage !== null && (
                          <div className={`hidden sm:grid sm:items-center`}>
                            {!donationSubmitted &&
                              hostedPage === "HOSTED_PAGE_POPUP" && (
                                <div
                                  type="button"
                                  onClick={() => {
                                    if (this.state.page === "CONFIRMATION") {
                                      this.exitCheckoutForm(true, "BACK");
                                    } else if (
                                      this.state.page === "DONOR_INFO" &&
                                      this.state.globalCustomInputs.length > 0
                                    ) {
                                      this.setState({
                                        page: "GLOBAL_CUSTOM_INPUTS",
                                      });
                                    } else {
                                      this.exitCheckoutForm(false, "BACK");
                                    }
                                  }}
                                  className="flex cursor-pointer justify-self-start text-gray-500"
                                >
                                  <ArrowLeftIcon className="h-5 w-5" />
                                  <div className="text-sm">Back</div>
                                </div>
                              )}

                            <img
                              onError={(e) => {
                                e.target.src =
                                  "https://charitystack-logos.s3.us-east-2.amazonaws.com/CharityStack+Logo+Full.png";
                              }}
                              src={`${process.env.REACT_APP_S3_LOGO_URL}/${merchantName}_Logo.png`}
                              className="LogoForOrg justify-self-center"
                              style={{
                                maxWidth:
                                  hostedPage === "HOSTED_PAGE_FULLSCREEN"
                                    ? "225px"
                                    : "125px",
                                maxHeight:
                                  hostedPage === "HOSTED_PAGE_FULLSCREEN"
                                    ? "144px"
                                    : "80px",
                              }}
                            ></img>
                          </div>
                        )}

                        {/* Top level amount display */}
                        <span class="Amount pb-1">
                          {freeTicket ? (
                            <div className="text-5xl">$0.00</div>
                          ) : (
                            <>
                              {amount === 0 &&
                                this.calculateScheduledDonationTotal() !==
                                  0 && (
                                  <div className="flex flex-wrap items-baseline text-5xl">
                                    <div className="">
                                      $
                                      {coverFees
                                        ? (
                                            this.calculateScheduledDonationTotal() +
                                            scheduledFees
                                          ).toFixed(2)
                                        : this.calculateScheduledDonationTotal().toFixed(
                                            2
                                          )}
                                    </div>
                                    <div className="text-base text-gray-500">
                                      &nbsp;scheduled
                                    </div>
                                  </div>
                                )}

                              {amount !== 0 &&
                                this.calculateScheduledDonationTotal() ===
                                  0 && (
                                  <div className="flex flex-wrap items-baseline text-5xl">
                                    <div className="">${total.toFixed(2)}</div>
                                    <div className="text-base text-gray-500">
                                      &nbsp;paid today
                                    </div>
                                  </div>
                                )}
                              {amount !== 0 &&
                                this.calculateScheduledDonationTotal() !==
                                  0 && (
                                  <div className="flex flex-wrap items-baseline text-5xl">
                                    <div className="">${total.toFixed(2)}</div>
                                    <div className="text-base text-gray-500">
                                      &nbsp;today
                                    </div>
                                    {this.calculateScheduledDonationTotal() >
                                      0 && (
                                      <div className="text-base text-gray-500">
                                        &nbsp;& $
                                        {coverFees
                                          ? (
                                              this.calculateScheduledDonationTotal() +
                                              scheduledFees
                                            ).toFixed(2)
                                          : this.calculateScheduledDonationTotal().toFixed(
                                              2
                                            )}{" "}
                                        later
                                      </div>
                                    )}
                                  </div>
                                )}
                            </>
                          )}
                        </span>
                        {/* <span className="text-base font-normal">{monthly}</span> */}

                        {/* Fee Covered Modal */}
                        {freeTicket === false &&
                          this.state.page !== "CONFIRMATION" &&
                          showCoverFees === true && (
                            <div
                              className="mb-2 pb-2"
                              style={{
                                marginLeft: "-1.25rem",
                                marginRight: "-1.25rem",
                              }}
                              // offset parent margins so that border can extend to the edges of panel
                            >
                              <div className="px-5">
                                <div className="relative flex items-center">
                                  {" "}
                                  {/* Added relative position here */}
                                  <input
                                    type="checkbox"
                                    checked={coverFees}
                                    onChange={this.handleFeeOptinChange}
                                    className="custom-checkbox"
                                  />
                                  <div className="bg-transparent pl-2 text-sm text-black">
                                    Yes, I'll cover the transaction fee.
                                  </div>
                                  {/* heart animation for cover fees */}
                                  {/* {coverFees && (
                                    <div
                                      className="heartIcon"
                                      style={{ left: "65%", top: "35%" }}
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "-10px",
                                          left: "0",
                                          width: "15px",
                                          height: "18px",
                                        }}
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          fill="red"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                        </svg>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                                <div
                                  className="text-2xs text-gray-500"
                                  style={{ paddingLeft: "22px" }}
                                >
                                  <div>
                                    Fees are required by payment processors to
                                    facilitate the transaction.
                                  </div>
                                  <div>
                                    Covering the fee ensures that {merchantName}{" "}
                                    can receive the entire transaction.
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        {tickets === null ||
                        tickets === undefined ||
                        tickets.length === 0 ? (
                          cart === null ||
                          cart === undefined ||
                          cart.length === 0 ? (
                            <>
                              {fundKey in schedulePaymentMap && (
                                <div className="pb-3 text-sm font-normal">
                                  <div className="w-full rounded-t-md border border-gray-300 bg-gray-200 p-2 text-sm font-normal">
                                    <div className="flex justify-between text-black">
                                      <div className="group flex items-center">
                                        <div>{formName}</div>
                                      </div>

                                      <div>
                                        $
                                        {fundSchedulePaymentObject.amount.toFixed(
                                          2
                                        )}
                                        {fundFreqDisplay}
                                      </div>
                                    </div>
                                    <div className="text-xs text-gray-500">
                                      {products.length > 0
                                        ? products[0].productGroupName +
                                          " - " +
                                          products[0].productName
                                        : fund}
                                    </div>

                                    {this.state.showScheduleDonation &&
                                    defaultFrequency !== "ONE_TIME" ? (
                                      <div className="mt-2">
                                        {fundSchedulePaymentObject.showComponent ===
                                        true ? (
                                          <div className="flex w-full py-2 text-gray-300">
                                            <RecurringDatePicker
                                              fundKey={fundKey}
                                              startValue={
                                                fundSchedulePaymentObject.startValue
                                              }
                                              setStartValue={this.setStartValue}
                                              endValue={
                                                fundSchedulePaymentObject.endValue
                                              }
                                              setEndValue={this.setEndValue}
                                              timeValue={
                                                fundSchedulePaymentObject.timeValue
                                              }
                                              setTimeValue={this.setTimeValue}
                                              lightColor={lightColor}
                                              darkColor={darkColor}
                                              timePicker={
                                                fundSchedulePaymentObject.timePicker
                                              }
                                              setShowComponent={
                                                this
                                                  .setShowScheduleDonationComponent
                                              }
                                              showComponent={
                                                fundSchedulePaymentObject.showComponent
                                              }
                                              calculateTotalSubscriptionCharge={
                                                this
                                                  .calculateTotalSubscriptionCharge
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            {fundSchedulePaymentObject.startValue ===
                                              undefined &&
                                            fundSchedulePaymentObject.endValue ===
                                              undefined &&
                                            fundSchedulePaymentObject.timeValue ===
                                              undefined ? (
                                              <>
                                                {this.state.page ===
                                                "CONFIRMATION" ? (
                                                  <></>
                                                ) : (
                                                  <div
                                                    type="button"
                                                    className="cursor-pointer text-highlight-blue"
                                                    onClick={() =>
                                                      this.setShowScheduleDonationComponent(
                                                        fundKey,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Schedule payment +
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div className="flex items-center justify-between">
                                                <div>{fundScheduleText}</div>
                                                <div className="flex items-center">
                                                  {this.state.page !==
                                                    "CONFIRMATION" && (
                                                    <div
                                                      onClick={() =>
                                                        this.setShowScheduleDonationComponent(
                                                          fundKey,
                                                          true
                                                        )
                                                      }
                                                      className="mr-1 cursor-pointer rounded-xl bg-gray-300 px-1 py-[2px] text-2xs"
                                                    >
                                                      Edit
                                                    </div>
                                                  )}

                                                  <div className="text-right">
                                                    {fundScheduleDatesDisplay}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                      // // This is for one-time donations. I still add text so that it renders the same height. But the text is invisible
                                      // <div className="invisible">
                                      //   Schedule payment +
                                      // </div>
                                    )}
                                  </div>
                                  <div className="rounded-b-md border border-t-0 border-gray-300 bg-gray-100 p-2 text-right">
                                    {!fundSchedulePaymentObject.aggregate ? (
                                      <div>
                                        $
                                        {fundSchedulePaymentObject.amount.toFixed(
                                          2
                                        )}
                                        {fundFreqDisplay}
                                      </div>
                                    ) : (
                                      <div>
                                        $
                                        {fundSchedulePaymentObject.amount.toFixed(
                                          2
                                        )}
                                        {fundFreqDisplay} until $
                                        {fundSchedulePaymentObject.aggregate.toFixed(
                                          2
                                        )}
                                        {coverFees ? " + fees " : " "}paid
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          ) : (
                            cart.map((item, index) => {
                              const freqDisplay =
                                item.frequency === "ONE_TIME"
                                  ? ""
                                  : "/" +
                                    FREQUENCY_TO_TIMEPICKER_MAP[item.frequency];
                              const quantity =
                                item.quantity === 1
                                  ? ""
                                  : ` x ${item.quantity}`;
                              const fund = item.fundName;
                              const fundraiser = item.fundraiser;
                              const total =
                                parseFloat(item.amount) * item.quantity;

                              const cartKey = item.cartItemKey;

                              let scheduleText = "";
                              let scheduleDatesDisplay = "";

                              const schedulePaymentObject =
                                schedulePaymentMap[cartKey];

                              console.log(schedulePaymentObject);
                              const startValue =
                                schedulePaymentObject.startValue ?? undefined;
                              const endValue =
                                schedulePaymentObject.endValue ?? undefined;

                              const timeValue =
                                schedulePaymentObject.timeValue ?? undefined;
                              let timeValueDisplay = "";
                              if (timeValue !== undefined) {
                                // fundTimeValue is a dayjs object. grab the hour and minutes in local time
                                let hours = timeValue.hour();
                                let minutes = timeValue.minute();
                                let ampm = hours >= 12 ? "PM" : "AM";
                                hours = hours % 12;
                                hours = hours ? hours : 12; // the hour '0' should be '12'
                                minutes =
                                  minutes < 10 ? "0" + minutes : minutes;
                                timeValueDisplay = ` ${hours}:${minutes}${ampm}`;
                              }

                              if (
                                startValue !== undefined &&
                                endValue !== undefined
                              ) {
                                scheduleText = "Scheduled";
                                scheduleDatesDisplay = `${schedulePaymentObject.startValue.format(
                                  "M/D/YYYY"
                                )} - ${schedulePaymentObject.endValue.format(
                                  "M/D/YYYY"
                                )}${timeValueDisplay}`;
                              } else if (
                                startValue !== undefined &&
                                endValue === undefined
                              ) {
                                scheduleText = "Scheduled Start";
                                scheduleDatesDisplay = `${schedulePaymentObject.startValue.format(
                                  "M/D/YYYY"
                                )}${timeValueDisplay}`;
                              } else if (
                                startValue === undefined &&
                                endValue !== undefined
                              ) {
                                scheduleText = "Scheduled End";
                                scheduleDatesDisplay = `${schedulePaymentObject.endValue.format(
                                  "M/D/YYYY"
                                )}${timeValueDisplay}`;
                              } else if (timeValue !== undefined) {
                                scheduleText = "Scheduled Start";
                                scheduleDatesDisplay = `Today${timeValueDisplay}`;
                              }

                              return (
                                <div className="pb-3 text-sm font-normal">
                                  <div className="w-full rounded-t-md border border-gray-300 bg-gray-200 p-2 text-sm font-normal">
                                    <div className="flex justify-between text-black">
                                      <div className="group flex items-center">
                                        <div className="flex items-center">
                                          {item.fundraiser}
                                          {quantity}
                                          {/* <div
                                            className=" flex h-10  flex-row px-2"
                                            style={{
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <button
                                              className={`flex h-3 w-3 items-center justify-center rounded-full bg-[#D2D9E0] ${
                                                item.quantity === 1
                                                  ? "cursor-auto opacity-25"
                                                  : "cursor-pointer"
                                              }`}
                                              style={{
                                                fontSize: 8,
                                                color: "black",
                                              }}
                                              name="minus"
                                              id="minus-cart-button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.triggerButtonAnimation(
                                                  e.target.id
                                                );
                                                // update quanity for fund to be -1 if quantity is greater than 1
                                                if (item.quantity > 1) {
                                                  this.handleCartItem(
                                                    item.cartItemKey,
                                                    item.productID,
                                                    "SUBTRACT"
                                                  );
                                                }
                                              }}
                                            >
                                              &mdash;
                                            </button>
                                            <div className="px-1.5 text-center">
                                              {item.quantity}
                                            </div>
                                            <button
                                              className="flex h-3 w-3 cursor-pointer items-center justify-center rounded-full bg-[#D2D9E0]"
                                              style={{
                                                fontSize: 8,
                                                color: "black",
                                              }}
                                              name="plus"
                                              id="plus-cart-button"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.triggerButtonAnimation(
                                                  e.target.id
                                                );
                                                this.handleCartItem(
                                                  item.cartItemKey,
                                                  item.productID,
                                                  "ADD"
                                                );
                                              }}
                                            >
                                              &#xff0b;
                                            </button>
                                          </div> */}
                                        </div>
                                        <XMarkIcon
                                          className="ml-2 h-4 w-4 cursor-pointer text-gray-500"
                                          onClick={() =>
                                            this.handleCartItem(
                                              item.cartItemKey,
                                              item.productID,
                                              "REMOVE"
                                            )
                                          }
                                        />
                                      </div>

                                      <div>
                                        ${item.amount.toFixed(2)}
                                        {freqDisplay}
                                      </div>
                                    </div>
                                    <div className="text-xs text-gray-500">
                                      {item.productName
                                        ? item.productGroupName +
                                          " - " +
                                          item.productName
                                        : item.fundName}
                                    </div>

                                    {this.state.showScheduleDonation &&
                                    item.frequency !== "ONE_TIME" ? (
                                      <div className="mt-2">
                                        {schedulePaymentMap[item.cartItemKey]
                                          .showComponent === true ? (
                                          <div className="flex w-full py-2 text-gray-300">
                                            <RecurringDatePicker
                                              fundKey={item.cartItemKey}
                                              startValue={
                                                schedulePaymentMap[
                                                  item.cartItemKey
                                                ].startValue
                                              }
                                              setStartValue={this.setStartValue}
                                              endValue={
                                                schedulePaymentMap[
                                                  item.cartItemKey
                                                ].endValue
                                              }
                                              setEndValue={this.setEndValue}
                                              timeValue={
                                                schedulePaymentMap[
                                                  item.cartItemKey
                                                ].timeValue
                                              }
                                              setTimeValue={this.setTimeValue}
                                              lightColor={lightColor}
                                              darkColor={darkColor}
                                              timePicker={
                                                schedulePaymentMap[
                                                  item.cartItemKey
                                                ].timePicker
                                              }
                                              setShowComponent={
                                                this
                                                  .setShowScheduleDonationComponent
                                              }
                                              showComponent={
                                                schedulePaymentMap[
                                                  item.cartItemKey
                                                ].showComponent
                                              }
                                              calculateTotalSubscriptionCharge={
                                                this
                                                  .calculateTotalSubscriptionCharge
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <>
                                            {schedulePaymentMap[
                                              item.cartItemKey
                                            ].startValue === undefined &&
                                            schedulePaymentMap[item.cartItemKey]
                                              .endValue === undefined &&
                                            schedulePaymentMap[item.cartItemKey]
                                              .timeValue === undefined ? (
                                              <>
                                                {this.state.page ===
                                                "CONFIRMATION" ? (
                                                  <></>
                                                ) : (
                                                  <div
                                                    type="button"
                                                    className="cursor-pointer text-highlight-blue"
                                                    onClick={() =>
                                                      this.setShowScheduleDonationComponent(
                                                        item.cartItemKey,
                                                        true
                                                      )
                                                    }
                                                  >
                                                    Schedule payment +
                                                  </div>
                                                )}
                                              </>
                                            ) : (
                                              <div className="flex items-center justify-between">
                                                <div>{scheduleText}</div>
                                                <div className="flex items-center">
                                                  {this.state.page !==
                                                    "CONFIRMATION" && (
                                                    <div
                                                      onClick={() =>
                                                        this.setShowScheduleDonationComponent(
                                                          item.cartItemKey,
                                                          true
                                                        )
                                                      }
                                                      className="mr-1 cursor-pointer rounded-xl bg-gray-300 px-1 py-[2px] text-2xs"
                                                    >
                                                      Edit
                                                    </div>
                                                  )}

                                                  <div className="text-right">
                                                    {scheduleDatesDisplay}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                      // // This is for one-time donations. I still add text so that it renders the same height. But the text is invisible
                                      // <div className="invisible">
                                      //   Schedule payment +
                                      // </div>
                                    )}
                                  </div>
                                  <div className="rounded-b-md border border-t-0 border-gray-300 bg-gray-100 p-2 text-right">
                                    {!schedulePaymentMap[cartKey].aggregate ? (
                                      <div>
                                        ${total.toFixed(2)}
                                        {freqDisplay}
                                      </div>
                                    ) : (
                                      <div>
                                        ${total.toFixed(2)}
                                        {freqDisplay} until $
                                        {schedulePaymentMap[
                                          cartKey
                                        ].aggregate.toFixed(2)}
                                        {coverFees ? " + fees " : " "}
                                        paid
                                      </div>
                                    )}
                                  </div>
                                </div>
                                // <div
                                //   className="group grid grid-cols-12 text-xs font-normal"
                                //   style={{
                                //     // fontSize: "15px",
                                //     // fontWeight: "300",
                                //     paddingTop: "7.5px",
                                //     paddingBottom: "7.5px",
                                //   }}
                                // >
                                //   <div className="col-span-9">
                                //     {/* Mapped out each ticket name x #ofticket */}
                                //     {`${freq} Donation for ${fundraiser} - ${fund}${quantity}`}
                                //   </div>
                                //   <div className="col-span-1 px-2 text-gray-500 group-hover:text-gray-500 sm:text-gray-100">
                                //     {/* display a small x XMarkIcon */}
                                //     <XMarkIcon
                                //       className="h-4 w-4 cursor-pointer"
                                //       onClick={() =>
                                //         this.handleRemoveCartItem(
                                //           item.cartItemKey
                                //         )
                                //       }
                                //     />
                                //   </div>
                                //   <div className="col-span-2 text-right text-xs font-normal">
                                //     {/* price */}$
                                //     {total.toFixed(2).replace(/[.,]00$/, "")}
                                //   </div>
                                // </div>
                              );
                            })
                          )
                        ) : (
                          ticketMapping.map((t1, index) => {
                            return t1.numSold !== undefined &&
                              t1.numSold > 1 ? (
                              <div className="LeftPanelDetailsEvent">
                                <div className="text-sm font-normal">
                                  {/* Mapped out each ticket name x #ofticket */}
                                  Ticket for {t1.name} x {t1.numSold}
                                </div>
                                <div className="text-sm font-normal">
                                  {/* price */}
                                  {freeTicket
                                    ? "Free"
                                    : `$${t1.soldTotalPrice.toFixed(2)}`}
                                </div>
                              </div>
                            ) : t1.numSold !== undefined && t1.numSold === 1 ? (
                              <div className="LeftPanelDetailsEvent">
                                <div className="text-sm font-normal">
                                  {/* Mapped out each ticket name x #ofticket */}
                                  Ticket for {t1.name}
                                </div>
                                <div className="text-sm font-normal">
                                  {/* price */}
                                  {freeTicket
                                    ? "Free"
                                    : `$${t1.soldTotalPrice.toFixed(2)}`}
                                </div>
                              </div>
                            ) : (
                              <></>
                            );
                          })
                        )}

                        {/* <div class="flex justify-between">
                          <div className="text-sm font-normal">Subtotal</div>
                          <div className="">
                            <span className="text-sm font-normal">
                              ${amount}
                            </span>
                          </div>
                        </div>

                        {tickets === null ||
                        tickets === undefined ||
                        tickets.length === 0 ||
                        this.state.totalDiscount === 0 ? (
                          <></>
                        ) : (
                          <div className="LeftPanelDetailsEvent">
                            <div className="text-sm font-normal">Promo</div>
                            <div className="text-sm font-normal">
                              (${this.state.totalDiscount.toFixed(2)})
                            </div>
                          </div>
                        )} */}
                      </div>

                      {!freeTicket && (
                        <>
                          <div
                            style={{ width: "100%" }}
                            className="mx-auto mb-2 h-[1px] bg-gray-300"
                          ></div>
                          {(this.state.totalDiscount !== 0 || coverFees) && (
                            <div class="flex justify-between">
                              <div className="text-sm font-normal">
                                Subtotal
                              </div>
                              <div className="">
                                <span className="text-sm font-normal">
                                  {tickets === null ||
                                  tickets === undefined ||
                                  tickets.length === 0 ? (
                                    <>
                                      $
                                      {(
                                        amount +
                                        this.calculateScheduledDonationTotal()
                                      ).toFixed(2)}
                                    </>
                                  ) : (
                                    <>${originalAmount.toFixed(2)}</>
                                  )}
                                </span>
                              </div>
                            </div>
                          )}

                          {tickets === null ||
                          tickets === undefined ||
                          tickets.length === 0 ||
                          this.state.totalDiscount === 0 ? (
                            <></>
                          ) : (
                            <div className="LeftPanelDetailsEvent">
                              <div className="text-sm font-normal">Promo</div>
                              <div className="text-sm font-normal">
                                (${this.state.totalDiscount.toFixed(2)})
                              </div>
                            </div>
                          )}
                          {coverFees && (
                            <div class="flex justify-between">
                              <div className="text-sm font-normal">
                                Transaction Fee
                              </div>
                              <div className="">
                                <span className="text-sm font-normal">
                                  {freeTicket
                                    ? "$0.00"
                                    : `$${
                                        amount === 0
                                          ? scheduledFees.toFixed(2)
                                          : (fees + scheduledFees).toFixed(2)
                                      }`}
                                </span>
                              </div>
                            </div>
                          )}
                          {/* show mini bar if promocode exists or fees are covered */}
                          {(this.state.totalDiscount !== 0 || coverFees) && (
                            <div className="ml-auto h-[1.25px] w-32 bg-gray-300"></div>
                          )}
                        </>
                      )}
                      {!freeTicket && (
                        <div
                          class="LeftPanelDetails"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <div className="text-sm font-normal">Total</div>
                          <div className="text-sm font-normal">
                            {freeTicket
                              ? "$0.00"
                              : `$${
                                  coverFees
                                    ? (
                                        total +
                                        this.calculateScheduledDonationTotal() +
                                        scheduledFees
                                      ).toFixed(2)
                                    : (
                                        total +
                                        this.calculateScheduledDonationTotal()
                                      ).toFixed(2)
                                }`}
                          </div>
                        </div>
                      )}

                      {this.state.page !== "CONFIRMATION" && (
                        <>
                          {
                            // Double the donation condition
                            this.state.showDoubleTheDonation && (
                              <>
                                <br></br>
                                <div className="text-sm font-normal">
                                  Additional Details
                                </div>
                                <div
                                  className={`mt-1 w-[120px] border-b-[1px] border-[#cbc7c7] ${
                                    this.state.merchantName.includes(
                                      "ICNA Relief USA"
                                    )
                                      ? "opacity-0"
                                      : ""
                                  }`}
                                ></div>
                              </>
                            )
                          }
                          <div className="divide-y-[1px] divide-[#cbc7c7]">
                            {/* {(this.state.frequenciesValue !== "One-Time" ||
                              this.recurringDonationInCart()) &&
                              this.state.showScheduleDonation && (
                                <div className="AdditionalDetailsFields flex flex-col items-start">
                                  <span>Schedule End Date</span>
                                  <div className="flex w-full justify-between">

                                    <span
                                      className="font-medium"
                                      style={{ fontSize: "10px" }}
                                    >
                                      Chose a date for your recurring
                                      subscription to end on a specific date.
                                    </span>
                                    {this.state.toggleScheduleDonation ==
                                    false ? (
                                      <ChevronDownIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={(e) =>
                                          this.setState({
                                            toggleScheduleDonation: true,
                                          })
                                        }
                                      />
                                    ) : (
                                      <ChevronUpIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={(e) =>
                                          this.setState({
                                            toggleScheduleDonation: false,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                  {this.state.toggleScheduleDonation ==
                                    true && (
                                    <div className="flex w-full py-2">
                                      <RecurringDatePicker
                                        startValue={this.state.startValue}
                                        setStartValue={this.setStartValue}
                                        endValue={this.state.endValue}
                                        setEndValue={this.setEndValue}
                                        lightColor={lightColor}
                                        darkColor={darkColor}
                                        timePicker={selectedDonationPicker}
                                        setShowComponent={
                                          this.setToggleScheduleDonation
                                        }
                                        showComponent={
                                          this.state.toggleScheduleDonation
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )} */}

                            {this.state.showDoubleTheDonation && (
                              <div
                                className="AdditionalDetailsFields flex flex-col items-start"
                                style={
                                  this.state.merchantName.includes(
                                    "ICNA Relief USA"
                                  )
                                    ? {
                                        backgroundColor: "#7DC794",
                                        borderRadius: "6px",
                                        color: "#FEFFFF",
                                        padding: "10px 10px",
                                        // opacity: "0.7",
                                        border: "2px solid #00A950",
                                      }
                                    : {}
                                }
                              >
                                <span className="font-bold">Company Match</span>
                                <div className="flex w-full justify-between">
                                  <span
                                    className="font-bold"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Type in your employer to find out if they
                                    can match your donation.
                                  </span>
                                  {!merchantName.includes(
                                    "ICNA Relief USA"
                                  ) && (
                                    <>
                                      {this.state.toggleDoubleTheDonation ===
                                      false ? (
                                        <ChevronDownIcon
                                          className="h-6 w-6 cursor-pointer"
                                          onClick={(e) =>
                                            this.setState({
                                              toggleDoubleTheDonation: true,
                                            })
                                          }
                                        />
                                      ) : (
                                        <ChevronUpIcon
                                          className="h-6 w-6 cursor-pointer"
                                          onClick={(e) =>
                                            this.setState({
                                              toggleDoubleTheDonation: false,
                                            })
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                {
                                  <div
                                    className="flex w-full py-2"
                                    style={{
                                      display: this.state
                                        .toggleDoubleTheDonation
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <DoubleTheDonation
                                      setCompany={this.setCompany}
                                      // merchantName={merchantName} // only needed to set ICNA styling
                                    />
                                  </div>
                                }
                              </div>
                            )}

                            {tickets !== null &&
                              tickets !== undefined &&
                              tickets.length !== 0 &&
                              ticketMapping !== null &&
                              ticketMapping !== undefined &&
                              ticketMapping.length !== 0 &&
                              this.state.showPromos && (
                                <div className="AdditionalDetailsFields flex flex-col items-start">
                                  <div className="flex w-full justify-between">
                                    <span>Promo Code</span>
                                    {this.state.togglePromos === false ? (
                                      <ChevronDownIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={(e) =>
                                          this.setState({
                                            togglePromos: true,
                                          })
                                        }
                                      />
                                    ) : (
                                      <ChevronUpIcon
                                        className="h-6 w-6 cursor-pointer"
                                        onClick={(e) =>
                                          this.setState({
                                            togglePromos: false,
                                          })
                                        }
                                      />
                                    )}
                                  </div>
                                  {
                                    <div
                                      className={`flex w-full ${
                                        this.state.togglePromos ? "py-2" : ""
                                      }`}
                                      style={{
                                        display: this.state.togglePromos
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <FormControl variant="standard">
                                          <div class="flex space-x-5">
                                            <div className="donor-field">
                                              <label
                                                className={
                                                  promoCode && "filled"
                                                }
                                                htmlFor={promoCode}
                                              >
                                                {" "}
                                                Input Promo Code
                                              </label>
                                              <input
                                                type="text"
                                                className="Info"
                                                onKeyPress={(e) => {
                                                  e.key === "Enter" &&
                                                    e.preventDefault();
                                                }}
                                                value={promoCode}
                                                onChange={
                                                  this.handlePromoChange
                                                }
                                              ></input>
                                            </div>

                                            <button
                                              type="button"
                                              className="SubmitButtonPromo"
                                              style={{
                                                backgroundColor: lightColor,
                                                width: 200,
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                              }}
                                              name="promoSubmit"
                                              id="promoSubmit"
                                              onClick={this.handlePromoCheck}
                                            >
                                              {promoMessage}
                                            </button>
                                          </div>
                                        </FormControl>
                                      </Box>
                                    </div>
                                  }
                                </div>
                              )}

                            {this.state.showAnonymousDonations && (
                              <div className="AdditionalDetailsFields flex flex-col items-start">
                                <span>Anonymous Donation</span>
                                <div className="flex w-full justify-between">
                                  <span
                                    className="font-medium"
                                    style={{ fontSize: "10px" }}
                                  >
                                    Prevent your information and donation from
                                    being shown publicly.
                                  </span>
                                  {this.state.toggleAnonymousDonations ===
                                  false ? (
                                    <ChevronDownIcon
                                      className="h-6 w-6 cursor-pointer"
                                      onClick={(e) =>
                                        this.setState({
                                          toggleAnonymousDonations: true,
                                        })
                                      }
                                    />
                                  ) : (
                                    <ChevronUpIcon
                                      className="h-6 w-6 cursor-pointer"
                                      onClick={(e) =>
                                        this.setState({
                                          toggleAnonymousDonations: false,
                                        })
                                      }
                                    />
                                  )}
                                </div>
                                {
                                  <div
                                    className="flex w-full py-2"
                                    style={{
                                      display: this.state
                                        .toggleAnonymousDonations
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <div id="arrow_box1">
                                      <div id="arrow_box">
                                        <div
                                          style={{
                                            justifyContent: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            fontSize: "10px",
                                          }}
                                          className="text-2xs font-[500] leading-4 text-[#868686]"
                                        >
                                          Selecting the option below will
                                          prevent your name from being shared
                                          publicly for this donation. Your
                                          details will still be shared to the
                                          nonprofit.{" "}
                                        </div>
                                        <div
                                          style={{
                                            paddingTop: "0px",
                                            paddingBottom: "0px",
                                            justifyContent: "start",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            defaultChecked={
                                              this.state.anonymous
                                            }
                                            onClick={this.handleAnonymousChange}
                                            className="custom-checkbox"
                                          />
                                          &nbsp;
                                          <div
                                            // id="donationAmountSecondPageCoverFees"
                                            className="py-2 text-2xs font-[500] text-[#868686]"
                                          >
                                            Yes, I want my donation to be
                                            anonymous.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            )}
                          </div>
                          <div className="hidden sm:block">
                            <Faqs faqs={this.state.faqs} />
                          </div>
                        </>
                      )}
                    </div>
                  </form>
                  {/* <div className="hidden grow sm:flex"></div>{" "} */}
                  {/* this is a spacer to push logo to the bottom */}
                  {/* <div
                    className="mt-4 hidden sm:flex"
                    style={{
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                    onClick={() =>
                      window.open("https://charitystack.com", "_blank")
                    }
                  >
                    <p className="charitystack">powered by</p>
                    <p className="charity">charity</p>
                    <p className="stack">stack</p>
                  </div> */}
                </div>
              </div>

              <div
                className={`no-scrollbar flex flex-1 flex-col bg-white sm:overflow-y-auto ${
                  hostedPage === "HOSTED_PAGE_FULLSCREEN"
                    ? "p-5 sm:w-5/12 sm:items-center"
                    : "p-5 sm:relative sm:items-center"
                }`}
              >
                <div className="my-auto flex w-full justify-center">
                  {/* <Elements
                    stripe={stripePromise}
                    // options={this.state.stripeExpressCheckoutOptions}
                  > */}
                  <form
                    className="w-full max-w-lg"
                    style={
                      this.state.page === "DONOR_INFO"
                        ? {}
                        : // : { visibility: "hidden" }
                          { display: "none" }
                    }
                  >
                    <div>
                      <div
                        style={
                          freeTicket ||
                          defaultPaymentProcessor !== "FINIX" ||
                          merchantName ===
                            "Rahma Worldwide Aid & Development" ||
                          merchantName === "Dev"
                            ? { display: "none" }
                            : gpayOrApplypayParentStyle
                        }
                      >
                        {googlePayOrApplePayButton}
                      </div>
                      {this.state.stripePromise && (
                        <div
                          style={
                            freeTicket || defaultPaymentProcessor !== "STRIPE"
                              ? { display: "none" }
                              : gpayOrApplypayParentStyle
                          }
                        >
                          <Elements
                            stripe={this.state.stripePromise}
                            options={{
                              paymentMethodCreation: "manual",
                              phoneEnabled: true,
                              emailEnabled: true,
                              billingAddressCollection: "required",
                              mode: "payment",
                              amount:
                                total === 0 &&
                                this.calculateScheduledDonationTotal() === 0
                                  ? 100
                                  : parseInt(
                                      this.calculateWalletTotal() * 100,
                                      10
                                    ),
                              currency: "usd",
                              // Customizable with appearance API.
                              appearance: {
                                /*...*/
                              },
                            }}
                          >
                            <StripeExpressCheckout
                              makeGooglePayPayment={this.makeGooglePayPayment}
                              makeApplePayPayment={this.makeApplePayPayment}
                              setLoad={this.setLoad}
                              setStripeWalletAvailable={
                                this.setStripeWalletAvailable
                              }
                              handlePaymentTypeChange={
                                this.handlePaymentTypeChange
                              }
                            />

                            {/* <StripeGooglePay
                              setLoad={this.setLoad}
                              total={total}
                              setStripeWalletAvailable={
                                this.setStripeWalletAvailable
                              }
                              makeGooglePayPayment={this.makeGooglePayPayment}
                            /> */}
                          </Elements>
                        </div>
                      )}
                      {!(
                        merchantName === "Rahma Worldwide Aid & Development" ||
                        merchantName === "Dev"
                      ) && (
                        <div style={freeTicket ? { display: "none" } : {}}>
                          {
                            // GPAY or APPLE PAY
                            (((showApplePay && isSafari) || isChromium) &&
                              defaultPaymentProcessor === "FINIX") ||
                            (defaultPaymentProcessor === "STRIPE" &&
                              stripeWalletAvailable) ? (
                              <div
                                className="payWCard text-gray-300"
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "12px",
                                }}
                              >
                                <span className="text-gray-500">
                                  &nbsp;Or pay another way&nbsp;
                                </span>
                              </div>
                            ) : (
                              <></>
                            )
                          }
                        </div>
                      )}

                      {/*  <div
                        className="cursor-pointer "
                        style={{
                          fontSize: "12px", // Smaller font size
                          marginTop: "-5px",
                          color: "#b1b1b1",
                        }}
                      >
                        Contact Information
                      </div> */}
                      <div className="donor-field">
                        <label className={email && "filled"} htmlFor={email}>
                          {" "}
                          Email Address{" "}
                        </label>
                        <input
                          type="text"
                          className="Info"
                          value={email}
                          onChange={this.handleEmailChange}
                          autoComplete="email"
                        ></input>
                      </div>
                      {phone1}
                      <div style={{ display: "flex" }}>
                        <div className="donor-field">
                          <label
                            className={firstName && "filled"}
                            htmlFor={firstName}
                          >
                            {" "}
                            First Name
                          </label>
                          <input
                            type="text"
                            className="Info"
                            value={firstName}
                            onChange={this.handlefirstNameChange}
                            autoComplete="given-name"
                          ></input>
                        </div>
                        &nbsp;
                        <div className="donor-field">
                          <label
                            className={lastName && "filled"}
                            htmlFor={lastName}
                          >
                            {" "}
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="Info"
                            value={lastName}
                            onChange={this.handlelastNameChange}
                            autoComplete="family-name"
                          ></input>
                        </div>
                      </div>
                      {/* Show Organization Name */}
                      {freeTicket === false &&
                        this.state.page !== "CONFIRMATION" &&
                        showOrganizationNameField === true && (
                          <button
                            type="button"
                            className="cursor-pointer text-highlight-blue"
                            style={{
                              fontSize: "12px", // Smaller font size
                              marginTop: "-5px",
                            }}
                            name="showOrgName"
                            id="showOrgName"
                            onClick={this.handleShowingOrganizationNameField}
                          >
                            + Organization Name
                          </button>
                        )}
                      <div style={orgNameVisibility}>
                        <div className="donor-field">
                          <label
                            className={orgName && "filled"}
                            htmlFor={orgName}
                          >
                            {" "}
                            Organization Name{" "}
                          </label>
                          <input
                            type="text"
                            className="Info"
                            value={orgName}
                            onChange={this.handleOrgNameChange}
                          ></input>
                          <button
                            className="clear-button"
                            onClick={this.handleShowingOrganizationNameField}
                          >
                            &times;
                          </button>
                        </div>
                      </div>
                      {this.state.page !== "CONFIRMATION" &&
                        (showAddress === true || showCountries === true) && (
                          <div
                            className="cursor-pointer "
                            style={{
                              fontSize: "12px", // Smaller font size
                              marginTop: "-5px",
                              paddingTop: "19px",
                              color: "#b1b1b1",
                            }}
                          >
                            {/*                                      

                            Billing Address
                            */}
                          </div>
                        )}
                      {/*      <div style={countryVisibility}>
                        <select
                          onChange={this.handleSelectedCountryChange}
                          className={countryButtonStyle}
                          value={
                            selectedCountry === null ? "" : selectedCountry
                          }
                        >
                          <option key="" value="" selected disabled>
                            Country
                          </option>
                          {countries.map((country) => (
                            <option key={country.value} value={country.value}>
                              {country.label}
                            </option>
                          ))}

                        </select>
                      </div> */}
                      <div style={addressVisability}>
                        {this.state.mapBoxAccess ? (
                          <AddressAutofill
                            accessToken={
                              process.env.REACT_APP_SCOPED_MAPBOX_TOKEN
                            }
                            onError={this.handleMapboxError}
                          >
                            <div className="donor-field">
                              <label
                                className={streetAddress && "filled"}
                                htmlFor={streetAddress}
                              >
                                {" "}
                                Address 1
                              </label>
                              <input
                                type="text"
                                className="Info"
                                value={streetAddress}
                                onChange={this.handleStreetAddressChange}
                                autoComplete="address-line1"
                              ></input>
                            </div>
                          </AddressAutofill>
                        ) : (
                          <div className="donor-field">
                            <label
                              className={streetAddress && "filled"}
                              htmlFor={streetAddress}
                            >
                              {" "}
                              Address 100
                            </label>
                            <input
                              type="text"
                              className="Info"
                              value={streetAddress}
                              onChange={this.handleStreetAddressChange}
                              autoComplete="address-line1"
                            ></input>
                          </div>
                        )}
                        <div className="donor-field">
                          <label
                            className={streetAddress2 && "filled"}
                            htmlFor={streetAddress2}
                          >
                            {" "}
                            Address 2
                          </label>

                          <input
                            type="text"
                            className="Info"
                            value={streetAddress2}
                            onChange={this.handleStreetAddress2Change}
                            autoComplete="address-line2"
                          ></input>
                        </div>

                        <div style={{ display: "flex" }}>
                          {countrySettings.cityText !== "" && (
                            <div className="donor-field">
                              <label
                                className={city && "filled"}
                                htmlFor={city}
                              >
                                {" "}
                                {countrySettings.cityText}
                              </label>
                              <input
                                type="text"
                                className="Info"
                                value={city}
                                onChange={this.handleCityChange}
                                autoComplete="address-level2"
                              ></input>
                            </div>
                          )}
                          &nbsp;
                          {countrySettings.regionText !== "" ? (
                            <div className="donor-field">
                              <label
                                className={state && "filled"}
                                htmlFor={state}
                              >
                                {" "}
                                {countrySettings.regionText}
                              </label>
                              <input
                                type="text"
                                maxLength="2"
                                className="Info"
                                value={state}
                                onChange={this.handleStateChange}
                                autoComplete="address-level1"
                              />
                            </div>
                          ) : (
                            <input
                              type="text"
                              maxLength="2"
                              className="Info sr-only"
                              value={state}
                              onChange={this.handleStateChange}
                              autoComplete="address-level1"
                            />
                          )}
                          &nbsp;
                          {countrySettings.postalCodeText !== "" && (
                            <div className="donor-field">
                              <label className={zip && "filled"} htmlFor={zip}>
                                {" "}
                                {countrySettings.postalCodeText}
                              </label>
                              <input
                                type="tel"
                                maxLength="6"
                                className="Info"
                                value={zip}
                                onChange={this.handleZipChange}
                                autoComplete="postal-code"
                              ></input>
                            </div>
                          )}
                        </div>

                        <div className="relative inline-block w-full">
                          <select
                            onChange={this.handleSelectedCountryChange}
                            className={`w-full appearance-none rounded-md border border-gray-300 px-4 py-2 pr-8 shadow-sm focus:border-blue-500 focus:outline-none ${countryButtonStyle}`}
                            autoComplete="country-name"
                            value={selectedCountry}
                          >
                            <option key="" value="" disabled>
                              Country
                            </option>
                            {countries.map((country) => (
                              <option key={country.label} value={country.label}>
                                {country.label}
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                            <svg
                              className="h-3 w-3 text-black"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 26 28"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div
                        style={
                          freeTicket
                            ? { display: "none" }
                            : {
                                marginTop: "-5px",
                                paddingTop: "19px",
                              }
                        }
                      >
                        {!(
                          merchantName ===
                            "Rahma Worldwide Aid & Development" ||
                          merchantName === "Dev"
                        ) && cardAndBankingButtonElements}
                        <div style={showPayByBankPaymentFieldsVisability}>
                          <div
                            style={
                              defaultPaymentProcessor === "FINIX"
                                ? { width: "100%" }
                                : { display: "none" }
                            }
                          >
                            <PayByBank
                              setLoad={this.setLoad}
                              makePlaidPayment={this.makePlaidPayment}
                              goToPayment={this.goToPayment}
                            />
                          </div>

                          <div
                            style={
                              defaultPaymentProcessor === "STRIPE"
                                ? { width: "100%" }
                                : { display: "none" }
                            }
                          >
                            <Elements stripe={this.state.stripePromise}>
                              <StripeFinancialConnections
                                stripeClientSecret={stripeClientSecret}
                                setErrorMessage={this.setErrorMessage}
                                setLoad={this.setLoad}
                                setStripeClientSecret={
                                  this.setStripeClientSecret
                                }
                                stripeAccountID={stripeAccountID}
                                stripeCustomerID={stripeCustomerID}
                                processStripePayByBank={
                                  this.processStripePayByBank
                                }
                                name={firstName + " " + lastName}
                                email={email}
                                phoneNumber={phoneNumber}
                                goToPayment={this.goToPayment}
                              />
                            </Elements>
                          </div>
                        </div>
                        <div style={showPayByBankPaymentFieldsVisability}>
                          {
                            // defaultPaymentProcessor === "FINIX" && (
                            //   <div style={{ display: "flex", width: "100%" }}>
                            //     <div
                            //       className="donor-field"
                            //       style={{ width: "33.33%" }}
                            //     >
                            //       <label
                            //         className={accountNumber && "filled"}
                            //         htmlFor={accountNumber}
                            //       >
                            //         {" "}
                            //         Account
                            //       </label>
                            //       <input
                            //         type="text"
                            //         className="Info"
                            //         value={accountNumber}
                            //         onChange={this.handleAccountNumberChange}
                            //       ></input>
                            //     </div>{" "}
                            //     &nbsp;
                            //     <div
                            //       className="donor-field"
                            //       style={{ width: "33.33%" }}
                            //     >
                            //       <label
                            //         className={routingNumber && "filled"}
                            //         htmlFor={routingNumber}
                            //       >
                            //         {" "}
                            //         Routing
                            //       </label>
                            //       <input
                            //         type="text"
                            //         className="Info"
                            //         value={routingNumber}
                            //         onChange={this.handleRoutingNumberChange}
                            //       ></input>
                            //     </div>{" "}
                            //     &nbsp;
                            //     <select
                            //       onChange={
                            //         this.handleSelectedAccountTypeChange
                            //       }
                            //       className={accountTypeButtonStyle}
                            //       style={{ width: "33.33%" }}
                            //     >
                            //       <option selected value="Checking">
                            //         Checking
                            //       </option>
                            //       <option value="Savings">Savings</option>
                            //     </select>
                            //   </div>
                            // )
                            // : (
                            //   <div className="w-full">
                            //     <div style={{ display: "flex", width: "100%" }}>
                            //       <div
                            //         className="donor-field"
                            //         style={{ width: "50%" }}
                            //       >
                            //         <label
                            //           className={accountNumber && "filled"}
                            //           htmlFor={accountNumber}
                            //         >
                            //           {" "}
                            //           Account
                            //         </label>
                            //         <input
                            //           type="text"
                            //           className="Info"
                            //           value={accountNumber}
                            //           onChange={this.handleAccountNumberChange}
                            //         ></input>
                            //       </div>{" "}
                            //       &nbsp;
                            //       <div
                            //         className="donor-field"
                            //         style={{ width: "50%" }}
                            //       >
                            //         <label
                            //           className={routingNumber && "filled"}
                            //           htmlFor={routingNumber}
                            //         >
                            //           {" "}
                            //           Routing
                            //         </label>
                            //         <input
                            //           type="text"
                            //           className="Info"
                            //           value={routingNumber}
                            //           onChange={this.handleRoutingNumberChange}
                            //         ></input>
                            //       </div>{" "}
                            //     </div>
                            //     <div className="flex w-full">
                            //       <select
                            //         onClick={() => {
                            //           this.setState({
                            //             accountHolderTypeButtonStyle:
                            //               "donor-field-selected",
                            //           });
                            //         }}
                            //         onChange={
                            //           this.handleSelectedAccountHolderTypeChange
                            //         }
                            //         className={
                            //           this.state.accountHolderTypeButtonStyle
                            //         }
                            //         style={{ width: "50%" }}
                            //       >
                            //         <option selected value="individual">
                            //           Individual
                            //         </option>
                            //         <option value="company">Company</option>
                            //       </select>
                            //       &nbsp;
                            //       <select
                            //         onClick={() => {
                            //           this.setState({
                            //             accountTypeButtonStyle:
                            //               "donor-field-selected",
                            //           });
                            //         }}
                            //         onChange={
                            //           this.handleSelectedAccountTypeChange
                            //         }
                            //         className={accountTypeButtonStyle}
                            //         style={{ width: "50%" }}
                            //       >
                            //         <option selected value="Checking">
                            //           Checking
                            //         </option>
                            //         <option value="Savings">Savings</option>
                            //       </select>
                            //     </div>
                            //   </div>
                            // )
                          }
                        </div>

                        {
                          <div style={showPaypalPaymentFieldsVisability}>
                            <div className="fake-field-paypal">
                              <PaypalVenmo
                                statementDescriptor={statementDescriptor}
                                recurringDonationInCart={
                                  this.recurringDonationInCart
                                }
                                cart={cart}
                                schedulePaymentMap={schedulePaymentMap}
                                amount={amount}
                                amountDonated={total}
                                uuid={uuid}
                                coverFees={coverFees}
                                paypalMerchantID={paypalMerchantID}
                                merchantName={merchantName}
                                setPaypalInputs={this.setPaypalInputs}
                                paypalAuthorizationCode={
                                  paypalAuthorizationCode
                                }
                                paypalAccountConnected={paypalAccountConnected}
                                setPaypalAuthorizationCode={
                                  this.setPaypalAuthorizationCode
                                }
                                recurringFrequencyValue={frequenciesValue}
                                exitCheckoutForm={this.exitCheckoutForm}
                                setPage={setPage}
                                scheduledTotal={this.calculateScheduledDonationTotal()}
                                paypalAuthFailure={paypalAuthFailure}
                                paypalAuthFailureFunction={
                                  this.paypalAuthFailureFunction
                                }
                                reloadPaypal={reloadPaypal}
                              />
                            </div>
                          </div>
                        }

                        <div style={showFinixCardPaymentFieldsVisability}>
                          <div
                            style={{
                              display:
                                defaultPaymentProcessor === "FINIX"
                                  ? ""
                                  : "none",
                            }}
                            className="w-full"
                          >
                            {cardInputs}
                          </div>
                          <div
                            style={{
                              display:
                                defaultPaymentProcessor === "STRIPE"
                                  ? ""
                                  : "none",
                            }}
                            className="w-full"
                          >
                            {/* {stripePromise && ( */}
                            {this.state.stripePromise && (
                              <Elements
                                stripe={this.state.stripePromise}
                                // options={
                                //   this.state.accountID
                                //     ? { onBehalfOf: this.state.accountID }
                                //     : {}
                                // }
                                width="100%"
                              >
                                <StripeCheckout
                                  setErrorMessage={this.setErrorMessage}
                                  ref={this.stripeCardRef}
                                  defaultPaymentProcessor={
                                    defaultPaymentProcessor
                                  }
                                  processStripeCard={this.processStripeCard}
                                  stripeAccountID={stripeAccountID}
                                />
                                {/* <StripeACH
                                  ref={this.stripeACHRef}
                                  name={firstName + " " + lastName}
                                  accountNumber={accountNumber}
                                  routingNumber={routingNumber}
                                  accountHolderType={
                                    this.state.accountHolderType
                                  }
                                  setErrorMessage={this.setErrorMessage}
                                  accountType={accountType.toLowerCase()}
                                  processStripePayByBank={
                                    this.processStripePayByBank
                                  }
                                  stripeCustomerID={stripeCustomerID}
                                  stripeAccountID={stripeAccountID}
                                /> */}
                              </Elements>
                            )}

                            {/* )} */}
                          </div>
                        </div>

                        {/*  <div style={showFinixCardPaymentFieldsVisability}>
                          <div
                            className="cursor-pointer"
                            style={{
                              fontSize: '12px', // Smaller font size
                              marginTop: '-5px',
                              paddingTop: '3px',
                              color: '#b1b1b1',
                              width: "100%",
                              textAlign: 'left', // Align text to the left
                            }}
                          >
                            Card Information
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center', // Center inputs horizontally
                              width: "100%",
                            }}
                          >
                            {cardInputs}
                          </div> */}

                        {/*        <div class="flex items-center justify-center">
  <span class="relative inline-flex">
    {nextButton}

    <span class="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
    </span>
  </span>
</div> */}
                      </div>
                      {!(
                        // defaultPaymentProcessor === "STRIPE" &&
                        (
                          showPayByBankPaymentFields === true &&
                          page === "DONOR_INFO"
                        )
                      )
                        ? nextButton
                        : errorMessage && (
                            <div className="text-center text-red-500">
                              {errorMessage}
                            </div>
                          )}
                      {this.state.cart.length > 0 && (
                        <button
                          type="button"
                          className="SubmitButton"
                          style={{
                            top: "6px",
                            backgroundColor: "white",
                            inset: this.state.lightColor,
                            color: this.state.lightColor,
                            marginTop: "6px",
                            boxShadow: "none",
                            borderRadius: "6px",
                            border: "1px solid #b1b1b1",
                          }}
                          onClick={() => {
                            this.exitCheckoutForm(false, "EXIT");
                          }}
                        >
                          {" "}
                          select other items
                        </button>
                      )}
                    </div>
                  </form>
                  {/* </Elements> */}

                  {this.state.page === "CONFIRMATION" && (
                    <Confirmation
                      amount={amount}
                      scheduledTotal={
                        coverFees
                          ? this.calculateScheduledDonationTotal() +
                            scheduledFees
                          : this.calculateScheduledDonationTotal()
                      }
                      donationData={this.state.donationData}
                      email={email}
                      merchantName={merchantName}
                      exitCheckoutForm={this.exitCheckoutForm}
                      setPage={setPage}
                      amountDonated={total}
                      statementDescriptor={statementDescriptor}
                      companyName={this.state.company.companyName}
                      companyID={this.state.company.companyID}
                      freeTicket={freeTicket}
                      doubleTheDonationPublicAPIKey={
                        this.state.doubleTheDonationPublicAPIKey
                      }
                      elementID={this.state.elementID}
                      hostedPage={this.state.hostedPage}
                    />
                  )}
                  {this.state.page === "GLOBAL_CUSTOM_INPUTS" && (
                    <div className="w-full max-w-full text-left">
                      <GlobalCustomInputs
                        customInputsValues={this.state.globalCustomInputs}
                        setCustomInputsValues={this.setGlobalCustomInputs}
                        setPage={setPage}
                        handleNext={() => {
                          // set the page to donor info
                          this.setState({ page: "DONOR_INFO" });
                        }}
                        primaryColor={lightColor}
                        hostedPage={hostedPage}
                        cart={this.state.cart}
                        exitCheckoutForm={this.exitCheckoutForm}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="hidden self-center justify-self-end sm:flex"
                  onClick={() =>
                    window.open("https://charitystack.com", "_blank")
                  }
                >
                  <p className="charitystack">powered by</p>
                  <p className="charity">charity</p>
                  <p className="stack">stack</p>
                </div>
              </div>
              {/* faqs and logo for small/mobile screens */}
              <div
                className={`block px-5 sm:hidden ${
                  this.state.faqs.length > 0 ? "bg-gray-100" : "bg-white"
                }`}
              >
                <div className="block sm:hidden">
                  <Faqs faqs={this.state.faqs} />
                </div>
                <div className="flex grow sm:hidden"></div>{" "}
                {/* this is a spacer to push logo to the bottom */}
                <div
                  className="mb-4 mt-6 flex py-2 sm:hidden"
                  style={{
                    justifyContent: "center",
                    alignItems: "baseline",
                    paddingBottom: "12px",
                  }}
                  onClick={() =>
                    window.open("https://charitystack.com", "_blank")
                  }
                >
                  <p className="charitystack">powered by</p>
                  <p className="charity">charity</p>
                  <p className="stack">stack</p>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default DonorInfo;
