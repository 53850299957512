import React, { useState } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import { set } from "date-fns";

function StripeFinancialConnections({
  stripeClientSecret,
  setErrorMessage,
  setLoad,
  stripeAccountID,
  stripeCustomerID,
  setStripeClientSecret,
  processStripePayByBank,
  name,
  email,
  phoneNumber,
  goToPayment,
}) {
  const stripe = useStripe();
  const [ready, setReady] = useState(true);

  const refetchSession = async () => {
    setReady(false);
    const url = `${process.env.REACT_APP_STRIPE_AUXILIARY_URL}/stripe/financial-connections-token`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        stripeAccountID: stripeAccountID,
        customerID: stripeCustomerID,
      }),
    });
    const data = await response.json();
    console.log("DATAAAA", data);
    setStripeClientSecret(data.clientSecret, setReady(true));
  };

  const fetchPaymentMethod = async (paymentMethodID) => {
    // add paymentMethodID as query param, and add stripeAccountID as query param for GET request
    const url = `${process.env.REACT_APP_STRIPE_AUXILIARY_URL}/stripe/payment-method`;
    const response = await fetch(
      `${url}?paymentMethodID=${paymentMethodID}&stripeAccountID=${stripeAccountID}&customerID=${stripeCustomerID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const paymentMethod = await response.json();
    return paymentMethod;
  };

  const openModal = async () => {
    const passedErrorCheck = goToPayment("FINANCIAL_CONNECTIONS");
    if (!passedErrorCheck) {
      return;
    }

    setLoad(true);
    while (!stripe && !ready) {
      continue;
    }

    const result = await stripe.collectBankAccountForSetup({
      clientSecret: stripeClientSecret,
      params: {
        payment_method_type: "us_bank_account",
        payment_method_data: {
          billing_details: {
            name: name,
            email: email,
          },
        },
      },
    });
    console.log("financialConnectionsSession", result);
    const setupIntent = result.setupIntent;

    if (result.error) {
      console.error(result.error);
      setErrorMessage("Invalid bank account information");
      refetchSession();
      setLoad(false);
      return;
    }
    const paymentMethodID = setupIntent.payment_method;
    if (!paymentMethodID) {
      setErrorMessage("No bank account information provided");
      refetchSession();
      setLoad(false);
      return;
    }
    const paymentMethod = await fetchPaymentMethod(paymentMethodID);
    processStripePayByBank(paymentMethod);
    // console.log("paymentMethod", paymentMethod);
    // setLoad(false);

    // grab the payment method id from the setup intent

    // create a payment method using the id of the payment method created
    // const { error: errorPaymentMethod, paymentMethod } =
    //   await stripe.createPaymentMethod({
    //     type: "us_bank_account",
    //     us_bank_account: {
    //       financial_connections_account:
    //     },
    //     billing_details: {
    //       name: name,
    //       email: email,
    //       phone: phoneNumber ? phoneNumber : null,
    //     },
    //   });
    // if (errorPaymentMethod) {
    //   console.error(errorPaymentMethod);
    //   setErrorMessage("Invalid bank account information");
    //   refetchSession();
    //   setLoad(false);
    //   return;
    // }
    // console.log("paymentMethod", paymentMethod);

    // setLoad(false);
    // const accounts = financialConnectionsSession.accounts;
    // const account = accounts[0]; // Assuming you want to use the first account

    // // Use the account ID from the financial connections session to create a payment method
    // const { error: errorPaymentMethod, paymentMethod } =
    //   await stripe.createPaymentMethod({
    //     type: "us_bank_account", // Changed from 'financial_connections'
    //     us_bank_account: {
    //       financial_connections_account: account.id,
    //     },
    //     billing_details: {
    //       name: name,
    //       email: email,
    //       phone: phoneNumber ? phoneNumber : null,
    //     },
    //   });
    // if (errorPaymentMethod) {
    //   console.error(errorPaymentMethod);
    //   setErrorMessage("Invalid bank account information");
    //   setLoad(false);
    //   return;
    // }
    // console.log("paymentMethod", paymentMethod);
    // processStripePayByBank(paymentMethod);

    // You can now use the payment method in your server-side code
  };

  return (
    <div
      onClick={openModal}
      style={{
        color: "#FFFFFF",
        background: "#000000",
        width: "100%",
        cursor: "pointer",
        borderRadius: "5px",
        border: "none",
        height: "45px",
        fontSize: "22px",
        fontWeight: 560,
        paddingTop: "5px",
      }}
      className="text-center"
    >
      Instant Bank Connection
      {/* <PlaidLink
                token={tokenHolder}
                onSuccess={onSuccess}
                style={{ color: "#FFFFFF", background: "#000000", width: "100%", cursor: "pointer", borderRadius: "5px", border: "none", height: "45px", fontSize: "22px", fontWeight: 560 }}
                onExit={onExit}
                onEvent={onEvent}
                className="PayByBankButton"
            >
                Instant Bank Connection
            </PlaidLink> */}
    </div>
  );
}

export default StripeFinancialConnections;
