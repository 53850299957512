import React, { useState, useEffect } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';


function Faqs(props) {
    const { faqs } = props
    const [showFAQs, setShowFAQs] = useState([])

    useEffect(() => {
        // store false for every showFAQ
        let showFAQs = []
        faqs.forEach((faq, index) => {
            showFAQs.push(false)
        })
        setShowFAQs(showFAQs)
    }, [faqs])
    return (
        <>
            {
                faqs.length > 0 &&
                <div className='text-left'>
                    <div className="text-sm font-normal pt-5 sm:pt-12">
                        FAQs
                    </div>
                    <div className="border-b-[1px] w-[120px] border-[#cbc7c7] mt-1"></div>
                    <div className="divide-y-[1px] divide-[#cbc7c7]">
                        {
                            faqs.map((faq, index) => {
                                return (
                                    <div className='AdditionalDetailsFields flex flex-col items-start'>
                                        <div className="flex justify-between w-full">
                                            <span>
                                                {faq.question}
                                            </span>
                                            {/* <button>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
</button> */}
                                            {
                                                showFAQs[index] == false ?
                                                    <ChevronDownIcon className="w-6 h-6 cursor-pointer" onClick={(e) => {
                                                        let showFAQsCopy = [...showFAQs]
                                                        showFAQsCopy[index] = true
                                                        setShowFAQs(showFAQsCopy)
                                                    }} />
                                                    :
                                                    <ChevronUpIcon className="w-6 h-6 cursor-pointer" onClick={(e) => {
                                                        let showFAQsCopy = [...showFAQs]
                                                        showFAQsCopy[index] = false
                                                        setShowFAQs(showFAQsCopy)
                                                    }} />
                                            }
                                        </div>
                                        {
                                            showFAQs[index] == true &&
                                            <div className="flex w-full py-2 text-gray-400">
                                                {faq.answer}
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Faqs