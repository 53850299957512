import React, {
  useCallback,
  useState,
  FunctionComponent,
  useEffect,
  useRef,
} from "react";
import {
  PlaidLink,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  usePlaidLink,
} from "react-plaid-link";
import Confirmation from "./Confirmation";

const PayByBank: FunctionComponent = (props: any) => {
  const [token, setToken] = useState<string | null>(null);

  // const [email, setEmail] = useState(props.email)
  // const [name, setName] = useState(props.name)
  // const [uuid, setUUID] = useState(props.uuid)
  // const [merchantID, setMerchantID] = useState(props.merchantID)
  // const [total, setTotal] = useState(props.total)
  // const [coverFees, setCoverFees] = useState(props.coverFees)
  // const [optIn, setOptIn] = useState(props.optIn)
  // const [merchantName, setMerchantName] = useState(props.merchantName)
  // const [sponsee, setSponsee] = useState(props.sponsee)

  // generate a link_token
  useEffect(() => {
    async function createLinkToken() {
      const url: string =
        process.env.REACT_APP_PLAID_CREATE_LINK_TOKEN_URL ?? "";
      let response = await fetch(url, {
        method: "POST",
      });
      const token = await response.json();
      console.log("token is", token);
      setToken(token);
    }
    createLinkToken();
  }, []);

  // useEffect(() => {con})
  // useEffect(() => { setEmail(props.email); console.log("email in props is", props.email)}, [props.email]);
  // useEffect(() => { setName(props.name) }, [props.name]);
  // useEffect(() => { setUUID(props.uuid) }, [props.uuid]);
  // useEffect(() => { setMerchantID(props.merchantID) }, [props.merchantID]);
  // useEffect(() => { setTotal(props.total) }, [props.total]);
  // useEffect(() => { setCoverFees(props.coverFees) }, [props.coverFees]);
  // useEffect(() => { setOptIn(props.optIn) }, [props.optIn]);
  // useEffect(() => { setMerchantName(props.merchantName) }, [props.merchantName]);
  // useEffect(() => { setSponsee(props.sponsee) }, [props.sponsee]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token, metadata) => {
      const accountID = metadata.accounts[0]["id"];
      const accountType = metadata.accounts[0]["subtype"];
      //            makePlaidPayment = async (flag, accountType, public_token, accountID, routingNumber, accountNumber, ) => {

      props.makePlaidPayment(
        "plaid",
        accountType,
        public_token,
        accountID,
        0,
        0
      );
    },
    []
  );

  const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    // console.log(eventName, metadata);
    if (eventName == "OPEN") {
      props.setLoad(true);
    }
  }, []);

  const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    // console.log(error, metadata);
    props.setLoad(false);
  }, []);

  const { open, ready, error, exit } = usePlaidLink({
    token,
    onSuccess,
    onEvent,
    onExit,
  });

  const openModal = () => {
    if (props.goToPayment("PLAID")) {
      open();
    }
  };

  // The pre-built PlaidLink component uses the usePlaidLink hook under the hood.
  // It renders a styled button element and accepts a `className` and/or `style` prop
  // to override the default styles. It accepts any Link config option as a prop such
  // as receivedRedirectUri, onEvent, onExit, onLoad, etc.
  return token === null ? (
    // insert your loading animation here
    <div className="loader"></div>
  ) : (
    <div
      onClick={openModal}
      style={{
        color: "#FFFFFF",
        background: "#000000",
        width: "100%",
        cursor: "pointer",
        borderRadius: "5px",
        border: "none",
        height: "45px",
        fontSize: "22px",
        fontWeight: 560,
        paddingTop: "5px",
      }}
      className="text-center"
    >
      Instant Bank Connection
      {/* <PlaidLink
                token={tokenHolder}
                onSuccess={onSuccess}
                style={{ color: "#FFFFFF", background: "#000000", width: "100%", cursor: "pointer", borderRadius: "5px", border: "none", height: "45px", fontSize: "22px", fontWeight: 560 }}
                onExit={onExit}
                onEvent={onEvent}
                className="PayByBankButton"
            >
                Instant Bank Connection
            </PlaidLink> */}
    </div>
  );
};

export default PayByBank;
