import React, { useState, useEffect } from "react";
import { DatePicker, Flex, TimePicker } from "antd";
import "../Styling/styles.css";
import dayjs from "dayjs";
import {
  CloseOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { da } from "date-fns/locale";
const { RangePicker } = DatePicker;
const RecurringDatePicker = (props) => {
  const [localStartValue, setLocalStartValue] = useState(undefined);
  const [localEndValue, setLocalEndValue] = useState(undefined);
  const [localTimeValue, setLocalTimeValue] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [suffixIcon, setSuffixIcon] = useState(
    <CalendarOutlined style={{ fontSize: "150%" }} />
  );
  const dateFormat = "MM/DD/YYYY";

  useEffect(() => {
    console.log("props for recurring date picker", props);
  }, [props.showComponent]);

  const resetEndValue = () => {
    let startingPosition = dayjs(localStartValue).startOf("day");
    const endingPosition = dayjs(localEndValue).startOf("day");

    if (localEndValue !== null && localEndValue !== undefined) {
      let newEndValue = endingPosition;

      if (
        props.timePicker === "year" &&
        endingPosition.month() !== startingPosition.month()
      ) {
        // set newEndValue to match the month of the start position
        newEndValue = newEndValue.month(startingPosition.month());
      }

      if (props.timePicker === "week") {
        let dayOfWeek = localEndValue.day();
        let dayOfWeekStart = dayjs().day();
        if (localStartValue !== null && localStartValue !== undefined) {
          dayOfWeekStart = dayjs(localStartValue).day();
        }
        let difference = dayOfWeekStart - dayOfWeek;
        newEndValue = dayjs(localEndValue).add(difference, "day");
      }
      if (
        (props.timePicker === "month" || props.timePicker === "year") &&
        localStartValue !== null &&
        localStartValue !== undefined
      ) {
        // make endValue use same day in month value as startValue
        // if the endValue month does not have that day, set endValue to be the last day of the month

        let dayOfMonth = startingPosition.date();
        let endOfMonth = newEndValue.endOf("month").date();
        if (dayOfMonth > endOfMonth) {
          dayOfMonth = endOfMonth;
        }
        newEndValue = newEndValue.date(dayOfMonth);
      }
      console.log("newEndValue!!!", newEndValue);
      if (
        newEndValue.isBefore(startingPosition) ||
        newEndValue.isSame(startingPosition)
      ) {
        newEndValue = dayjs(startingPosition);
        if (props.timePicker === "day") {
          newEndValue = newEndValue.add(1, "day");
        }
        if (props.timePicker === "week") {
          newEndValue = newEndValue.add(1, "week");
        }
        if (props.timePicker === "month") {
          console.log("newEndValue", newEndValue);
          newEndValue = newEndValue.add(1, "month");
        }
        if (props.timePicker === "year") {
          newEndValue = newEndValue.add(1, "year");
        }
      }

      // only update if the new endValue is different from the old endValue
      if (
        newEndValue.format("YYYY-MM-DD") !== endingPosition.format("YYYY-MM-DD")
      ) {
        setLocalEndValue(newEndValue);
      }
    }
  };

  const handleCancelButton = () => {
    const key = props.fundKey;
    props.calculateTotalSubscriptionCharge(key, undefined, undefined);
    // reset startValue, endValue, and timeValue
    props.setStartValue(key, undefined);
    props.setEndValue(key, undefined);
    props.setTimeValue(key, undefined);
    props.setShowComponent(key, false);
  };

  const handleApplyButton = () => {
    // update endvalue and startValue
    const key = props.fundKey;
    props.calculateTotalSubscriptionCharge(key, localStartValue, localEndValue);
    console.log("localStartValue", localStartValue);
    console.log(typeof localStartValue);
    props.setStartValue(key, localStartValue);
    props.setEndValue(key, localEndValue);
    props.setTimeValue(key, localTimeValue);
    props.setShowComponent(key, false);
  };

  useEffect(() => {
    if (props.showComponent === true) {
      setLocalStartValue(props.startValue);
      setLocalEndValue(props.endValue);
      setLocalTimeValue(props.timeValue);
    }
  }, [props.showComponent]);

  useEffect(() => {
    resetEndValue();
    console.log("localEndValue", localEndValue);
    props.calculateTotalSubscriptionCharge(
      props.fundKey,
      localStartValue,
      localEndValue
    );
  }, [localEndValue]);

  useEffect(() => {
    console.log("startValue", localStartValue);
    // check if localStartValue is today. If so, set it to undefined
    if (localStartValue !== undefined) {
      let today = dayjs().startOf("day");
      let startValueDate = dayjs(localStartValue).startOf("day");
      if (startValueDate.isSame(today)) {
        setLocalStartValue(undefined);
      }
    }

    resetEndValue();
  }, [localStartValue]);

  // const setEndDateToCorrectDayInWeek = (date) => {
  //   let dayOfWeek = date.day();
  //   let dayOfWeekStart = dayjs().day();
  //   if (localStartValue !== null && localStartValue !== undefined) {
  //     dayOfWeekStart = dayjs(localStartValue).day();
  //   }
  //   let difference = dayOfWeekStart - dayOfWeek;
  //   setLocalEndValue(dayjs(date).add(difference, "day"));
  // };

  const disabledTime = (current) => {
    return {
      disabledHours: () => {
        const hours = [];
        // if startValue is undefined or null, disable hours before the current hour
        if (localStartValue === null || localStartValue === undefined) {
          const currentHour = dayjs().hour();

          for (let i = currentHour + 1; i <= 24; i++) {
            hours.push(i);
          }

          return hours;
        }
      },
      disabledMinutes: (selectedHour) => {
        const minutes = [];
        if (localStartValue === null || localStartValue === undefined) {
          const currentMinute = dayjs().minute();
          if (selectedHour === dayjs().hour()) {
            for (let i = currentMinute; i <= 60; i++) {
              minutes.push(i);
            }
          }
        }
        return minutes;
      },
    };
  };

  const disabledStartDate = (current) => {
    return current && current < dayjs();
  };

  const disabledEndDate = (current) => {
    // Allow only dates one month into the future and after in relation to start date
    // console.log("current", current);
    let startingPosition = dayjs();
    if (localStartValue !== undefined && localStartValue !== null) {
      startingPosition = dayjs(localStartValue);
    }

    // let dayOfWeek = dayjs().day();
    // if (localEndValue !== null && localEndValue !== undefined) {
    //   dayOfWeek = dayjs(localEndValue).day();
    // }

    if (props.timePicker === "month") {
      return current < dayjs(startingPosition);
    } else if (props.timePicker === "week") {
      return current < dayjs(startingPosition);
    } else if (props.timePicker === "day") {
      return current <= dayjs(startingPosition);
    } else if (props.timePicker === "year") {
      return current < dayjs(startingPosition);
    }
  };

  if (true) {
    return (
      <div
        className="flex w-full items-center bg-white"
        style={{ borderRadius: "6px" }}
      >
        <div
          className="w-full items-center bg-white"
          style={{ borderRadius: "6px" }}
        >
          <div className="flex h-8 w-full items-center">
            <div className="flex w-1/2 items-center">
              <CalendarOutlined className="mx-2 h-5 w-5" />
              <DatePicker
                key={localEndValue}
                placeholder={"Today"}
                style={{
                  border: 0,
                  // marginLeft: "15px",
                  padding: "4px 0px 4px",
                  flexGrow: 2,
                  boxShadow: "0 0 0 0",
                }}
                suffixIcon={null}
                allowClear={false}
                format={dateFormat}
                disabledDate={disabledStartDate}
                onChange={(date) => {
                  setLocalStartValue(dayjs(date));
                }}
                value={localStartValue}
                showNow={false}
                inputReadOnly={true}
              />
            </div>
            <ArrowRightIcon className="h-5 w-5" />
            <div className="flex h-8 w-1/2 items-center justify-between">
              <DatePicker
                key={localStartValue}
                placeholder={"Everlasting"}
                style={{
                  border: 0,
                  flexGrow: 1,
                  marginLeft: "15px",
                  padding: "4px 0px 4px",
                  boxShadow: "0 0 0 0",
                }}
                picker={props.timePicker == "day" ? "date" : props.timePicker}
                suffixIcon={null}
                allowClear={false}
                format={dateFormat}
                disabledDate={disabledEndDate}
                onChange={(date) => {
                  console.log("dateeeee", date);
                  setLocalEndValue(dayjs(date));
                }}
                value={localEndValue}
                showNow={false}
                inputReadOnly={true}
              />
            </div>
          </div>
          <div
            className="flex h-8 w-full items-center justify-between"
            style={{ borderRadius: "6px" }}
          >
            <ClockCircleOutlined className="mx-2 h-5 w-5" />
            <TimePicker
              use12Hours
              changeOnScroll
              needConfirm={false}
              format="h:mm a"
              placeholder="Now"
              onChange={(time) => {
                console.log("timeee", time);

                setLocalTimeValue(time);
              }}
              onCalendarChange={(time) => {
                console.log("timeee", time);

                setLocalTimeValue(time);
              }}
              // disabledTime={disabledTime}
              className="flex h-full w-1/2 items-center justify-between"
              style={{
                border: 0,
                flexGrow: 2,
                // marginLeft: "15px",
                padding: "4px 0px 4px",
                boxShadow: "0 0 0 0",
              }}
              suffixIcon={null}
              allowClear={false}
              value={localTimeValue}
              showNow={false}
              inputReadOnly={true}
            />
          </div>
        </div>
        <div className="flex h-full flex-col justify-center divide-y-2 divide-gray-200 border-l-2 border-gray-200">
          <div
            className="flex h-1/2 cursor-pointer items-center justify-center px-4 font-normal text-highlight-blue first-letter:text-start"
            onClick={handleApplyButton}
          >
            <div className="text-center">Apply</div>
          </div>
          <div
            className="flex h-1/2 cursor-pointer items-center justify-center px-4 font-normal text-red-400"
            onClick={handleCancelButton}
          >
            <div className="text-center">Cancel</div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <RangePicker
      //center the popstyle prop
      // placement="bottomRight"
      // popupStyle={{position:"absolute", left:"45.45%"}}
      format={dateFormat}
      disabledDate={disabledEndDate}
      picker={props.timePicker}
      // open={isOpen}
      onOpenChange={(status) => {
        // setIsOpen(status)
        if (status) {
          setLocalEndValue(undefined);
        }
        // console.log('endvalue', localEndValue)
      }}
      onChange={(datesArr) => {
        // console.log('datesArr', datesArr)
        setLocalEndValue(datesArr[1]);
        if (datesArr[1] == null) {
          setSuffixIcon(<CalendarOutlined style={{ fontSize: "20px" }} />);
        } else {
          setSuffixIcon(<CloseOutlined style={{ fontSize: "20px" }} />);
        }
        // setIsOpen(false)
      }}
      clearIcon={
        // you can use every element here
        <CloseOutlined style={{ fontSize: "20px" }} />
      }
      suffixIcon={suffixIcon}
      allowClear={true}
      disabled={[true, false]}
      value={[localStartValue, localEndValue]}
      inputReadOnly={true}
      style={{
        width: "100%",
        height: "42px",
        "border-width": "1px",
        "border-color":
          props.lightColor != undefined ? props.lightColor : "#000000",
        "border-style": "solid",
        borderRadius: "6px",
      }}
    />
  );
};
export default RecurringDatePicker;
