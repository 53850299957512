import React from 'react';
import ContentLoader from 'react-content-loader';

const CustomShimmer = () => {
  return (
    <div style={{ width: '100%' }}>
      <ContentLoader
        // viewBox="0 0 400 200"
        width={'100%'}
        height={'500px'}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="15" y="15" rx="4" ry="4" width="100%" height="85" />

        <rect x="15" y="123" rx="2" ry="2" width="100%" height="42" />

        <rect x="15" y="173" rx="2" ry="2" width="49%" height="42" />
        <rect x="calc(50% + 15px)" y="173" rx="2" ry="2" width="49%" height="42" />

        <rect x="15" y="223" rx="2" ry="2" width="100%" height="42" />
        <rect x="15" y="273" rx="2" ry="2" width="100%" height="42" />

        <rect x="15" y="373" rx="2" ry="2" width="32.3%" height="42" />
        <rect x="calc(33.3% + 15px)" y="373" rx="2" ry="2" width="32.3%" height="42" />
        <rect x="calc(66.6% + 15px)" y="373" rx="2" ry="2" width="32.3%" height="42" />

        <rect x="15" y="423" rx="2" ry="2" width="100%" height="42" />
      </ContentLoader>
    </div>
  );
};

export default CustomShimmer;
