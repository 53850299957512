import React, { useEffect, useState } from "react";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";
import "../Styling/doublethedonation.css";
import { CloseOutlined } from '@ant-design/icons';

function DoubleTheDonation(props) {
  const [selectedValue, setSelectedValue] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [companies, setCompanies] = useState({});
  const [options, setOptions] = useState([]);
  const [displayedValue, setDisplayedValue] = useState("");

  useEffect(() => {
    const onLoad = async () => {
    // create a query parameter called company_name_prefix using the value currentValue
    const response = await fetch(`https://doublethedonation.com/api/360matchpro-partners/v1/search_by_company_prefix?company_name_prefix=${currentValue}`)
    const data = await response.json()
    // console.log(data)
    // create an object using company_name and id
    const companies = {}
    // for every company in data, add a key value pair to the companies object
    data.forEach(company => {
      companies[company.company_name] = company.id
    })

    setCompanies(companies)
    setOptions(Object.keys(companies))
    // console.log(Object.keys(companies))

  }

  onLoad()
}, [currentValue])


const handleOnChange = (value) => {
  setCurrentValue(value)
};

// create a function to handle the select event
const handleSelect = (value) => {
  setSelectedValue(value)
  if (value.length > 35) {
    // if the value is greater than 35 characters, set the displayedValue to the first 35 characters plus "..."
    setDisplayedValue(value.substring(0, 35) + "...")
  }
  else {
    setDisplayedValue(value)
  }
  props.setCompany({ 'companyName': value, 'companyID': companies[value] })
};

const handleClear = () => {
  setSelectedValue("");
  setCurrentValue("");
  props.setCompany({ 'companyName': '', 'companyID': '' })
}

return (
  <>
    {
      selectedValue != "" ?
        <div className="donor-field" style={{ paddingLeft: '7px', fontFamily: 'inherit' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', fontFamily: 'inherit' }}>
            <div className="selectedCompany">{displayedValue}</div>
            <CloseOutlined className="closeButton" onClick={handleClear} />
          </div>
        </div>
        :
        <div style={{ paddingBottom: '8px' }} className="w-full">
          <Combobox
            hideCaret
            hideEmptyPopup
            data={options}
            filter={false}
            placeholder="Search for your company"
            onSelect={handleSelect}
            value={currentValue}
            onChange={handleOnChange}
            style={{ width: '100%', height: '42px', fontSize: '13px', color: '#b1b1b1', display: 'flex', justifyContent: 'space-between', border: '1px solid #b1b1b1', borderRadius: '6px', fontFamily: 'inherit' }}
          />
        </div>
    }
  </>
);
}

export default DoubleTheDonation;
