import React, { Component, useState, useEffect, useRef } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// Renders errors or successful transactions on the screen.
function Message({ content, style, isSuccess }) {
  const messageHasContent = content !== "";

  // return (
  //   <div style={style} className={`flex items-center pt-1 pb-2 text-sm justify-center	${messageHasContent ? "animate-fadeIn" : ""}`}>
  //     {messageHasContent && (
  //       <div className="px-1 scale-75">
  //         <SuccessCheckmark />
  //       </div>
  //     )}
  //     <p className={messageHasContent ? "transition-opacity duration-700 opacity-0 animate-fadeIn antialiased tracking-normal font-light	" : ""}>
  //       {content}
  //     </p>
  //   </div>
  // );
}

function SuccessCheckmark() {
  // Ensure your SVG markup is correctly returned from the function.
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
      style={{ width: "22px", display: "block" }}
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#73AF55"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
}

export const PaypalVenmo = (props) => {
  const [isPaypalReady, setIsPaypalReady] = useState(false);

  useEffect(() => {
  //  console.log(
   //   "PayPal SDK Loaded:",
  //    typeof window.paypal !== "undefined",
   //   window.paypal
  //  );
  }, []);

  useEffect(() => {
    if (window.paypal && window.paypal.Buttons) {
      setIsPaypalReady(true);
     // console.log("Paypal is ready");
    } else {
   //   console.log("Paypal is not yet ready");

      const interval = setInterval(() => {
        if (window.paypal && window.paypal.Buttons) {
          setIsPaypalReady(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, []);

  const [donationAmount, setDonationAmount] = useState(props.amountDonated);
  const [paypalBody, setPaypalBody] = useState({});
  const [initialOptions, setInitialOptions] = useState({});
  const [messageStyling, setMessageStyling] = useState({});
  const [message, setMessage] = useState("");
  const successStyle = { color: "green", fontWeight: "normal" };
  const errorStyle = { color: "red", fontWeight: "normal" };
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [paypalScriptKey, setPaypalScriptKey] = useState("");
  const paypalBodyRef = useRef({});

  //let paypalScriptKey = JSON.stringify(initialOptions);

  useEffect(() => {
    // This effect will run whenever props.amountDonated changes.
    setDonationAmount(props.amountDonated);
   // console.log("props.amountDonated is changed: ", props.amountDonated);
    let tempValue = {
      cart: {
        items: [
          {
            id: props.uuid.toString(),
            quantity: 1,
            price: props.amountDonated,
            merchantID: props.paypalMerchantID,
            statementDescriptor: props.statementDescriptor,
            merchantName: props.merchantName,
            feeCovered: props.coverFees,
            frequency: "ONE_TIME", // TODO: Use props.recurringFrequencyValue here
          },
        ],
      },
    };

    setPaypalBody(tempValue); // You're already doing this
    paypalBodyRef.current = tempValue; // Also update the ref
   // console.log("Set Paypal Body: ", paypalBody);
  }, [props.amountDonated, props.uuid, props.coverFees]); // Watching for changes in amountDonated

  useEffect(() => {
    // This effect will run whenever props.amountDonated changes.
    console.log(
      "recurringFrequencyValue is changed, or cart changed",
      props.recurringFrequencyValue,
      props.cart
    );
    let tempOptions = {};

    if (
      (props.recurringFrequencyValue === "One-Time" &&
        props.cart.length === 0) ||
      (props.recurringDonationInCart() === false && props.cart.length > 0)
    ) {
      tempOptions = {
        "enable-funding": "venmo",
        "disable-funding": "paylater,card",
        "data-sdk-integration-source": "integrationbuilder_sc",
        intent: "capture",
        components: "buttons",
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "data-partner-attribution-id":
          process.env.REACT_APP_PAYPAL_PARTNER_ATTRIBUTION_ID,

        //  "merchant-id": props.paypalMerchantID,
      };
      //console.log("enabling venmo");
      setPaypalScriptKey(JSON.stringify(tempOptions));
      setInitialOptions(tempOptions);
    }

    if (
      (props.recurringFrequencyValue !== "One-Time" &&
        props.cart.length === 0) ||
      (props.recurringDonationInCart() === true && props.cart.length > 0)
    ) {
      tempOptions = {
        "disable-funding": "paylater,card,venmo",
        "data-sdk-integration-source": "integrationbuilder_sc",
        intent: "authorize",
        components: "buttons",
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        "data-partner-attribution-id":
          process.env.REACT_APP_PAYPAL_PARTNER_ATTRIBUTION_ID,
        //  "merchant-id": props.paypalMerchantID,
      };
    }
    setInitialOptions(tempOptions);
    setPaypalScriptKey(JSON.stringify(tempOptions));
  }, [props.recurringFrequencyValue, props.cart]);

  useEffect(() => {
   // console.log("Paypal Body updated ?: ", paypalBody);
    // Perform any action that depends on the updated state here
  }, [paypalBody]); // This useEffect runs whenever paypalBody changes

  useEffect(() => {
    //console.log("Paypal Reload Situation: ", props.reloadPaypal);
    setMessage("");
    props.setPaypalAuthorizationCode("");
  }, [props.reloadPaypal, props.coverFees, props.amountDonated]); // This useEffect runs whenever paypalBody changes

  //console.log("intialOptions config: ", initialOptions)

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions} key={paypalScriptKey}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            size: "responsive",
          }}
          createOrder={async () => {
            try {
              const response = await fetch(
                process.env.REACT_APP_PAYPAL_ORDER_API + "/create-order",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(paypalBodyRef.current), // Use the ref here
                }
              );

              const orderData = await response.json();
              console.log("orderData", orderData);

              if (orderData.id) {
                return orderData.id;
              } else {
                const errorDetail = orderData?.details?.[0];
                const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : JSON.stringify(orderData);
                //     console.log("in else", errorMessage)
                if (errorMessage.includes("Account Error from PayPal")) {
                  //       console.log("hello")
                  //    props.paypalAuthFailure(true)
                  props.paypalAuthFailureFunction("PayPal Connection Error");
                } else {
                  throw new Error(errorMessage);
                }
              }
            } catch (error) {
              console.log("in catch");

              console.error(error);
              //  setMessage(`Could not initiate PayPal Checkout...${error}`);
              //  setMessageStyling(errorStyle);
            }
          }}
          onError={async (data, actions) => {
            console.log("theres an error");
            //   props.paypalAuthFailure(true)
            props.paypalAuthFailureFunction("PayPal Connection Error");
            console.log("set paypal auth failure to true");
          }}
          onApprove={async (data, actions) => {
            console.log("onApprove data", data);
            console.log("onApprove actions", actions);
            const orderID = data.orderID;

            // send orderID as get parameter to the server. Ensure it is encoded properly
            const response = await fetch(
              process.env.REACT_APP_PAYPAL_ORDER_API +
                "/order-details" +
                "?orderID=" +
                orderID,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const orderData = await response.json();
            console.log("orderData", orderData);

            const payer = orderData.payer;
            console.log("payer", payer);

            props.setPaypalInputs(
              payer.email_address,
              payer.name.given_name,
              payer.name.surname
            );
            props.setPaypalAuthorizationCode(orderID);
            // try {
            //   console.log("Starting onApprove for authorization");

            //   // Perform the payment authorization
            //   const authorization = await actions.order.authorize();
            //   console.log(
            //     "Authorization completed successfully",
            //     authorization
            //   );
            //   props.setPaypalInputs(
            //     authorization.payer.email_address,
            //     authorization.payer.name.given_name,
            //     authorization.payer.name.surname
            //   );
            //   //     props.setPaypalAuthorizationCode(authorization.id)
            //   if (
            //     authorization.purchase_units &&
            //     authorization.purchase_units.length > 0
            //   ) {
            //     const purchaseUnit = authorization.purchase_units[0];
            //     if (
            //       purchaseUnit.payments &&
            //       purchaseUnit.payments.authorizations &&
            //       purchaseUnit.payments.authorizations.length > 0
            //     ) {
            //       const authorizationId =
            //         purchaseUnit.payments.authorizations[0].id;
            //       console.log("Authorization ID:", authorizationId);

            //       // Use the authorization ID as needed, for example, set it in your component's state or props
            //       props.setPaypalAuthorizationCode(authorizationId);
            //     }
            //   }

            //   // At this point, the transaction has been authorized but not captured.
            //   // You can remove or comment out the capture logic if not needed.

            //   // Check for the authorization result and handle accordingly

            //   const errorDetail = authorization?.details?.[0];
            //   console.log("errorDetail: ", errorDetail);
            //   // https://developer.paypal.com/api/rest/reference/orders/v2/errors/
            //   if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
            //     setMessageStyling(errorStyle);

            //     // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
            //     return actions.restart();
            //   } else if (errorDetail) {
            //     setMessageStyling(errorStyle);

            //     // (2) Other non-recoverable errors -> Show a failure message
            //     throw new Error(
            //       `${errorDetail.description} (${authorization.debug_id})`
            //     );
            //   } else {
            //     // (3) Successful transaction -> Show confirmation or thank you message
            //     setMessage(`Account connected. Pay below.`);
            //     setMessageStyling(successStyle);
            //     setIsSuccessMessage(true);

            //     console.log(
            //       "Authorization result",
            //       authorization,
            //       JSON.stringify(authorization, null, 2)
            //     );
            //   }
            // } catch (error) {
            //   console.error(error);
            //   /*   setMessage(
            //       `Error connecting to Paypal`
            //     ); */
            //   setMessageStyling(errorStyle);
            // }
          }}
        />
      </PayPalScriptProvider>
      <Message
        content={message}
        style={messageStyling}
        isSuccess={isSuccessMessage}
      />
    </div>
  );
};

export default PaypalVenmo;
