// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://www.stripe.com/docs/payments/integration-builder

import React, { Component } from "react";
// import "./Styling/styles.css";
import DonorInfo from "./Components/DonorInfo";

<script src="https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js"></script>;
export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: (
        <DonorInfo
          setPage={this.setPage}
          setContainerClassName={this.setContainerClassName}
        />
      ),
      containerClassName: "AppContainer",
      // appClassName: "AppContainerDesktop"
    };
  }

  // componentDidMount() {
  //   if (document.getElementById('appContainer') !== null && document.getElementById('appContainer').offsetWidth <= 375) {
  //     this.setState({appClassName: "AppContainerMobile"})
  //   }
  // }

  setPage = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  setContainerClassName = (newClassName) => {
    this.setState({ containerClassName: newClassName });
  };

  render() {
    const { currentPage, containerClassName } = this.state;
    return (
      <div className={containerClassName}>
        {/* <FinixCard refresh={this.refresh}/> */}
        {/* <PayByBank /> */}
        {/* <GooglePay /> */}
        {/* <ApplePay /> */}
        {/* <DonorInfo /> */}
        {/* <Category/> */}
        {currentPage}
      </div>
    );
  }
}

export default App;
