import React, { useEffect } from "react";

import GooglePayButton from "@google-pay/button-react";
import Confirmation from "./Confirmation";

export default function GooglePay(props) {
  let env = "TEST";

  if (process.env.REACT_APP_ENVIRONMENT !== "development") {
    env = "PRODUCTION";
  }

  useEffect(() => {
    const gpay_button = document.getElementsByClassName("new_style");
    if (gpay_button.length > 0) {
      gpay_button[0].classList.remove("new_style");
    }
  });
  console.log("isChromium", props.isChromium);
  if (props.isChromium === false) {
    return <></>;
  } else {
    return (
      <GooglePayButton
        style={{ width: "100%", height: "50px" }}
        buttonType="plain"
        buttonSizeMode="fill"
        environment={env}
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA", "AMEX", "DISCOVER"],
                billingAddressRequired: true,
                billingAddressParameters: {
                  format: "FULL",
                  phoneNumberRequired: true,
                },
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "finix",
                  //Need to make this variable for different merchants. corresponds to merchant identity
                  gatewayMerchantId: props.merchantIdentity,
                },
              },
            },
          ],
          emailRequired: true,
          shippingAddressRequired: true,
          shippingAddressParameters: {
            phoneNumberRequired: true,
          },
          //From Google Pay API Dashboard
          merchantInfo: {
            merchantId: "BCR2DN4TVCAMDYL6",
            merchantName: ".NGO",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: props.total,
            currencyCode: "USD",
            countryCode: "US",
          },
        }}
        onClick={(event) => {
          const paymentTypeChangeEvent = { target: { id: "card" } };
          props.handlePaymentTypeChange(paymentTypeChangeEvent);
          if (props.schedulePaymentShowComponent() === true) {
            event.preventDefault();
          }
        }}
        onLoadPaymentData={async (paymentRequest) => {
          // console.log('load payment data', paymentRequest);
          props.setLoad(true);
          props.makeGooglePayPayment(paymentRequest);
        }}
      />
    );
  }
}
